const validate = (values) => {
  const errors = {};

  if (!values.valorPresentado) {
    errors.valorPresentado = "Requerido";
  }

  if (!values.tipoComprobante) {
    errors.tipoComprobante = "Requerido";
  }

  const regex_nroComprobante = /^[a-zA-Z]{1}[0-9]{13}$/;

  if (!regex_nroComprobante.test(values.nroComprobante)) {
    errors.nroComprobante = "Requerido";
  }

  if (!values.periodoPrestacion) {
    errors.periodoPrestacion = "Requerido";
  }

  if (!values.cuit) {
    errors.cuit = "Requerido";
  } else if (values.cuit.length !== 13) {
    errors.cuit = "CUIT incorrecto";
  }

  if (!values.tipoComprobante) {
    const expresion = /^[a-c]\d{13}$/;
    if (!expresion.test(values.nroComprobante.toLowerCase())) {
      errors.nroComprobante = "Formato invalido";
    }
  }

  if (!values.facturaBase64File) {
    errors.facturaBase64File = "Requerido";
  }

  return errors;
};

export default validate;
