import verifyCuit from "../../../utils/cuit";

const validate = values => {

    const errors = {};
 
    if (!values.presen_total) { 
      errors.presen_total = 'Requerido'; 
    }

    if (!values.tipo) { 
        errors.tipo = 'Requerido';   
    }

    if(!values.compro_tipo)
    {
        errors.compro_tipo="Requerido";
    }

    if(values.cae)
    {
        if(values.cae.length!=14)
        {
            errors.cae="Formato invalido";
        }
    }
   
    const regex_compro_nro=/^[a-zA-Z]{1}[0-9]{13}$/;   

    if(!regex_compro_nro.test(values.compro_nro))
    {
        errors.compro_nro="Requerido";
    }

    if (!values.orden_fecha) { 
        errors.orden_fecha = 'Requerido';   
    }

    if(!values.sucursal)
    {
        errors.sucursal="Requerido";
    }

    if(!values.efe_cuit)
    {
        errors.efe_cuit="Requerido";
    }else if(values.efe_cuit.length!==13)
    {
        errors.efe_cuit="CUIT incorrecto";
    }
    // else if(!verifyCuit(values.efe_cuit.replaceAll("-","")))
    // {
    //     errors.efe_cuit="CUIT incorrecto"
    // }


    if(values.compro_tipo=="FC")
        {

         

            let expresion=/^[a-c]\d{13}$/;
            if(!expresion.test(values.compro_nro.toLowerCase()))
            {
                errors.compro_nro="Formato invalido";
            }
        }else if(values.compro_tipo=="RE"){
            let expresion=/^[r]\d{13}$/;
            if(!expresion.test(values.compro_nro.toLowerCase()))
            {
                errors.compro_nro="Formato invalido";
            }
        }else
        {
            let expresion=/^[x]\d{13}$/;
            if(!expresion.test(values.compro_nro.toLowerCase()))
            {
                errors.compro_nro="Formato invalido";
            }
        }
    
 
    return errors;
 
  };

  export default validate;