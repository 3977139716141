import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CommonRecuperoContrasena from "../../../components/common/password-change";
import UserSidebar from "../../../components/common/user-sidebar";
import Overlay from "../../../components/common/overlay";
import FooterPSHB from "../../../assets/img/pshb/Backgrounds/FooterVerde.png";

const CambiarContrasenaPSHB = () => {
  const [routes, setRoutes] = useState({
    routes: [
      // { route: "/ayuda", name: "Ayuda" },
      { route: "/cambiar-contrasena", name: "Cambiar contraseña" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="recupero-contrasena d-flex justify-content-center align-items-center">
          <CommonRecuperoContrasena></CommonRecuperoContrasena>
        </div>
        <img src={FooterPSHB} style={{ width: "100%", height: "auto" }} />
      </div>
    </div>
  );
};

export default CambiarContrasenaPSHB;
