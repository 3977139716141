import React, { useContext, useState } from "react";
import "./automatic-debit.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { myAxios } from "../../../utils/fetch";
import { Formik, Field, Form } from "formik";
import { Modal } from "react-bootstrap";
import CheckIcon from "../../../assets/img/check-green.png";
import { validateModelPS } from "./validationSchema";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";

const AutomaticDebitComponent = (props) => {
  const captcha = React.useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [checkboxChecked, setCheckboxChecked] = React.useState(false);
  const { user } = useContext(UserGlobalContextMemorySpace);
  const date = new Date().toLocaleDateString();
  const Customers = user.user.data.Customers;
  const [visible, setVisible] = React.useState({
    visible: false,
    resetForm: null,
  });
  const [visibleResponse, setVisibleResponse] = React.useState(false);
  const [guid, setGuid] = React.useState({});
  const [data, setData] = React.useState({
    picked: "",
    nombre: Customers.firstName,
    apellido: Customers.lastName,
    nrosocio: Customers.socialSecurityNumber,
    fecha: date,
    tarjeta: "",
    numeroTarjeta: "",
    nombreTarjeta: "",
    banco: "",
    fechaVencimiento: "",
    vigenteDesde: "",
    recaptcha: "",
  });

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const confirmar = (form, resetForm) => {
    // resetForm();
    setData(form);
    setVisible({ visible: true, resetForm });
  };

  const handleChangeCBU = (e, setFieldValue) => {
    let { value } = e.target;
    let lastLetter = value
      ?.substring(value.length - 1, value.length)
      ?.toLowerCase();

    if (!isNaN(lastLetter) && lastLetter != " ") {
      setFieldValue(e.target.name, value);
    }
  };

  const validateField = (value, tarjeta) => {
    let errorMessage = "";
    if (tarjeta == "" || tarjeta == "") {
      errorMessage = "Seleccione una tarjeta.";
    }
    if (tarjeta == "Visa" || tarjeta == "Mastercard") {
      if (value.length != 16) {
        errorMessage = "El campo debe tener 16 caracteres.";
      }
    }
    if (tarjeta == "American Express") {
      if (value.length != 15) {
        errorMessage = "El campo debe tener 15 caracteres.";
      }
    }
    return errorMessage;
  };

  const enviar = () => {
    setLoading(true);
    setVisibleResponse(true);
    setVisible({ ...visible, visible: false });
    setCheckboxChecked(false);

    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/sendemaildebito",
      method: "POST",
      data: {
        socialSecurity: data.nrosocio,
        fullName: data.nombre + " " + data.apellido,
        email: Customers.email,
        tipoOperacion: data.picked,
        tipoDebito: "automatico",
        tarjeta: data.tarjeta,
        numeroTarjeta: data.numeroTarjeta.toString(),
        nombresTarjeta: data.nombreTarjeta,
        bancoEmisor: data.banco,
        fechaVencimiento: data.fechaVencimiento,
        vigenteDesde: data.vigenteDesde,
        cbu: "",
      },
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        setGuid(response);
      })
      .catch((error) => {})
      .finally(() => {
        visible.resetForm();
        captcha.current.reset();
        setLoading(false);
      });
  };

  return (
    <section className=" ">
      <div className="container p-4">
        {/* TITULO */}
        <div className="row mt-5">
          <div className="col-lg-12">
            <h2 className="section_pshb_title mb-3">
              <b>Formulario débito automático en tarjeta de crédito</b>
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12" id="header-invoice">
            <span className="section_pshb_subtitle">
              Autorizo al <strong>Plan de Salud del Hospital Británico</strong>{" "}
              a debitar mensualmente de mi tarjeta de crédito el importe de la
              cuota correspondiente al plan que por servicio médico
              asistenciales he acordado con esa institución. Asimismo, tomo
              conocimiento que toda modificación sobre ese particular la debo
              comunicar antes del día 05 del mes anterior al que tendrá vigencia
              ya que las cuotas se procesan con 20 días de anticipación al
              período correspondiente.
            </span>
          </div>
        </div>

        {/* SEPARADOR */}
        <div className="separador-table-green-result"></div>
        {/* TABLA */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table-responsive table-mobile">
              <div className="mb-4">
                <Formik
                  initialValues={{
                    picked: "",
                    nombre: Customers.firstName,
                    apellido: Customers.lastName,
                    nrosocio: Customers.socialSecurityNumber,
                    fecha: date,
                    tarjeta: "",
                    numeroTarjeta: "",
                    nombreTarjeta: "",
                    banco: "",
                    fechaVencimiento: "",
                    vigenteDesde: "",
                  }}
                  validationSchema={validateModelPS}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    // if(!captcha.current.getValue())
                    // {
                    //   Swal.fire("", "Debe indicar que no es un robot", "error");
                    //   return
                    // }
                    confirmar(values, resetForm);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    setErrors,
                  }) => (
                    <Form>
                      <table className="col-lg-10 mx-auto">
                        <div className="row mb-4 ms-5 me-5 claseMobile">
                          <div className="col-md-4">
                            <div class="" style={{ textAlign: "center" }}>
                              <span className="label-pshb claseMobile">
                                <Field
                                  type="radio"
                                  name="picked"
                                  value="Adhesión"
                                  className="pt-1 w-100 input-debito"
                                  // value={values.picked}
                                  onChange={handleChange}
                                />
                                Adhesión
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div style={{ textAlign: "center" }}>
                              <span className="label-pshb claseMobile">
                                <Field
                                  type="radio"
                                  name="picked"
                                  value="Baja"
                                  className="pt-1 w-100 input-debito"
                                  // value={values.picked}
                                  onChange={handleChange}
                                />
                                Baja
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div style={{ textAlign: "center" }}>
                              <span className="label-pshb claseMobile">
                                <Field
                                  type="radio"
                                  name="picked"
                                  value="Modificación"
                                  className="pt-1 w-100 input-debito"
                                  // value={values.picked}
                                  onChange={handleChange}
                                />
                                Modificación
                              </span>
                            </div>
                          </div>
                          <center>
                            {errors.picked && touched.picked && (
                              <div className="text-danger">
                                {errors?.picked}
                              </div>
                            )}
                          </center>
                        </div>
                        <div className="card card-refund b-radius-20 shadow-external">
                          <div className="g-0">
                            <div className="row">
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  Nombre
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    type="text"
                                    readOnly
                                    name="nombre"
                                    value={Customers.firstName}
                                  />
                                </span>
                              </div>
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  Apellido
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    type="text"
                                    readOnly
                                    name="apellido"
                                    value={Customers.lastName}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  N° de Socio
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    type="text"
                                    readOnly
                                    name="nrosocio"
                                    value={Customers.socialSecurityNumber}
                                  />
                                </span>
                              </div>
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  Fecha de solicitud
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    type="text"
                                    readOnly
                                    name="fecha"
                                    value={date}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-12">
                                <label className="label-pshb">
                                  Tarjeta
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    name="tarjeta"
                                    as="select"
                                    onChange={handleChange}
                                  >
                                    <option value="1">
                                      Seleccione una opción
                                    </option>
                                    <option value="Visa">Visa</option>
                                    <option value="American Express">
                                      American Express
                                    </option>
                                    <option value="Mastercard">
                                      Mastercard
                                    </option>
                                  </Field>
                                </label>
                                {errors.tarjeta && touched.tarjeta && (
                                  <div className="text-danger">
                                    {errors?.tarjeta}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-12">
                                <span className="label-pshb">
                                  Completar con todos los números que figuren en
                                  su tarjeta de crédito.
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    // type="number"
                                    name="numeroTarjeta"
                                    value={values.numeroTarjeta}
                                    validate={(e) =>
                                      validateField(e, values.tarjeta)
                                    }
                                    onChange={(e) =>
                                      handleChangeCBU(e, setFieldValue)
                                    }
                                  />
                                </span>
                                {errors.numeroTarjeta &&
                                  touched.numeroTarjeta && (
                                    <div className="text-danger">
                                      {errors?.numeroTarjeta}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-12">
                                <span className="label-pshb">
                                  Nombre y apellido que figura en la tarjeta.
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    type="text"
                                    name="nombreTarjeta"
                                    value={values.nombreTarjeta}
                                    onChange={handleChange}
                                  />
                                </span>
                                {errors.nombreTarjeta &&
                                  touched.nombreTarjeta && (
                                    <div className="text-danger">
                                      {errors?.nombreTarjeta}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  Banco emisor
                                  <Field
                                    className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                    type="text"
                                    name="banco"
                                    value={values.banco}
                                    onChange={handleChange}
                                  />
                                </span>
                                {errors.banco && touched.banco && (
                                  <div className="text-danger">
                                    {errors?.banco}
                                  </div>
                                )}
                              </div>
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  Fecha de vencimiento (plástico)
                                  <Field
                                    name="fechaVencimiento"
                                    value={values.fechaVencimiento}
                                    render={({ field }) => (
                                      <MaskedInput
                                        {...field}
                                        mask={[
                                          /[0-1]/,
                                          /[0-9]/,
                                          "/",
                                          /[2]/,
                                          /[0]/,
                                          /\d/,
                                          /\d/,
                                        ]}
                                        id="fechaVencimiento"
                                        placeholder="MM/AAAA"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                      />
                                    )}
                                  />
                                </span>
                                {errors.fechaVencimiento &&
                                  touched.fechaVencimiento && (
                                    <div className="text-danger">
                                      {errors.fechaVencimiento}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-6">
                                <span className="label-pshb">
                                  Vigencia gestión alta/baja o modificación
                                  débito
                                  <Field
                                    name="vigenteDesde"
                                    value={values.vigenteDesde}
                                    render={({ field }) => (
                                      <MaskedInput
                                        {...field}
                                        mask={[
                                          /[0-1]/,
                                          /[0-9]/,
                                          "/",
                                          /[2]/,
                                          /[0]/,
                                          /\d/,
                                          /\d/,
                                        ]}
                                        id="vigenteDesde"
                                        placeholder="MM/AAAA"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="pt-1 input-round shadow-blue w-100 input-date-ios"
                                      />
                                    )}
                                  />
                                </span>
                                {errors.vigenteDesde &&
                                  touched.vigenteDesde && (
                                    <div className="text-danger">
                                      {errors?.vigenteDesde}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="section-date col-md-6">
                                <div id="botCaptcha">
                                  <ReCAPTCHA
                                    ref={captcha}
                                    sitekey={process.env.REACT_APP_CAPTCHA}
                                    onChange={(value) => {
                                      setFieldValue("recaptcha", value);
                                    }}
                                  />
                                  {/* {errors.recaptcha && (
                                    <div className="text-danger">
                                      {errors?.recaptcha}
                                    </div>
                                  )} */}
                                </div>
                              </div>
                              <div className="section-date col-md-12 col-3 section-mobile">
                                <button
                                  type="submit"
                                  className="boton-confirmar boton-confirmar-mobile"
                                  onClick={() => {
                                    if (!captcha.current.getValue()) {
                                      Swal.fire(
                                        "",
                                        "Debe indicar que no es un robot",
                                        "error"
                                      );
                                    }
                                  }}
                                >
                                  Enviar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </table>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        {/* SEPARADOR */}
        <div className="separador-table-green-result">
          <div className="mt-3">
            <span className="section_pshb_subtitle">
              <strong> Importante:</strong> para inclusión en débito automático
              es indispensable que Usted tenga la cuota del mes en curso
              cancelada, al momento de presentar esta solicitud.
            </span>
          </div>
        </div>
      </div>
      <div className="footer-pshb"></div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={visible.visible}
        centered
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="row mt-2 mx-auto">
              <div className="col-lg-12 ">
                <h2 className="section_pshb_title">
                  <center>
                    <b>Confirmá tus datos</b>
                  </center>
                </h2>
              </div>
            </div>
            <div className="separador-table-green-result mb-4"></div>
            <div className="mb-4">
              <span className="section_pshb_subtitle_2">
                <strong> Tipo de trámite: </strong>
                {data.picked}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> Nombre: </strong>
                {data.nombre}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> Apellido: </strong>
                {data.apellido}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> N° de Socio: </strong>
                {data.nrosocio}
              </span>
            </div>
            <div className="mb-5">
              <span className="section_pshb_subtitle_2">
                <strong> Fecha: </strong>
                {data.fecha}
              </span>
            </div>
            <div className="politica-proteccion-pshb" />
            <div className="col-lg-12 ">
              <h6 className="section_pshb_subtitle_2">
                <strong>Datos tarjeta de crédito:</strong>
              </h6>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> Tarjeta: </strong>
                {data.tarjeta}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> N° de tarjeta: </strong>
                {data.numeroTarjeta}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> Nombre y apellido: </strong>
                {data.nombreTarjeta}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> Banco emisor: </strong>
                {data.banco}
              </span>
            </div>
            <div className="mb-2">
              <span className="section_pshb_subtitle_2">
                <strong> Fecha de vencimiento: </strong>
                {data.fechaVencimiento}
              </span>
            </div>
            <div className="mb-4">
              <span className="section_pshb_subtitle_2">
                <strong>
                  {" "}
                  Vigencia gestión alta/baja o modificación débito:{" "}
                </strong>
                {data.vigenteDesde}
              </span>
            </div>
            <div className="tutorial-credencial">
              {data.picked !== "Baja" ? (
                <div className="section_pshb_subtitle_2 checkbox-container">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    id="myCheckbox"
                    className="checkboxInput"
                  />
                  <label>
                    <strong>
                      Declaro bajo juramento que los datos informados en la
                      solicitud de adhesión al debito automático pertenecen al
                      titular del grupo. En caso de uso de tarjetas de crédito
                      de terceros, declaro que el titular de la tarjeta está en
                      conocimiento del uso y aceptación del débito registrado
                      por Ud. asumiendo en este acto, toda responsabilidad que
                      pueda surgir de la utilización de la misma.
                    </strong>
                  </label>
                </div>
              ) : (
                <></>
              )}

              {data.picked === "Baja" ? (
                <div className="section_pshb_subtitle_2 checkbox-container">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    id="myCheckbox"
                    className="checkboxInput"
                    style={{ marginBottom: "94px", marginRight: "10px" }}
                  />
                  <label>
                    <strong>
                      Medios de pagos habilitados: pagos on line.{" "}
                      <a
                        href="https://www.hospitalbritanico.org.ar/PlanDeSalud"
                        target="_blank"
                      >
                        www.hospitalbritanico.org.ar/PlanDeSalud
                      </a>{" "}
                      -{" "}
                      <a href="https://www.pagomiscuentas.com">
                        www.pagomiscuentas.com
                      </a>{" "}
                      /pago telefónico con Tarjeta de crédito /tarjeta de debito
                      0810-999-7742 Mercado Pago /Efectivo : Pago fácil,
                      Rapipago. Bancos: Itau-Santander-Patagonia-Hsbc.
                    </strong>
                  </label>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <div className="mx-auto">
            <button
              className="boton-volver"
              onClick={() => {
                setCheckboxChecked(false);
                setVisible({ ...visible, visible: false });
              }}
            >
              Volver
            </button>
            <button
              className="boton-confirmar"
              onClick={() => enviar()}
              disabled={!checkboxChecked}
            >
              Confirmar
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={visibleResponse}
        centered
      >
        <Modal.Body>
          {!loading ? (
            <div className="modalBody">
              <center>
                <img src={CheckIcon} alt="check-icon" className="mt-3 mb-2" />
              </center>
              <div className="row mt-2 mx-auto">
                <div className="col-lg-12 ">
                  <h2 className="section_pshb_subtitle">
                    <center>
                      <b>
                        <strong>Datos cargados con éxito</strong>
                      </b>
                    </center>
                  </h2>
                </div>
              </div>
              <div className="row mt-4 mx-auto">
                <div className="col-lg-12 ">
                  <h2 className="section_pshb_subtitle">
                    <center>
                      <strong>ID de su trámite:</strong>
                    </center>
                  </h2>
                </div>
              </div>
              <div className="row mt-2 mx-auto">
                <div className="col-lg-12 ">
                  <h2 className="section_pshb_subtitle">
                    <center>
                      <b>{guid?.data?.data?.ds?.guid}</b>
                    </center>
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            <center>
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </td>
              </tr>
            </center>
          )}
        </Modal.Body>
        {!loading ? (
          <Modal.Footer style={{ border: "none" }}>
            <div className="mx-auto">
              <button
                className="boton-confirmar"
                onClick={() => setVisibleResponse(false)}
              >
                Aceptar
              </button>
            </div>
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    </section>
  );
};

export default AutomaticDebitComponent;
