import React,{ useContext } from 'react';
import "./style.scss";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const PageError = () => {

    const { userPSHB } =
    useContext(UserGlobalContextMemorySpace);
    

    return (
        <section style={{minHeight: "calc(100vh - 25px - 100px - 225px)",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
            <div style={{textAlign:"center"}} id={userPSHB?"page-error-pshb":"page-error-hb"}>            
                <span id="titulo-error">No pudimos encontrar la página que estás buscando.</span>
                <br/>
                <span id="subTitulo-error"> Podés seguir navegando por el portal y volver en unos minutos.</span>
            </div>
        </section>
    );
}
export default PageError;