import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import React, { useContext, useState } from "react";
import axios from "axios";
// import CustomSelect from "../../customSelect";
import CustomSelectMiCobertura from "../../customSelectMiCobertura";
import { CUSTOM_SELECT_PROFILE } from "../../customSelect/constants";
import { useFormik } from "formik";
import { validateModel } from "./validationSchema";
import { myAxios } from "../../../../utils/fetch";
import "./mi-cobertura.scss";

export const MiCoberturaComponent = ({
  activo = false,
  editando = false,
  onEditando = () => null,
  confirmar = () => null,
  cancelar = () => null,
  guardando  
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );

  const [claseCampo, setClaseCampo] = React.useState("");
  const [socialSecurities, setSocialSecurities] = React.useState(null);
  const [plans, setPlans] = React.useState(null);
  const [socialsecurityIdGlobal, setSocialSecurityIdGlobal] =
    React.useState(null);
  // const [plansIdGlobal,setPlansIdGlobal]=React.useState(null);
  const [socialsecurityGlobal, setSocialSecurityGlobal] = React.useState({
    id: null,
    name: null,
  });
  const [plansGlobal, setPlansGlobal] = React.useState(null);

  const [isValid, setIsValid] = React.useState(true);
  const [isPSHB, setIsPSHB] = React.useState(
    temporalUser.user.data.Customers.socialSecurity == "40080/000"
  );

  const [socialSecurityTextGlobal, setSocialSecurityTextGlobal] =
    React.useState();

  const [menuOpen, setMenuOpen] = React.useState(false);

  const [menuOpenPlan, setMenuOpenPlan] = React.useState(false);

  const [plansTextGlobal, setPlansTextGlobal] = React.useState();

  const [securityNumber, setSecurityNumber] = React.useState(
    temporalUser.user.data.Customers.socialSecurityNumber
  );

  const initialValues = {
    phone: temporalUser.user.data.Customers.phone,
    mobile: temporalUser.user.data.Customers.mobile,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateModel,
  });

  React.useEffect(() => {
    if (editando) setClaseCampo("campo editandoCampo");
    else setClaseCampo("campo");
  }, [editando]);

  React.useEffect(() => {
    if (plans != null) {
      // console.log("NUEVO VALOR TEMPORAL",temporalUser);
      // seleccionarSocialSecurity(temporalUser.user.data.Customers.socialSecurity);
      // seleccionarPlan(temporalUser.user.data.Customers.socialSecurityPlan);
    }
  }, [temporalUser]);

  React.useEffect(() => {
    temporalUser.user.data.Customers.socialSecurityNumber =
      temporalUser.user.data.Customers.socialSecurityNumber.replaceAll(" ", "");
    if (socialSecurities == null) {
      if (sessionStorage.getItem("socialSecurities") !== null) {
        const socialSecuritiesList = JSON.parse(
          sessionStorage.getItem("socialSecurities")
        );
        setSocialSecurities(socialSecuritiesList);
      } else {
        myAxios({
          url:
            process.env.REACT_APP_GATEWAY_QA +
            "/customers/api/v1/user/getSocialSecurities",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            USERTOKEN: user.user.data.Token,
            Authorization: "sham", //`Bearer ${user.user.data.appToken}`
          },
        })
          .then((response) => {
            const socialSecuritiesList =
              response.data.data.SocialSecurities.map((socialSecurity) => {
                return {
                  id: socialSecurity.codigo,
                  name: socialSecurity.descripcion,
                };
              });
            setSocialSecurities(socialSecuritiesList);
            sessionStorage.setItem(
              "socialSecurities",
              JSON.stringify(socialSecuritiesList)
            );
          })
          .catch((error) => {});
      }
    }
    //
    if (isPSHB) {
      //Primer llamado API Verificar cobertura
      myAxios({
        url: `${process.env.REACT_APP_GATEWAY_QA}${
          process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
        }?credential=${encodeURIComponent(
          temporalUser.user.data.Customers.socialSecurityNumber
        )}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      }).then((resp) => {
        if (resp.data != null) {
          if (
            resp.data.data.Afiliado == "ACTIVO" &&
            resp.data.data.documento == user.user.data.Customers.dni
          ) {
            seleccionarPlan(resp.data.data.codigoplan.trim());
          } else {
            seleccionarPlan("");
          }
        }
      });
    }
  }, []);

  React.useEffect(() => {
    seleccionarSocialSecurity(temporalUser.user.data.Customers.socialSecurity);
  }, [socialSecurities]);

  React.useEffect(() => {
    //setPlans(null);
    consultarPlan(socialsecurityIdGlobal).then(() => {
      if (socialsecurityIdGlobal == "40080/000") {
        setIsPSHB(true);
      } else {
        setIsPSHB(false);
      }
    });
  }, [socialsecurityIdGlobal]);

  React.useEffect(() => {
    if (isPSHB) {
      //Primer llamado API Verificar cobertura
      myAxios({
        url: `${process.env.REACT_APP_GATEWAY_QA}${
          process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
        }?credential=${encodeURIComponent(
          temporalUser.user.data.Customers.socialSecurityNumber
        )}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      }).then((resp) => {
        if (resp.data != null) {
          if (
            resp.data.data.Afiliado == "ACTIVO" &&
            resp.data.data.documento == user.user.data.Customers.dni
          ) {
            seleccionarPlan(resp.data.data.codigoplan.trim());
          } else {
            seleccionarPlan("");
          }
        }
      });
    }
  }, [securityNumber]);

  React.useEffect(() => {
    if (plans) {
      seleccionarPlan(temporalUser.user.data.Customers.socialSecurityPlan);
    }
  }, [plans]);

  const consultarPlan = async (socialSecurityId) => {
    if (!socialSecurityId) return;

    let response = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/user/GetSocialSecurityPlan?socialSecurity=" +
        encodeURIComponent(socialSecurityId),
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });
    // console.log(response)
    if (response != null && response.status == 200) {
      let socialSecuritiesPlansList = response.data.data.SocialSecurityPlan.map(
        (socialSecurityPlan) => {
          return {
            id: socialSecurityPlan.codigo,
            name: socialSecurityPlan.descripcion,
          };
        }
      );
      setPlans(socialSecuritiesPlansList);
    }
  };

  const seleccionarSocialSecurity = (socialSecurityId) => {
    const socialSelected = socialSecurities?.find((socialSecurity) => {
      return socialSecurity.id === socialSecurityId;
    });

    setSocialSecurityGlobal(socialSelected ?? { id: null, name: null });
    setSocialSecurityTextGlobal(socialSelected ?? { id: null, name: null });
    setSocialSecurityIdGlobal(socialSelected?.id);

    // if(socialSecurityId!==socialsecurityIdGlobal)
    // {
    //   setSocialSecurityIdGlobal(socialSecurityId);
    // }

    //return socialSelected?socialSelected.name:"---------";
  };

  const seleccionarPlan = (planId) => {
    //console.log("Seleccionar plan con PLANID", planId);
    const planSelected = plans?.find((socialSecurityPlan) => {
      return socialSecurityPlan.id === planId;
    });

    if (planSelected) {
      setPlansGlobal({ ...planSelected });
      setPlansTextGlobal({ ...planSelected });
    } else {
      if (plans && plans?.length > 0) {
        setPlansGlobal({ ...plans[0] });
        setPlansTextGlobal({ ...plans[0] });
      }
    }
  };

  const changeValue = (e) => {
    // if (e.target.name === "socialSecurityNumber") {
    //   const { value } = e.target;
    //   let valido = /^[0-9]+$/;
    //   setIsValid(valido.test(value));
    // }
    temporalUser.user.data.Customers = {
      ...temporalUser.user.data.Customers,
      [e.target.name]: e.target.value,
    };
    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));
    formik.setFieldValue([e.target.name], e.target.value);

    if (e.target.name == "socialSecurityNumber" && isPSHB) {
      //Tercer llamado API Verificar cobertura
      setSecurityNumber(e.target.value);

      myAxios({
        url: `${process.env.REACT_APP_GATEWAY_QA}${
          process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
        }?credential=${encodeURIComponent(e.target.value.replaceAll(" ", ""))}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      }).then((resp) => {
        if (resp.data != null) {
          if (
            resp.data.data.Afiliado == "ACTIVO" &&
            resp.data.data.documento == user.user.data.Customers.dni
          ) {
            seleccionarPlan(resp.data.data.codigoplan.trim());
          } else {
            seleccionarPlan("");
          }
        }
      });
    }
  };

  const onChange = (id, name) => {
    temporalUser.user.data.Customers = {
      ...temporalUser.user.data.Customers,
      [name]: id,
    };
    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));

    if (name === "socialSecurity") {
      //consultarPlan(id);
      seleccionarSocialSecurity(id);
      setSocialSecurityIdGlobal(id);
      if (id == "40080/000") {
        //Segundo llamado API Verificar cobertura
        setIsPSHB(true);
        myAxios({
          url: `${process.env.REACT_APP_GATEWAY_QA}${
            process.env.REACT_APP_GATEWAY_ENDPOINT_VALIDATEPSHBPLAN
          }?credential=${encodeURIComponent(
            securityNumber.replaceAll(" ", "")
          )}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            USERTOKEN: user.user.data.Token,
            Authorization: `Bearer ${user.user.data.appToken}`,
          },
        }).then((resp) => {
          if (resp.data != null) {
            if (
              resp.data.data.Afiliado == "ACTIVO" &&
              resp.data.data.documento == user.user.data.Customers.dni
            ) {
              seleccionarPlan(resp.data.data.codigoplan.trim());
            } else {
              seleccionarPlan("");
            }
          }
        });
      }
    } else if (name === "socialSecurityPlan") {
      seleccionarPlan(id);
    }
  };

  const emptyPlans = () => {
    setPlansTextGlobal("");
    setSocialSecurityTextGlobal("");
    temporalUser.user.data.Customers.socialSecurityNumber = "";
    setMenuOpen(true);
  };

  const emptyPlansPlan = () => {
    setPlansTextGlobal("");
    // console.log(
    //   "temporalUser.user.data.Customers: ",
    //   temporalUser.user.data.Customers
    // );
    // setSocialSecurityTextGlobal("");
    // temporalUser.user.data.Customers.socialSecurityNumber = "";
    setMenuOpenPlan(true);
  };

  const onInputChange = (cualEs) => {
    // setPlansTextGlobal("");
    //Vuelve a poner cobertura cuando no se selecciona nada
    const socialSelected = socialSecurities?.find((socialSecurity) => {
      return (
        socialSecurity.id === temporalUser.user.data.Customers.socialSecurity
      );
    });

    setSocialSecurityTextGlobal(socialSelected ?? { id: null, name: null });

    if (cualEs != "socialSecurity") {
      //Vuelve a poner plan cuando no se selecciona nada
      const planSelected = plans?.find((socialSecurityPlan) => {
        return (
          socialSecurityPlan.id ===
          temporalUser.user.data.Customers.socialSecurityPlan
        );
      });
      setPlansTextGlobal(planSelected);
    }

    //Vuelve a poner numero afiliado cuando no se selecciona nada
    temporalUser.user.data.Customers.socialSecurityNumber = securityNumber;

    setMenuOpen(false);
  };

  const onInputChangePlan = () => {
    // setPlansTextGlobal("");

    const planSelected = plans?.find((socialSecurityPlan) => {
      return (
        socialSecurityPlan.id ===
        temporalUser.user.data.Customers.socialSecurityPlan
      );
    });
    setPlansTextGlobal(planSelected);

    setMenuOpenPlan(false);
  };

  return (
    <div className={activo ? "" : "d-none"}>
      <div className={activo ? "formActivo" : "formInactivo"}>
        {/* LINEA SUPERIOR */}
        <div
          className={
            activo
              ? "lineaCabActiva"
              : userPSHB
              ? "lineaCabInactivaPSHB"
              : "lineaCabInactiva"
          }
        />

        {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}

        {!userPSHB ? (
          <>
            <div className="botones">
              {!editando && (
                <button
                  className="botonEditar"
                  onClick={() => (activo ? onEditando() : null)}
                  style={{ paddingBottom: "2px" }}
                >
                  <FiEdit2 /> Editar
                </button>
              )}
              {editando && (
                <>
                  <button
                    className={
                      "botonEditar " + (userPSHB ? "boton-PSHB" : "boton-HB")
                    }
                    disabled={!isValid}
                    onClick={(isValid && !guardando) ? () => confirmar(temporalUser) : null}
                  >
                     {guardando?
              <>
                <div style={{verticalAlign:"inherit",marginLeft: "5px", width: "0.7rem",height: "0.7rem",color: "rgb(93, 132, 170)"}} className="spinner-grow text-muted"></div>
                <div style={{verticalAlign:"inherit",marginLeft: "5px", width: "0.7rem",height: "0.7rem",color: "rgb(93, 132, 170)"}} className="spinner-grow text-muted"></div>
                <div style={{verticalAlign:"inherit",marginLeft: "5px", width: "0.7rem",height: "0.7rem",color: "rgb(93, 132, 170)"}} className="spinner-grow text-muted"></div>
              </>
                :
               <>
                <FiCheck /> Confirmar                 
               </>}
                  </button>
                  <button
                    className={
                      "botonCancelar " + (userPSHB ? "boton-PSHB" : "boton-HB")
                    }
                    onClick={() => cancelar(setTemporalUser)}
                  >
                    <FiX /> Cancelar
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* CAMPOS DE FORMULARIOS */}
        <div
          className={user.user.data.Customers.pshb ? "campo" : claseCampo}
          style={{ display: "flex" }}
        >
          <span
            className="perfil-etiqueta"
            style={{ width: "100px", minWidth: "100px" }}
          >
            Cobertura:
          </span>
          {userPSHB ? (
            <span className="perfil-valor" style={{ width: "100%" }}>
              PLAN DE SALUD DEL HOSPITAL BRITANICO
            </span>
          ) : (
            <span className="perfil-valor" style={{ width: "100%" }}>
              {user.user.data.Customers.pshb ? (
                socialsecurityGlobal.name
              ) : !editando ? (
                socialsecurityGlobal.name
              ) : (
                <div
                  style={{ width: "100%", marginTop: "-5px" }}
                  className={
                    "perfil " + (userPSHB ? "perfil-PSHB" : "perfil-HB")
                  }
                >
                  <div style={{ position: "relative" }}>
                    <CustomSelectMiCobertura
                      key={socialSecurityTextGlobal}
                      defaultText={socialSecurityTextGlobal}
                      optionsList={socialSecurities ?? [{ id: "", name: "" }]}
                      type={CUSTOM_SELECT_PROFILE}
                      width="100%"
                      onChange={onChange}
                      name="socialSecurity"
                      menuOpen={menuOpen}
                      onInputChange={onInputChange}
                      onMenuOpen={() => {
                        emptyPlans();
                        // setSocialSecurityTextGlobal("");
                      }}
                    />
                  </div>
                </div>
              )}
            </span>
          )}
        </div>

        <div className={activo ? "lineaActiva" : "lineaInactiva"} />
        <div className={claseCampo} style={{ display: "flex" }}>
          <span
            className="perfil-etiqueta"
            style={{ width: "100px", minWidth: "100px" }}
          >
            Plan:
          </span>
          {userPSHB ? (
            <span className="perfil-valor" style={{ width: "100%" }}>
              {user?.user?.data?.Customers?.plan
                ? user?.user?.data?.Customers?.plan
                : ""}
            </span>
          ) : (
            <span className="perfil-valor" style={{ width: "100%" }}>
              {plans != null ? (
                !editando ? (
                  user.user.data.Customers.socialSecurity === "40080/000" &&
                  user?.user?.data?.Customers?.plan ? (
                    user?.user?.data?.Customers?.plan
                  ) : (
                    plansGlobal?.name
                  )
                ) : (
                  <div
                    style={{ width: "100%", marginTop: "-5px" }}
                    className={
                      "perfil " + (userPSHB ? "perfil-PSHB" : "perfil-HB")
                    }
                  >
                    <div style={{ position: "relative" }}>
                      {/* <CustomSelectMiCobertura
                        defaultText={plansGlobal?.name}
                        optionsList={plans}
                        type={CUSTOM_SELECT_PROFILE}
                        width="100%"
                        onChange={onChange}
                        name="socialSecurityPlan"
                      /> */}
                      <CustomSelectMiCobertura
                        key={plansTextGlobal.id ?? ""}
                        defaultText={plansTextGlobal ?? [{ id: "", name: "" }]}
                        optionsList={plans}
                        type={CUSTOM_SELECT_PROFILE}
                        width="100%"
                        onChange={onChange}
                        name="socialSecurityPlan"
                        menuOpen={menuOpenPlan}
                        onInputChangePlan={onInputChangePlan}
                        onMenuOpen={() => {
                          emptyPlansPlan();
                          // setPlansTextGlobal("");
                        }}
                        disabled={isPSHB}
                      />
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
            </span>
          )}
        </div>

        <div className={activo ? "lineaActiva" : "lineaInactiva"} />
        <div
          className={userPSHB ? "campo" : claseCampo}
          style={{ display: "flex" }}
        >
          <span
            className="perfil-etiqueta"
            style={{ width: "100px", minWidth: "100px" }}
          >
            N° afiliado:
          </span>
          {userPSHB ? (
            <span className="perfil-valor">
              {user?.user?.data?.Customers?.credencial
                ? user?.user?.data?.Customers?.credencial
                : ""}
            </span>
          ) : (
            <span className="perfil-valor">
              {userPSHB ? (
                temporalUser.user.data.Customers.socialSecurityNumber
              ) : !editando ? (
                temporalUser.user.data.Customers.socialSecurityNumber
              ) : (
                <input
                  type="text"
                  className="input-editing"
                  name="socialSecurityNumber"
                  maxLength={20}
                  style={{ width: "100%", marginLeft: "0px" }}
                  value={temporalUser.user.data.Customers.socialSecurityNumber}
                  onChange={changeValue}
                />
              )}
            </span>
          )}
        </div>
        <div className="text-danger-white">
          {formik.errors.socialSecurityNumber}
        </div>
        {/* <div className={activo ? 'lineaActiva' : 'lineaInactiva'} />
        <div className={claseCampo}><span>Condición IVA:</span>Exento</div> */}
      </div>
    </div>
  );
};
