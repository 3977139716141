import React, { useContext, useEffect } from "react";
import { useToggle } from "../../../hooks/useToggle";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { useSearchParams } from "react-router-dom";
import { TarjetaHistorialComponent } from "./tarjeta-historial";
import { HistorialComponent } from "./historial-component";
import axios from "axios";

const MecanismoHistorialComponent = () => {
  const informacionToggle = useToggle();
  const [results, setResults] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);

  const [searchParams, setSearchParams] = useSearchParams({});
  const [debitoVisible, setDebitoVisible] = React.useState("");
  const openDialogParams = searchParams.get("open");

  const { user } = useContext(UserGlobalContextMemorySpace);

  const obtenerResultados = (offset) => {
    const contrato = user.user.data.Customers.credencial?.split(" ")[0];
    axios({
      url: `${process.env.REACT_APP_GATEWAY_QA}/patient/api/v1/mecanismo/getpaged?offset=${offset}&contrato=${contrato}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        setResults(response?.data?.data?.result);
        setTotal(response?.data?.data?.recordCount);
        setLoader(false);
      })
      .catch((error) => {
        setResults([]);
        setLoader(false);
      })
      .finally(() => {});
  };

  useEffect(() => {
    obtenerResultados(offset);
  }, []);

  const siguiente = () => {
    setOffset(offset + limit);
    obtenerResultados(offset + limit);
    console.log(offset + limit);
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset < 0) {
      setOffset(0);
      obtenerResultados(0);
    } else {
      setOffset(temporalOffset);
      obtenerResultados(temporalOffset);
    }
  };

  React.useEffect(() => {
    if (openDialogParams == "1") {
      informacionToggle.onToggle();
    }
  }, []);

  const llamar = (index) => {
    setDebitoVisible((previus) => (previus === index ? "" : index));
  };

  const formatMoney = (value) => {
    return `$ ${parseFloat(value)
      .toLocaleString("es-AR")
      .replaceAll("NaN", "")}`;
  };

  return (
    <>
      <section className=" ">
        <div className="container p-4 d-none d-lg-block">
          {/* TITULO */}
          <div className="row mt-1">
            <div
              className="col-lg-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <h2 className="section_pshb_title">
                  <b>Historial</b>
                </h2>

                <h5 className="section_pshb_subtitle">
                  Desde esta opción puede observar todas sus solicitudes de
                  Mecanismo de integración
                </h5>
              </div>
            </div>
          </div>

          <div className="separador-table-green-result"></div>

          <div>
            <div className="g-0">
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table table-pshb">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Paciente</th>
                          <th>CUIT</th>
                          <th>N° de Comprobante</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loader ? (
                          results?.map((result) => {
                            return (
                              <tr key={result.id}>
                                <td>{result.periodoPrestacion.slice(0, 7)}</td>
                                <td>{result.paciente}</td>
                                <td>{result.cuit}</td>
                                <td>{result.nroComprobante}</td>
                                <td>{formatMoney(result.valorPresentado)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5" style={{ textAlign: "center" }}>
                              <span className="spinner-grow spinner-grow-sm"></span>
                              <span className="spinner-grow spinner-grow-sm"></span>
                              <span className="spinner-grow spinner-grow-sm"></span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {total > 0 ? (
                <div id="paginate">
                  {(offset > 0) & !loader ? (
                    <div
                      className="btn btn-pshb-primary shadow-blue"
                      onClick={anterior}
                    >
                      Anterior
                    </div>
                  ) : (
                    <></>
                  )}
                  {!loader ? (
                    <span className="number-pshb-paginate">
                      <a href="#">
                        {offset / 5 + 1} de {Math.ceil(total / limit)}
                      </a>
                    </span>
                  ) : (
                    <></>
                  )}
                  {(offset + results?.length < total) & !loader ? (
                    <div
                      className="btn btn-pshb-primary shadow-blue"
                      onClick={siguiente}
                    >
                      Siguiente
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="d-md-none d-block d-md-none container p-4">
          <div className="row mt-5">
            <div
              className="col-lg-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <h2 className="section_pshb_title">
                  <b>Historial</b>
                </h2>

                <h5 className="section_pshb_subtitle">
                  Desde esta opción puede observar todas sus solicitudes de
                  débito automático
                </h5>
              </div>
            </div>
          </div>

          <div className="separador-table-green-result"></div>

          {results?.map((result, index) => {
            return (
              <TarjetaHistorialComponent
                key={index}
                titulo={result.guid}
                activo={false}
                puedeMinimizar={true}
                data={result}
                onVisibleTarjeta={(_) => llamar(index)}
              >
                <HistorialComponent
                  data={result}
                  activo={true}
                  editando={false}
                  eliminar={() => null}
                  anadir={() => null}
                />
              </TarjetaHistorialComponent>
            );
          })}

          {total > 0 ? (
            <div
              id="paginate"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {(offset > 0) & !loader ? (
                <div
                  className="btn btn-pshb-primary shadow-blue"
                  onClick={anterior}
                >
                  Anterior
                </div>
              ) : (
                <></>
              )}
              {!loader ? (
                <span className="number-pshb-paginate">
                  <a href="#">
                    {offset / 5 + 1} de {Math.ceil(total / limit)}
                  </a>
                </span>
              ) : (
                <></>
              )}
              {(offset + results?.length < total) & !loader ? (
                <div
                  className="btn btn-pshb-primary shadow-blue"
                  onClick={siguiente}
                >
                  Siguiente
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="footer-pshb"></div>
      </section>
    </>
  );
};

export default React.memo(MecanismoHistorialComponent);
