import { createContext, useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// memory space created
export const UserGlobalContextMemorySpace = createContext();

export const UserGlobalContext = ({ children }) => {
  const [mainLoader, setMainLoader] = useState(true);
  const [sitePSHB, setSitePSHB] = useState(null);
  const [userPSHB, setUserPSHB] = useState(null);
  const [user, setUser] = useState(null);
  const [initialUser, setInitialUser] = useState(null);
  const [familiarGroup, setFamiliarGroup] = useState(null);
  const [familiarGroupToken, setFamiliarGroupToken] = useState(null);
  const [familiarGroupComponent, setFamiliarGroupComponent] = useState(null);
  const [clearContext, setClearContext] = useState(false);
  const [reintegro, setReintegro] = useState();
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState({});
  const [openHelper, setOpenHelper] = useState(false);
  const [footerConfiguration, setFooterConfiguration] = useState({});

  const logout = () => {
    setSitePSHB(null);
    setUserPSHB(null);
    setUser(null);
    setFamiliarGroup(null);
    setFamiliarGroupToken(null);
    setFamiliarGroupComponent(null);
    setClearContext(false);
    setReintegro();
    localStorage.clear();
  };

  const getFooterConfiguration = async () => {
    const footer = await axios({
      url: process.env.REACT_APP_STRAPI_QA + "/footers?estado=PU",
      method: "get",
    });

    setFooterConfiguration(footer.data[0]);
  };

  useEffect(() => {
    //Mount:

    let sessionLocal = localStorage.getItem("enc");

    getFooterConfiguration();
    if (sessionLocal) {
      axios({
        url:
          process.env.REACT_APP_STRAPI_QA +
          process.env.REACT_APP_STRAPI_QA_ENDPOINT_ENCSESSION,
        method: "POST",
        data: { enc: sessionLocal },
      })
        .then((res) => {
          // console.log("res.data.result", res.data.result)

          if (
            res.data.result.responseCode == 200 &&
            res.data.result.data.sitePSHB &&
            res.data.result.data.Customers.pshb
          ) {
            setSitePSHB(true);
            setUserPSHB(true);

            try {
              res.data.result.data.Customers.socialSecurityNumber =
                res.data.result.data.Customers.credencial;
              res.data.result.data.Customers.socialSecurity = "40080/000";
            } catch (e) {
              Swal.fire({
                allowOutsideClick: false,
                icon: "info",
                title:
                  '<h5 style="color: #224d7c">Plan de Salud no encontrado.</h5>',
                text: "No encontramos un Plan de Salud del Hospital Britanico asociado a la casilla de mail indicada, comunicate con nuestro equipo de ventas para solicitar un Plan.",
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Ventas",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location = "https://promociones.hbritanico.com.ar/";
                }
              });
            }

            setUser({ user: res.data.result });
            setInitialUser(res.data.result?.data?.Customers);
            setMainLoader(false);
          } else {
            setMainLoader(false);
            localStorage.clear();
            Swal.fire({
              allowOutsideClick: false,
              icon: "info",
              title:
                '<h5 style="color: #224d7c">Plan de Salud no encontrado.</h5>',
              text: "No encontramos un Plan de Salud del Hospital Britanico asociado a la casilla de mail indicada, comunicate con nuestro equipo de ventas para solicitar un Plan.",
              color: "#224d7c",
              confirmButtonColor: "#224d7c",
              confirmButtonText: "Ventas",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location = "https://promociones.hbritanico.com.ar/";
              }
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          localStorage.clear();
          setMainLoader(false);
        });
    } else {
      setMainLoader(false);
    }

    return () => {
      //Unmount
    };
  }, [clearContext]);

  return (
    <UserGlobalContextMemorySpace.Provider
      value={{
        mainLoader,
        setMainLoader,
        socialMediaData,
        setSocialMediaData,
        socialMediaModal,
        setSocialMediaModal,
        sitePSHB,
        setSitePSHB,
        userPSHB,
        setUserPSHB,
        user,
        setUser,
        familiarGroup,
        setFamiliarGroup,
        familiarGroupToken,
        setFamiliarGroupToken,
        familiarGroupComponent,
        setFamiliarGroupComponent,
        clearContext,
        setClearContext,
        reintegro,
        setReintegro,
        logout,
        openHelper,
        setOpenHelper,
        footerConfiguration,
        setFooterConfiguration,
        initialUser,
        setInitialUser,
      }}
    >
      {children}
    </UserGlobalContextMemorySpace.Provider>
  );
};
