import React from 'react';
import LogoutComponentPSHB from '../../../components/pshb/logout';
import "./logout.scss";

const LogoutPSHB = (props) => {

   
  return (
    <div className='logout'>
      <LogoutComponentPSHB />
      <div className="footer-pshb-logout"/> 

    </div>
  );
}

export default LogoutPSHB;
