import React, {useContext, useState} from 'react';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import logoPSHB from '../../../assets/img/pshb/logo.jpg'
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import bgImageLogin from './../../../assets/img/hb/image-login.jpg'
import bgImageLoginPSHB from './../../../assets/img/hb/test2.jpg'
import useProgressiveImage from "../../../hooks/useProgressiveImage";

const ValidateCredential = (props) => {

    const loadedBgImage = useProgressiveImage(props.pshb ? bgImageLoginPSHB : bgImageLogin)

    const [docType, setDocType] = useState("");
    const [documento, setDocumento] = useState("");
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [credential, setCredential] = useState(null);
    const [credentialLoader, setCredentialLoader] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams({});
    const shr = searchParams.get("SHR")

    const credentialValidation = async (body) => {
        try {
            let { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_STRAPI_QA}/external-user/credentialvalidation`,
                data: body
            });
            return data
        } catch (err) {
            return err;
        }
    };

    const SwalMessage = (icon, title, text, confirmButtonText) => {
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            color: '#224d7c',
            confirmButtonColor: '#224d7c',
            confirmButtonText: confirmButtonText
        })
    }

    const handlerRecuperarContraseña = async (event) =>{
        event.preventDefault();
        setErrorMsg("");
        if(shr && docType && documento){
            setCredentialLoader(true);
            const body = {
                credential: "",
                idcustomer: "",
                document: documento,
                documenttype: docType,
                accesToken: shr
            }
        
            let response = await credentialValidation(body);
            if(response.Credential){
                setCredential(response.Credential)
            }else{
                setErrorMsg("* Por favor ingrese un tipo de documento y N° de documento validos.");
            }
        setCredentialLoader(false);
        }else{
            setErrorMsg("* Por favor ingresa el tipo de documento y N° de documento.");
        }
    }

  return (
    <form onSubmit={handlerRecuperarContraseña}>
        <div className="d-flex align-items-center" style={{height: "100vh"}}>
            <div className="div-login">
                <div className='d-flex flex-column align-items-center'>
                    <img className='img-logo' src={logoPSHB}></img>
                    <div className='br'></div>
                    <h5 className='default-fontColor'>Validar Credencial Digital</h5>
                    <div className='br'></div>
                    {credential ? 
                        <>
                            <div className='card-type d-flex justify-content-center'>
                                <img src={`data:image/jpeg;base64,${credential}`} width="380px" />
                            </div>  
                        </> 
                    : 
                        <>
                            <select onChange={(e)=> setDocType(e.target.value)} className={`input-login shadow-blue`}>
                                <option className='option-default-size' value=""  hidden>Tipo de Documento</option>
                                <option value="1">DNI</option>
                                <option value="2">Pasaporte</option>                                                                
                            </select>
                            <div className='br'></div> 
                            <input 
                                onChange={(e)=> setDocumento(e.target.value.replace(/[^0-9]/g, ''))} 
                                value={documento} className={`input-login shadow-blue`} placeholder="N° de Documento">
                            </input>  
                            {errorMsg ? <div className='br'></div> : null}
                            <p style={{color: "red", fontWeight:"500"}}>{errorMsg}</p>
                            {credentialLoader ?
                                <button disabled={true} type='submit' className="button-loader shadow-blue">
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                </button>
                                :
                                <input className='button-continuar shadow-blue' type="submit" value="Continuar"/>
                            }
                        </>
                    }
                    <div className='br'></div> 
                    <Link to={'/'} className='link-noStyle'>Inicio</Link>
                </div>
            </div>
            <div className={"bg-image-pshb"} style={{ backgroundImage: `url(${loadedBgImage || ''})` }}>
                <div className="bg-image-text" >
                    <div className="bg-image-text">
                        <div className="transbox">
                            <h1>Bienvenido a mi PSHB Online</h1>
                            <h5>Verifica la credencial digital del Plan de Salud</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
  );
}

export default ValidateCredential;
