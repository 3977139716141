import React, {useContext, useEffect} from 'react';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import LoginHB from '../../../pages/hb/login';
import LoginPSHB from '../../../pages/pshb/login';
import { useNavigate } from 'react-router-dom';

const CommonLogin = (props) => {

    const navigate = useNavigate();

    const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser} = useContext(UserGlobalContextMemorySpace);

    const handleLoginPSHB = () =>{
        setUserPSHB(true);
        setUser({user: "PSHB User"})
    }
    const handleLoginHB = () =>{
        setUserPSHB(false);
        setUser({user: "HB User"})
    }

    const changeStack = () => {
        setUserPSHB(!userPSHB)
    }

    useEffect(()=>{

        //Mount: 
        if(props.redirect){
            navigate("/")
        }

    
        return () =>{
        //Unmount
    
        }
      }, [])


  return (
    <>
        <LoginPSHB pshb={true}></LoginPSHB>
    </>
  );
}

export default CommonLogin;
