import React from 'react';
import './informacion-util.scss'
import telutil1 from '../../../assets/img/pshb/telutil1.png'
import telutil2 from '../../../assets/img/pshb/telutil2.png'
import telutil3 from '../../../assets/img/pshb/telutil3.png'
import telutil_telefono from '../../../assets/img/pshb/telutil_telefono.png'
import telutil_whatsapp from '../../../assets/img/pshb/telutil_whatsapp.png'
import telutil_logohb from '../../../assets/img/pshb/telutil_logohb.png'
import dompurify from "dompurify";
import { fetch,fetchExternal } from '../../../utils/fetch';
import CommonLoader from '../../common/loader';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const InformacionUtilComponent = () => {




  const [loader, setLoader] = React.useState(true)
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getData();
  }, []);



  const getData = async () => {
    try {
      const { data } = await fetchExternal(`${process.env.REACT_APP_STRAPI_HOME_PLAN_SALUD}/informacion-utils?_locale=es`, "GET");
      // console.log(data[0]);
      setContenido(data[0]);
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  };

return(
  
        <>
        <section className='desktop'>
        <div className="container" >
          <div className="row mt-5">
            <div className="col-lg-12">            
              <h2 className="titulo-ayuda-ps">Conectate con nosotros</h2>
              <div className="separador-table-green-result" />
              <p className='descripcion-info-ps'>{contenido?.descripcion}</p>
            </div>
          </div>
          <div className="col-lg-12 fondo">
            <div className="row">
              <div className="col-lg-3">
                <img className="borde" src={telutil1} width="250px" />
              </div>
              <div className="col-lg-9">
                <div className="row h-100 d-flex align-items-center">                
                  
                  <div className="col-lg-6 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.whatsapp)}} />
                  </div>
                  <div className="col-lg-6 h-100 d-flex align-items-center justify-content-center">
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.centro_atencion_socio)}} />
                  </div>
                </div>
              </div>
            </div>
    
          </div>
    
          <div className="col-lg-12 fondo">
    
            <div className="row">
              <div className="col-lg-3">
                <img className="borde" src={telutil2} width="250px" />
              </div>
              <div className="col-lg-9 ">
                <div className="row h-100 d-flex align-items-center">                 
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.urgencias_emergencias)}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.turnos_odontologia)}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center">
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.asistencia_al_viajero)}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 fondo">
            <div className="row">
              <div className="col-lg-3">
                <img className="borde" src={telutil3} width="250px" />
              </div>
              <div className="col-lg-9 ">
                <div className="row h-100 d-flex align-items-center">                 
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.central_de_turnos)}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center" style={{borderRight: '4px solid #CEDCE9'}}>
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.turnos_diagnostico_x_imagenes)}} />
                  </div>
                  <div className="col-lg-4 h-100 d-flex align-items-center justify-content-center">
                    <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.teleconsulta)}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-pshb'></div>

        </section>



      <section className='mobile'>
      <div className="container" >
          <div className="row mt-5">
            <div className="col-lg-12">            
              <h2 className="titulo-ayuda-ps">Conectate con nosotros</h2>
              <div className="separador-table-green-result" />

              <p>{contenido?.descripcion}</p>
            </div>
          </div>
          </div>
          <div className='card-wrapper'>
             <Card style={{ width: '18rem' }}>      
                <Card.Body style={{paddingTop:'20px'}}>
                  <Card.Text>
                  <div className="innerHTML" style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: sanitizer(contenido?.whatsapp)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" id="centro-atencion" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.centro_atencion_socio)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem',  }}>      
                <Card.Body style={{paddingTop:'3.8rem'}}>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.urgencias_emergencias)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML"  id="odontologia" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.turnos_odontologia)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.asistencia_al_viajero)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.central_de_turnos)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.turnos_diagnostico_x_imagenes)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card style={{ width: '18rem' }}>      
                <Card.Body>
                  <Card.Text>
                  <div className="innerHTML" dangerouslySetInnerHTML={{__html: sanitizer(contenido?.teleconsulta)}} />
                  </Card.Text>
                </Card.Body>
              </Card>
    </div>
    <div className='footer-pshb'></div>


        
      </section>
      </>

);
}
export default InformacionUtilComponent;