import React from "react";
import tilde from "../../../assets/img/tilde-verde.svg";
import { useNavigate } from "react-router-dom";
import "./paga-factura.scss";
import NoAutorizado from "../no-autorizado";

const PagaFactura = (props) => {
  const navigate = useNavigate();

  const volver = () => {
    navigate("/");
  };

  return (
    <>
      {props.holder === "T" ? (
        <>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: props.loggedIn ? "50vh" : "50vh" }}
          >
            <div className="">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img className="logoTilde" src={tilde}></img>
                <div className="br"></div>
                <div className="br"></div>
                <h5 className="titulo-ayuda-ps">
                  <center style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    Disculpas, no hemos podido localizar la factura
                    correspondiente para procesar tu pago. Te recomendamos
                    ponerte en contacto con nuestro equipo de atención al socio
                    a través de 0810-999-7742 o WhatsApp 11-3015-9749 de lunes a
                    viernes de 8 a 20 hs para verificar tu situación.
                  </center>
                </h5>
                <div className="br"></div>
                <button
                  onClick={volver}
                  className="boton-confirmar boton-paga"
                  type="submit"
                  value="Continuar"
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NoAutorizado />
        </>
      )}
    </>
  );
};

export default PagaFactura;
