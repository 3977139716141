import { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const useFG = () => {
  const { setFamiliarGroup, user } = useContext(UserGlobalContextMemorySpace);

  const setOnlyGF = (data) => {
    const customer = user?.user?.data?.Customers;
    const result = data?.filter((x) => x.dni != customer.dni);
    setFamiliarGroup(result);
  };
  return {
    setOnlyGF,
  };
};

export default useFG;
