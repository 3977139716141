import React from 'react';
import logo from '../../../assets/img/hb/plainIsologotipo.png'

const MainCommonLoader = (props) => {
    
    return (
        <>
            
            <div className='d-flex flex-column justify-content-center align-items-center' style={{height: "100vh"}}>
                <div className='d-flex justify-content-center'>
                    <img className='img-logo' src={logo}></img>
                </div>
                <div className='d-flex justify-content-center align-items-center' style={{marginTop: "30px"}}>
                    <div style={{width: "1rem", height: "1rem", color: "#5d84aa"}} className="spinner-grow text-muted"></div> 
                    <div style={{marginLeft: "5px", width: "1rem", height: "1rem", color: "#5d84aa"}}className="spinner-grow text-muted"></div>
                    <div style={{marginLeft: "5px", width: "1rem", height: "1rem", color: "#5d84aa"}}className="spinner-grow text-muted"></div>
                </div>
            </div>
        </>

    );
}
export default MainCommonLoader;