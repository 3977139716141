import React, {FC} from 'react';
import { Accordion } from 'react-bootstrap';
import dataAcordions from './ayuda-ps.json';
import simboloPregunta from '../../../assets/img/pshb/preguntaVerde.png'
import './ayudaps.scss';
import {fetchExternal} from '../../../utils/fetch'


const AyudaPSComponent= () => {
 const [contenido, setContenido] = React.useState(null)
const [loader, setLoader] = React.useState(true)
const [titulo,setTitulo]=React.useState("")
const [descripcion,setDescripcion]=React.useState("")



React.useEffect(() => {
    getData();
  }, []);
  
    const getData = async () => {
        try {
          const { data } = await fetchExternal(`${process.env.REACT_APP_STRAPI_QA}/ayudas?idEmpresa=2&codigo=preguntasFrecuentesPS`, "GET");              
          setContenido(data[0].preguntas);
          setTitulo(data[0].titulo)
          setDescripcion(data[0].descripcionCorta);
        //   console.log("🚀AyudaPreguntas ~ file: index.js ~ line 49 ~ getData ~ data[0]", data[0])
          setLoader(false)
        } catch (error) {
          setLoader(false)
        }
      };
    const RenderAccordion = ({ items }) => {
        return (
            <Accordion>
                {items && items.map((data, index) => (
                    <Accordion.Item className="mb-4" key={index} eventKey={index}>
                    <Accordion.Header className="header-accordion " id='caja-ayuda'><img style={{width: '28px',color: "blue"}} className="me-4" src={simboloPregunta} />{data.descripcionCorta}</Accordion.Header>
                    <Accordion.Body className='accordion-text'>
                        <div className="">
                            {data.respuestas.map((itemsAcordion, index) => (
                                <div classaName="container">
                                <div className="col">
                                    {/* <img src={CheckIcon} alt="check-icon" className="me-2" /> */}
                                    {itemsAcordion.descripcion}
                                </div>
                                {itemsAcordion.multimedia &&
                                <div className="col">
                                    {/* <img src={CheckIcon} alt="check-icon" className="me-2" /> */}
                                    <img src={itemsAcordion.multimedia.url} style={{width:'100%'}}></img>
                                </div>}
                                </div>
                            ))}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                ))}
            </Accordion>
        );
    }
    
    return(
        <section className='' style={{overflow:"hidden"}}>
        <div className="container container-help mt-5 p-4" style={{maxWidth:"100%",paddingRight:"0px"}}>
            <div className="row">
                <h2 className='titulo-ayuda-ps'>{titulo}</h2>
                <p className='subtexto-ayuda-ps'>{descripcion}</p>
            </div>
            <div className="separador-table-green-result" style={{marginBottom:"1.5rem"}}></div> 
            <div className="row ">
                <div className="col-lg-12">
                    <RenderAccordion items={contenido} />
                </div>
            </div>
        
        </div>
        <div className='footer-pshb' style={{marginLeft:'0px'}}></div>
        
        </section>
        
    )
}
export default AyudaPSComponent;