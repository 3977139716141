import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import "./contacto.scss";
import contacto from "../../../assets/img/pshb/bg-dejanos-tu-consulta.jpg";
import { fetchExternal } from "../../../utils/fetch";
import { useToggle } from "../../../hooks/useToggle";
import { validateModelPS } from "./validationSchema";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { PoliticaProteccionComponent } from "../../../components/common/footer/politica-proteccion";
import { BASE_URL_STRAPPI } from "../../../utils/constants";

const DejanosTuConsultaPage = () => {
  const captcha = React.useRef(null);
  const politicaContactoToggle = useToggle();
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );

  const initialValues = {
    nombre: user.user.data.Customers.firstName,
    apellido: user.user.data.Customers.lastName,
    email: user.user.data.Customers.email,
    celular: temporalUser.user.data.Customers.mobile,
    motivo: "",
    mensaje: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: "",
  };

  const onSubmit = async (values, resetForm) => {
    if (captcha.current?.getValue()) {
      const correo = {
        to: process.env.REACT_APP_MAIL_CONTACTO,
        subject: "HB Online - Solicitud de contacto ",
        text: `<b>Nombre</b> ${values.nombre} <br/>
        <b>Apellido</b> ${values.apellido} <br/>
        <b>Email</b> ${values.email} <br/>
        <b>Celular</b> ${values.celular} <br/>        
        <b>Motivo</b> ${values.motivo} <br/>        
        <b>Mensaje</b> ${values.mensaje} <br/>        
        `,
      };
      //<b>Opción</b> ${values.opcion} <br/>
      const { data } = await fetchExternal(
        BASE_URL_STRAPPI + "/email",
        "post",
        correo
      );
      resetForm();
      captcha.current?.reset();
      Swal.fire("", "Solicitud de contacto enviada con éxito", "success");
    } else {
      Swal.fire("", "Debe indicar que no es un robot", "error");
    }
  };

  const handleChangeCel = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyNumbers = /^[0-9]+$/; // Expresión regular para validar números
    if (value === "" || (onlyNumbers.test(value) && value.length <= 12)) {
      setFieldValue(e.target.name, value);
    }
  };

  return (
    <section
      className="section_contacto"
      style={{ minHeight: "calc(100vh - 25px - 100px - 200px)" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-5">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validateModelPS}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="section_contacto_form" onSubmit={handleSubmit}>
                  <h2 className="subtitle-ps" style={{ marginTop: "3rem" }}>
                    <b>Dejanos tu consulta</b>
                    <div className="separador-form-comentario" />
                  </h2>
                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="input-title-pshb">Nombre</label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="nombre"
                        value={values.nombre}
                        className="form-control b-radius-20 shadow-blue"
                        disabled
                      />
                      {errors.nombre && touched.nombre && (
                        <div className="text-danger">{errors?.nombre}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="input-title-pshb">Apellido</label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="apellido"
                        value={values.apellido}
                        className="form-control b-radius-20 shadow-blue"
                        disabled
                      />
                      {errors.apellido && touched.apellido && (
                        <div className="text-danger">{errors?.apellido}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="input-title-pshb">Email</label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        className="form-control b-radius-20 shadow-blue"
                        disabled
                      />
                      {errors.email && touched.email && (
                        <div className="text-danger">{errors?.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="input-title-pshb red-asterisk">
                        Celular <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        name="celular"
                        value={values.celular}
                        maxLength={12}
                        onChange={(e) => handleChangeCel(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      {errors.celular && touched.celular && (
                        <div className="text-danger">{errors?.celular}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-3">
                      <label className="input-title-pshb red-asterisk">
                        Motivo <span>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name="motivo"
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option>Seleccione una opción</option>
                        <option>Autorizaciones</option>
                        <option>Facturacion y Cobranzas</option>
                        <option>Accesos a PSHB Online</option>
                        <option>Credencial Digital</option>
                        <option>Farmacia</option>
                        <option>Sugerencias</option>
                        <option>Turnos en el HB</option>
                        <option>Otros</option>
                      </select>
                      {errors.motivo && touched.motivo && (
                        <div className="text-danger">{errors?.motivo}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-12">
                      <label className="input-title-pshb mb-2 red-asterisk">
                        Mensaje <span>*</span>
                      </label>
                      <textarea
                        name="mensaje"
                        value={values.mensaje}
                        maxLength={500}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                        rows="4"
                      ></textarea>
                      {errors.mensaje && touched.mensaje && (
                        <div className="text-danger">{errors?.mensaje}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 form-check">
                    <input
                      name="aceptoPrivacidad"
                      value={values.aceptoPrivacidad}
                      onChange={handleChange}
                      className="form-check-input"
                      type="checkbox"
                      id="defaultCheck1"
                    />
                    <label
                      class="form-check-label red-asterisk"
                      for="defaultCheck1"
                      id="politicas-hb"
                    >
                      Acepto la{" "}
                      <a
                        href="javascript:void(0)"
                        onClick={politicaContactoToggle.onToggle}
                      >
                        {" "}
                        política de privacidad
                      </a>{" "}
                      del Hospital Británico <span>*</span>
                    </label>
                    <PoliticaProteccionComponent
                      visible={politicaContactoToggle.toggle}
                      onToggle={politicaContactoToggle.onToggle}
                    />
                    {errors.validateAceptoPrivacidad &&
                      touched.validateAceptoPrivacidad && (
                        <div className="text-danger">
                          {errors?.validateAceptoPrivacidad}
                        </div>
                      )}
                  </div>
                  <div className="mb-4 captcha">
                    <div className="row">
                      <div className="col">
                        <div id="botCaptcha">
                          <ReCAPTCHA
                            ref={captcha}
                            sitekey={process.env.REACT_APP_CAPTCHA}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button type="submit" className="boton-contacto">
                      Enviar
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="col">
            <img src={contacto} className="img-contacto" alt="bg-contacto" />
          </div>
        </div>
      </div>
      <div className="footer-pshb" />
    </section>
  );
};
export default DejanosTuConsultaPage;
