import validarCBU from "../../../../utils/cbu";
import verifyCuit from "../../../../utils/cuit";

const validate = values => {

    const errors = {};
 
    if (!values.titularCuenta) {
 
      errors.titularCuenta = 'Requerido';
 
    }else if(values.titularCuenta.length<3)
    {
        errors.titularCuenta="Titular incorrecto";
    }

    var patternNumber = /^\d+\.?\d*$/;

    if(!values.cbu)
    {
        errors.cbu="Requerido";
    }else if(values.cbu.length!==22)
    {
        errors.cbu="CBU incorrecto";
    }else if(!patternNumber.test(values.cbu))
    {
        errors.cbu="Solo se permiten números";
    }

    if(!values.cuit)
    {
        errors.cuit="Requerido";
    }else if(values.cuit.length!==13)
    {
        errors.cuit="CUIT incorrecto";
    }
    //else if(!verifyCuit(values.cuit.replaceAll("-","")))
    // {
    //     errors.cuit="CUIT incorrecto"
    // }

    if(!values.nroCuenta)
    {
        errors.nroCuenta="Requerido"
    }else if(!patternNumber.test(values.nroCuenta))
    {
        errors.nroCuenta="Solo se permiten números";
    }

    if(!values.tipoCuenta)
    {
        errors.tipoCuenta="Requerido"    
    }

    if(!values.banco)
    {
        errors.banco="Requerido"    
    }

    
    if (!values.vinculo) {
 
        errors.vinculo = 'Requerido';
   
    }

    if (!values.constancia) {
 
        errors.constancia = 'Requerido';
   
    }
 
 
    return errors;
 
  };

  export default validate;