import "./dato-contacto.scss";
import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import { useFormik } from "formik";
import { validateModel } from "./validationSchema";

export const DatoContactoComponent = ({
  activo = false,
  editando = false,
  onEditando = () => null,
  confirmar = () => null,
  cancelar = () => null,
  guardando,
}) => {
  const { userPSHB, user, setInitialUser, initialUser } = useContext(
    UserGlobalContextMemorySpace
  );
  const [isDisableConfirm, setIsDisableConfirm] = React.useState(true);
  const [claseCampo, setClaseCampo] = React.useState("");
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );

  React.useEffect(() => {
    if (editando) {
      setClaseCampo("campo editandoCampo");
    } else {
      setClaseCampo("campo");
    }
  }, [editando]);

  const changeValue = (e) => {
    if (e.target.name === "phone" || e.target.name === "mobile") {
      const { value } = e.target;
      const regex = /^[0-9]$/;

      if (!regex.test(value.at(-1))) {
        e.target.value = value.substring(0, value.length - 1);
      }
    }
    temporalUser.user.data.Customers = {
      ...temporalUser.user.data.Customers,
      [e.target.name]: e.target.value,
    };
    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));
    formik.setFieldValue([e.target.name], e.target.value);
    handleDisableBtnConfirm();
  };

  const initialValues = {
    phone: temporalUser.user.data.Customers.phone,
    mobile: temporalUser.user.data.Customers.mobile,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateModel,
  });

  const handleDisableBtnConfirm = () => {
    if (!initialUser) return;

    console.log(temporalUser.user.data.Customers.mobile);
    console.log(initialUser.mobile);

    if (
      initialUser.mobile != temporalUser.user.data.Customers.mobile ||
      initialUser.phone != temporalUser.user.data.Customers.phone
    ) {
      setIsDisableConfirm(false);
      console.log("enable");
      return;
    }

    console.log("disable");
    setIsDisableConfirm(true);
    return;
  };

  const handleEdit = () => {
    setInitialUser({ ...temporalUser.user.data.Customers });
    onEditando();
    setIsDisableConfirm(true);
  };

  return (
    <div className={activo ? "" : "d-none"}>
      <div className={activo ? "formActivo" : "formInactivo"}>
        {/* LINEA SUPERIOR */}
        <div
          className={
            activo
              ? "lineaCabActiva"
              : userPSHB
              ? "lineaCabInactivaPSHB"
              : "lineaCabInactiva"
          }
        />

        {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}
        <div className="botones">
          {!editando && (
            <button
              className="botonEditar"
              onClick={() => (activo ? handleEdit() : null)}
            >
              <FiEdit2 /> Editar
            </button>
          )}
          {editando && (
            <>
              <button
                className={`botonEditar ${
                  isDisableConfirm && "disabled-btn-contacto"
                }`}
                onClick={() => (guardando ? null : confirmar(temporalUser))}
              >
                {guardando ? (
                  <>
                    <div
                      style={{
                        verticalAlign: "inherit",
                        marginLeft: "5px",
                        width: "0.7rem",
                        height: "0.7rem",
                        color: "rgb(93, 132, 170)",
                      }}
                      className="spinner-grow text-muted"
                    ></div>
                    <div
                      style={{
                        verticalAlign: "inherit",
                        marginLeft: "5px",
                        width: "0.7rem",
                        height: "0.7rem",
                        color: "rgb(93, 132, 170)",
                      }}
                      className="spinner-grow text-muted"
                    ></div>
                    <div
                      style={{
                        verticalAlign: "inherit",
                        marginLeft: "5px",
                        width: "0.7rem",
                        height: "0.7rem",
                        color: "rgb(93, 132, 170)",
                      }}
                      className="spinner-grow text-muted"
                    ></div>
                  </>
                ) : (
                  <>
                    <FiCheck /> Confirmar
                  </>
                )}
              </button>
              <button
                className="botonCancelar"
                onClick={() => cancelar(setTemporalUser)}
              >
                <FiX /> Cancelar
              </button>
            </>
          )}
        </div>

        <form className={userPSHB ? "formPSHB" : "formHB"}>
          <div className={activo ? "lineaActiva" : "lineaInactiva"} />
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Teléfono fijo:</span>
            <span className="perfil-valor">
              {!editando ? (
                temporalUser.user.data.Customers.phone
              ) : (
                <input
                  type="text"
                  className="input-editing"
                  name="phone"
                  maxLength={12}
                  value={temporalUser.user.data.Customers.phone}
                  onChange={changeValue}
                  onBlur={changeValue}
                />
              )}
            </span>
          </div>
          <div className="text-danger-white">{formik.errors.phone}</div>

          <div className={activo ? "lineaActiva" : "lineaInactiva"} />
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Teléfono celular:</span>
            <span className="perfil-valor">
              {!editando ? (
                temporalUser.user.data.Customers.mobile
              ) : (
                <input
                  type="text"
                  className="input-editing"
                  name="mobile"
                  maxLength={12}
                  value={temporalUser.user.data.Customers.mobile}
                  onChange={changeValue}
                  onBlur={changeValue}
                />
              )}
            </span>
          </div>
          <div className="text-danger-white">{formik.errors.mobile}</div>

          <div className={activo ? "lineaActiva" : "lineaInactiva"} />
          <div className="campo campo-perfil-email">
            <span className="perfil-etiqueta" style={{ alignSelf: "flex-end" }}>
              Mail:
            </span>
            <span className="perfil-valor">
              {temporalUser.user.data.Customers.email}
            </span>
          </div>
          <div className="text-danger-white">{formik.errors.email}</div>
        </form>
      </div>
    </div>
  );
};
