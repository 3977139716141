import React from "react";
import "./customSelect.scss";
import { CUSTOM_SELECT_COLOR_BLUE, CUSTOM_SELECT_COLOR_RED } from '../../common/customSelect/constants';
import { borderColor } from "@mui/system";


class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    // @defaultSelectText => Show default text in select
    // @showOptionList => Show / Hide List options
    // @optionsList => List of options
    this.state = {
      defaultSelectText: "",
      showOptionList: false,
      optionsList: []
    };
    
  }


  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      defaultSelectText: this.props.defaultText
    });
  }

  
  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  static getDerivedStateFromProps(props, state) {
    // Cada vez que el usuario actual cambia,
    // Reiniciar cualquier parte del estado que esté atada a ese usuario.
    // En este ejemplo, es solo email.    
    // console.log(props.defaultText,state.defaultSelectText);
    if (props.defaultText !== state.defaultSelectText) {
      return {
        ...state,defaultSelectText: props.defaultText,      
      };
    }
    return null;
  }


  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = e => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      // this.setState({
      //   showOptionList: false
      // });
    }
  };

  // This method handles the display of option list
  handleListDisplay = () => {    
    
    if(this.props.optionsList.length)
    {
    this.setState(prevState => {
      return {
        showOptionList: !prevState.showOptionList
      };
    });
    }

    this.props.onClick();

  };

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick = e => {    
    var id=e.target.getAttribute("data-id");
    var text=e.target.getAttribute("data-name");
    this.setState({
      defaultSelectText: e.target.getAttribute("data-name"),
      showOptionList: false
    });    
    this.props.onChange(id,this.props.name,text);
  };

  render() {
    const { optionsList,type,width,fontFamily,fontSize,close } = this.props;
    const { showOptionList, defaultSelectText } = this.state;
    
    const border=this.props.error?"border-error":"";

    return (        
      <div  style={{width,fontFamily,fontSize}} className={(showOptionList &&  optionsList[0].name!=="" && !close) ? `custom-select-container-active shadow-blue ${type}` : `custom-select-container shadow-blue ${type} ${border}`} >
        <div
          className={(showOptionList && optionsList[0].name!=="" && !close) ? "selected-text active-item" : type===CUSTOM_SELECT_COLOR_RED?"selected-text selected-text-red":"selected-text"}          
          onClick={this.handleListDisplay}
        >
          {defaultSelectText}
        </div>
        {(showOptionList && optionsList[0].name!=="" && !close) ? <div className="split"></div>:<></>}
        {(showOptionList && optionsList[0].name!=="" && !close) && (
          
            <ul className="select-options" style={optionsList.length>7?{overflowY:"auto",maxHeight:"200px"}:{overflowY:"none"}}>
              {optionsList.map(option => {
                return (
                  <li                
                    className={`custom-select-option`}                  
                    data-name={option.name}
                    key={option.id}
                    onClick={this.handleOptionClick}
                    data-id={option.id}
                  >
                    {option.name}
                  </li>
                );
              })}
            </ul>
          
        )}
      </div>
    );
  }
}

export default CustomSelect;
