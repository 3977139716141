import "./style.scss";
import React, { useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import MecanismoHistorialComponent from "../../../components/pshb/mecanismo-historial";

const MecanismoIntegracionHistorial = () => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/mecanismo-integracion", name: "Mecanismo Integración" },
      { route: "/mecanismo-historial", name: "Historial" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <MecanismoHistorialComponent> </MecanismoHistorialComponent>
    </div>
  );
};

export default MecanismoIntegracionHistorial;
