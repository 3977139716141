import * as Yup from "yup";

export const validateModelPS = Yup.object().shape({
  nombreTarjeta: Yup.string().required("Campo obligatorio"),
  banco: Yup.string().required("Campo obligatorio"),
  tarjeta: Yup.string().required("Campo obligatorio"),
  // vigenteDesde: Yup.date().required("Campo obligatorio"),
  vigenteDesde: Yup.string().test(
    "match",
    "Fecha invalida, solo se podrá ingresar un periodo 2 meses superior al vigente",
    function (value) {
      if (!value) {
        return false;
      }

      const vigenteDesde = value.split("/");
      const today2 = new Date();
      today2.setMonth(today2.getMonth() + 2);
      const date2 = new Date(today2);
      date2.setMonth(vigenteDesde[0] - 1);
      date2.setFullYear(vigenteDesde[1]);

      return !(date2 < today2);
    }
  ),
  fechaVencimiento: Yup.string().test(
    "match",
    "Fecha invalida",
    function (value) {
      if (!value) {
        return false;
      }

      const fechaVencimiento = value.split("/");
      const today2 = new Date();
      const date2 = new Date(today2);
      date2.setMonth(fechaVencimiento[0] - 1);
      date2.setFullYear(fechaVencimiento[1]);

      return !(date2 < today2);
    }
  ),
  numeroTarjeta: Yup.number().required("Campo obligatorio"),
  picked: Yup.string().required("Seleccione un campo"),
  recaptcha: Yup.string().required("Por favor, completa el ReCAPTCHA."),
});
