import React, {useContext, useState, useEffect} from 'react';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import { FaUserCircle, FaAddressCard } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import style from './style.scss'



const UserSidebar = (props) => {



    const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser} = useContext(UserGlobalContextMemorySpace);
    const [showInfo, setShowInfo] = useState(false)

    useEffect(()=>{

        //Mount: 
    
        return () =>{
        //Unmount
    
        }
      }, [])

    return (
        <>
            <aside className={userPSHB?"asideWebPSHB d-flex justify-content-center" : "asideWebHB d-flex justify-content-center" } style={{minHeight:props.height}}>
                <div className='d-flex flex-column' style={{width: "100%"}}>
                    <div className='d-flex justify-content-center circleBig'>
                        <FaUserCircle size='80px' style={{color: "white"}}/>
                    </div>
                    <div className='d-flex justify-content-center' style={{paddingTop: "40px", width: "100%" }} >
                        <h4 style={{color: "white", textAlign: "center"}}>{user.user.data.Customers.firstName} {user.user.data.Customers.lastName}</h4>                     
                    </div>
                    <div className='d-flex justify-content-center'>
                        <h6 style={{color: "white"}}></h6>                     
                    </div>
                    <div className='separador' style={{width:"90%"}}/>
                    <div className='d-flex flex-column justify-content-center' style={{paddingTop: "10px", width: "100%"}}>
                        {props.routes && props.routes.routes.map((data, index)=>{
                            // console.log(data.route)
                            return(
                                <div className='d-flex justify-content-center' style={{paddingBottom: "5px", fontSize:"20px", width: "100%"}}>
                                    <NavLink className="navigate-button" to={data.route} activeclassname="active" style={{width:"100%", textAlign: "center",lineHeight:"90%",marginBottom:"10px"}}><span>{data.name}</span></NavLink>
                                </div>
                            )
                        })}              
                    </div>
                </div>
            </aside>            
        </>  


    )
  }
  
  export default UserSidebar;