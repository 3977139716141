import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import UserSidebar from '../../../components/common/user-sidebar';
import TurnosComponent from '../../../components/hb/turnos';
import style from './style.scss'

const Turnos = () => {

    const [routes, setRoutes] = useState({routes: []})
    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        setHeight(ref.current.clientHeight);
      }, []);

    return (
        <div className='d-flex help-page' ref={ref} >
            <UserSidebar routes={routes} height={height} ></UserSidebar>
            <div className='recupero-contrasena d-flex justify-content-center align-items-center' >
              <TurnosComponent/>
            </div>
        </div>
    )
  }
  
  export default Turnos;