import React, {useState,useContext, useEffect} from 'react';
import EditarIcon from '../../../assets/img/editar.png';
import EliminarIcon from '../../../assets/img/eliminar.png';
import "./family-group.scss";
import {useToggle} from "../../../hooks/useToggle";
import { InformacionComponent } from './informacion';
import InformationIcon from "../../../assets/img/information.png";
import { Button, Col, Row } from 'react-bootstrap';
import { CUSTOM_SELECT_COLOR_WHITE } from '../../common/customSelect/constants';
import CustomSelect from "../../common/customSelect";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditarFamiliarComponent } from './editar-familiar';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import axios from 'axios';
import { FiUserPlus } from "react-icons/fi";
import FamilyGroupPSHB from '../../../containers/familiar-group-pshb';


const FamilyGroupComponent = () => {


    const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser} = useContext(UserGlobalContextMemorySpace);


    const infoToggle = useToggle();    
    const editarFamiliarToggle = useToggle(); 
    const [isAddFamily,setAddFamily]=useState(false);  
    const [disabled,setDisabled]=useState(true);
    const [render, setRender] = useState(false);
    const [loader, setLoader] = useState(true);
    
    var documentList= [
      { id: 1, name: "DNI" },               
    ]; 
    
    var relationshipList= [
        { id: 1, name: "Hijo/a" },   
        { id: 1, name: "Papá" },         
        { id: 1, name: "Mamá" },         
    ];

    useEffect(()=>{
        //Mount: 

        return () =>{
        //Unmount
    
        }
      }, [])
    
    

    return(
        <>
        { !isAddFamily ?
        <section className="">   
        <div className='row'>     
            <FamilyGroupPSHB></FamilyGroupPSHB>  
            </div>    
            {/* <div className={userPSHB? 'footer-pshb' : 'footer-hb'} style={{width:"100%"}}></div>           */}
         </section>
        :
        <>

            <section>
            <Row id='family-add-container'>
                <Col md={6}>     
                <div className='header-add-family'>
                    <div id="header-add-family-title">
                        <span className='section_hb_title'>
                            Agregar familiar
                        </span>
                        <span className='section_hb_subtitle'>
                            Paso N°1: Completar los datos personales del paciente que desea agregarasu grupo familiar
                        </span>
                    </div>
                    {/* <div id="header-add-family-separator"></div> */}
                </div>         
                <div className='body-add-family'>
                    <div className="row mb-2">
                        <div className="col-md-4">
                            <span className='hb-label'>Nombre completo</span>
                        </div>
                        <div className="col-md-8">
                            <input type="text" className='input-login w-100 shadow-blue'/>
                        </div>
                    </div>
                    
                    <div className="row mb-2">
                        <div className="col-md-4">
                            <span className='hb-label'>Tipo documento</span>
                        </div>
                        <div className="col-md-2">    
                            <CustomSelect                                
                                    defaultText={"DNI"}
                                    optionsList={documentList}                                
                                    type={CUSTOM_SELECT_COLOR_WHITE}
                                    width="100px"
                                />
                        </div>
                        <div className="col-md-2">
                                <span className='hb-label'>Documento</span> 
                        </div>                       
                        <div className="col-md-4">
                                <input type="text" className='input-login w-100 shadow-blue'/>
                            </div>                                        
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-4">
                            <span className='hb-label'>Parentesco</span>
                        </div>
                        <div className="col-md-8">
                            <CustomSelect                                
                                defaultText={"Hijo/a"}
                                optionsList={relationshipList}                                
                                type={CUSTOM_SELECT_COLOR_WHITE}
                                width="100px"
                            />
                        </div>
                     </div>                     
                    </div> 
                    <div className="row mt-5">
                        <div className="col-md-12 family-add-buttons">
                            <div className="btn btn-hb-secondary-without-border" onClick={() => setAddFamily(false)}>Cancelar</div>
                            <div className="btn btn-hb-primary-without-border shadow-blue" onClick={() => setDisabled(false)}>Aceptar</div>
                        </div>
                    </div>     
                </Col>
                <Col md={6} className={disabled?"opacity":""}>                    
                <div className='header-add-family'>
                <div id="header-add-family-title">                        
                        <span className='section_hb_subtitle mt-3'>
                            El archivo debe ser menos a 3 MB y debe visualizarse con calidad.
                        </span>
                    </div>
                    {/* <div id="header-add-family-separator"></div> */}
                </div>
                <div className='body-add-family'>
                <div className="row mb-2">
                    <div className="col-md-5">
                        <span className='hb-label'>Documento</span>
                    </div>
                    <div className='col-md-7'>
                        {/* <input type="text" disabled={disabled} className='input-login w-100 shadow-blue'/> */}
                        
                        <div className="input-icons me-3">
                            <div className="customIcon-family-group">
                                <FontAwesomeIcon icon={faPaperclip}/>
                                <span>Adjuntar</span>
                            </div>
                            <input className="input-login w-100 shadow-blue" 
                                type="text" 
                                placeholder=""/>
                        </div>
                        
                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-md-5'>
                        <span className='hb-label'>Partida de nacimiento</span>
                    </div>
                    <div className='col-md-7'>
                        {/* <input type="text" disabled={disabled} className='input-login w-100 shadow-blue'/> */}
                        <div className="input-icons me-3">
                            <div className="customIcon-family-group">
                                <FontAwesomeIcon icon={faPaperclip}/>
                                <span>Adjuntar</span>
                            </div>
                            <input className="input-login w-100 shadow-blue" 
                                type="text" 
                                placeholder=""/>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-md-5'>
                        <span className='hb-label'>Certificado de discapacidad</span>
                    </div>
                    <div className='col-md-7'>
                        {/* <input type="text" disabled={disabled} className='input-login shadow-blue'/> */}
                        <div className="input-icons me-3">
                            <div className="customIcon-family-group">
                                <FontAwesomeIcon icon={faPaperclip}/>
                                <span>Adjuntar</span>
                            </div>
                            <input className="input-login w-100 shadow-blue" 
                                type="text" 
                                placeholder=""/>
                        </div>
                    </div>
                </div>               
                </div>
                <div className='row mt-5'>
                    <div className='col-md-12 family-add-buttons'>
                        <Button disabled={disabled} className="btn btn-hb-secondary-without-border" onClick={() => setAddFamily(false)}>cancelar</Button>
                        <Button disabled={disabled} className="btn btn btn-hb-primary-without-border shadow-blue">Aceptar</Button>
                    </div>
                </div>
                </Col>
            </Row>
            <div className={userPSHB? 'footer-pshb-large' : 'footer-hb'}></div>

            </section>
        </>        
        }
        </>
    )
}

export default FamilyGroupComponent;   