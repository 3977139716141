import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import DejanosTuConsultaPage from "../../../components/pshb/contact";

import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import "./contacto.scss";

const ContactoPS = () => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/informacion-util", name: "Información útil" },
      { route: "/contacto", name: "Contacto" },
      // { route: "/grupo-familiar", name: "Grupo familiar" },
      // { route: "/informacion-util", name: "Credencial digital" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <DejanosTuConsultaPage></DejanosTuConsultaPage>
    </div>
  );
};

export default ContactoPS;
