import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./politica-proteccion.scss";
import { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext.jsx";
import DOMPurify from "dompurify";

export const PoliticaProteccionComponent = ({ visible = false, onToggle }) => {
  const { user,footerConfiguration } =
    useContext(UserGlobalContextMemorySpace);
    const sanitizer = DOMPurify.sanitize;


  const ContenidoPolitica=()=>{
    return (
      <>
      <h5>Introducción</h5>
          <p>
            El Hospital Británico de Buenos Aires (“Hospital Británico”) se
            encuentra comprometido con el resguardo de la intimidad de sus
            pacientes y usuarios de éste sitio web, y es por esto que a través
            de la presente Política de Protección de Datos Personales pretende
            hacer sus mejores esfuerzos para que los derechos de sus pacientes
            se encuentren protegidos. La presente Política de Protección de
            Datos Personales se aplica exclusivamente a los datos personales
            ofrecidos libre y voluntariamente al Hospital Británico por sus
            pacientes y tiene por objeto la protección de estos datos cuando se
            encuentren asentados en archivos, registros, bancos de datos u otros
            medios técnicos de tratamiento de datos, en cumplimiento con la
            Constitución Nacional Argentina y la Ley 25.326 y todas aquellas
            normas que la reglamenten, sustituyan o deroguen. En este sentido el
            Hospital Británico, en cumplimiento del objetivo propuesto y
            expresamente declarado por el legislador en la norma, garantiza que
            los datos recabados de sus pacientes y usuarios de este sitio web
            serán tratados siempre de modo tal que quede resguardada la
            finalidad protectora que dispone la ley.
          </p>
          <h5>Tus datos personales</h5>
          <p>
            En ciertas ocasiones el Hospital Británico necesita acceder a
            algunos de tus datos personales como, por ejemplo, tu nombre, tu
            domicilio, tu correo electrónico, tu número de socio del plan de
            salud o tu número telefónico. El Hospital Británico necesita esta
            información personal para poder brindar los servicios que ofrece en
            cumplimiento de su objeto social hacia la comunidad. Toda vez que el
            Hospital Británico te solicite tus datos personales, sea a través de
            su sitio web en internet, o personal o telefónicamente, lo hará en
            el afán de posibilitarte primordialmente el acceso a un mejor y más
            personalizado servicio de salud. En estos casos, el Hospital
            Británico almacenará tus datos personales únicamente en caso en que
            vos nos los proveas libre y voluntariamente, y en conocimiento de
            los términos de la presente Política de Protección de Datos
            Personales.
            <br />
            <br />
            El Hospital Británico te garantiza mantener tus datos personales de
            manera confidencial, evitando que terceros tengan acceso a los
            mismos. Sin perjuicio de ello, corresponde mencionar que el Hospital
            Británico permitirá a sus empleados, médicos y demás profesionales
            de la salud acceder a tus datos personales en los casos en que
            entendamos que tengan necesidades razonables para utilizar dicha
            información para brindarte una mejor atención, proveerte mejores
            servicios o simplemente a efectos de poder realizar su trabajo.
          </p>
          <h5>Finalidad</h5>
          <p>
            La recolección de tus datos de carácter personal realizada por el
            Hospital tiene por finalidad la autenticación de tu identidad como
            paciente o socio del plan de salud, la realización de comunicaciones
            de diversa índole, la posibilidad de ofrecerte y proveerte productos
            y servicios, la gestión y efectivización de donaciones para el
            Hospital Británico, la realización de evaluaciones estadísticas,
            entre otras.
          </p>
          <h5>Autorización de uso</h5>
          <p>
            Es importante que tengas presente que cuando provees tus datos
            personales al Hospital Británico en forma libre y voluntaria, lo
            estas autorizando expresamente para que los pueda utilizar
            exclusivamente con sujeción a los fines expuestos en la presente
            Política de Protección de Datos Personales.
          </p>
          <h5>Acceso de terceros a tus datos personales</h5>
          <p>
            El Hospital Británico te garantiza que mantiene tus datos personales
            de manera confidencial, evitando que terceros tengan acceso a los
            mismos. No obstante, es posible que en el marco del cumplimiento de
            su objeto social, el Hospital Británico contrate con terceros la
            realización de ciertas prestaciones o servicios. Estas prestaciones
            podrán consistir, entre otras, en la gestión de donaciones. Para la
            adecuada prestación de los servicios subcontratados, es posible que
            estos terceros accedan a las bases de datos del Hospital Británico.
            Sin embargo, el Hospital Británico no cede ni dispone en favor de
            estos terceros tus datos personales, por el contrario, resulta
            fundamental destacar que el acceso de éstos a las bases de datos del
            Hospital Británico se limita únicamente al cumplimiento de las
            actividades necesarias para la prestación del servicio
            subcontratado, encontrándose prohibida en el marco del contrato
            correspondiente la extracción de datos o la realización de copias
            totales o parciales de las bases de datos del Hospital Británico.
            Los terceros con quienes el Hospital Británico contrate ciertas
            prestaciones conforme lo manifestado hasta aquí, operan bajo
            contratos que regulan estrictamente la confidencialidad de la
            información, y disponen sanciones y penas por cada incumplimiento,
            mal uso o abuso de la misma.
          </p>
          <h5>
            Tus derechos de acceso, rectificación, actualización y supresión de
            datos personales
          </h5>
          <p>
            La presente política tiene también por finalidad garantizarte el
            acceso a tus derechos amparados por la normativa vigente. En tal
            sentido, vos podés ejercer tus derechos vinculados con el acceso,
            rectificación, actualización o supresión de sus datos personales de
            las bases de datos del Hospital Británico, conforme te explicaremos
            a continuación.
            <br />
            <br />
            Previo a acreditar tu identidad, vos tendrás la posibilidad de
            acceder a tus datos personales que se encuentren almacenados en los
            registros o bases de datos del Hospital Británico. Tu solicitud será
            respondida por el Hospital Británico por correo electrónico a la
            dirección que nos indiques dentro de los 10 (diez) días corridos de
            recepcionada, o por otro medio que nos especifiques en la solicitud.
            <br />
            <br />
            Asimismo, el Hospital Británico también te permite la posibilidad de
            rectificar, actualizar o suprimir (siempre que corresponda) tus
            datos personales almacenados en sus registros. El Hospital asume el
            compromiso de realizar las operaciones que solicites a fin de
            modificar tus datos personales en los términos de la presente
            Política de Protección de Datos Personales dentro de los 5 (cinco)
            días corridos de recepcionada la solicitud respectiva.
            <br />
            <br />
            Es importante que tengas en cuenta que la rectificación,
            actualización o supresión de datos personales no será realizada por
            el Hospital Británico cuando ello pudiera causar un perjuicio a
            derechos o intereses legítimos de terceros o cuando el Hospital
            Británico tenga la obligación legal de conservar determinados datos
            personales en cumplimiento de otras normas.
            <br />
            <br />A fin de ejercer tus derechos arriba mencionados, enviá un
            correo electrónico a{" "}
            <a href="mailto:compliance@hbritanico.com.ar">
              compliance@hbritanico.com.ar
            </a>{" "}
            especificando claramente si necesitás acceder a los datos personales
            que el Hospital Británico almacena sobre tu persona, o bien si
            necesitás actualizar, rectificar o suprimir todos o algunos de éstos
            describiendo con precisión la modificación solicitada. Es importante
            que sepas que el ejercicio de estos derechos es totalmente gratuito
            para vos.
          </p>
          <h5>Actualización de la Política de Privacidad</h5>
          <p>
            El Hospital Británico podrá rever, revisar y/o actualizar esta
            Política de Protección de Datos Personales en cualquier momento. En
            cada oportunidad en que las mismas resulten modificadas, el Hospital
            Británico procederá a publicar la versión mas actualizada en su
            página web, de manera que siempre puedas acceder al texto vigente.
          </p>
          <h5>Sobre las “cookies”</h5>
          <p>
            Eventualmente, el Hospital Británico podría utilizar cookies de
            navegación analíticas generadas a partir de la navegación web en los
            sitios del Hospital Británico para el seguimiento y análisis
            estadístico del comportamiento de los usuarios, gestión de los
            espacios publicitarios en base a criterios como la frecuencia y para
            la gestión de los espacios publicitarios según el perfil específico
            del usuario. En ningún caso las cookies proporcionan información
            personal del usuario, ya que no almacenan información personal, sólo
            información sobre el uso del sitio. Por cuestiones técnicas, las
            cookies son totalmente anónimas, y el usuario mantendrá pleno
            anonimato, aún frente al Hospital Británico.
          </p>
          <h5>
            ESTA POLITICA DE PROTECCION DE DATOS PERSONALES FUE ACTUALIZADA POR
            ÚLTIMA VEZ EL 16/08/2018
          </h5>
          </>
    )
  } 

  return (
    <Modal
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="custom-header">
        <Modal.Title
          className={"titulo-proteccion-pshb"}
        >
          {footerConfiguration?.titulo_politicas}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className={
            "politica-proteccion-pshb"
          }
        >
          <div
            className={              
                "barra-politica-proteccion-pshb"                
            }
          />
          {
            <div dangerouslySetInnerHTML={{__html: sanitizer(footerConfiguration?.contenido_politicas)}}></div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn2-color3-r"
          variant="primary"
          onClick={() => onToggle()}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
