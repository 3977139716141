import React from 'react';
import { Modal,Row,Col } from 'react-bootstrap';
import { ImCancelCircle } from 'react-icons/im';



import './style.scss'
export const Information = ({
    visible = false,
    onToggle,    
    tipo,
    sitio
}) => {
    
    const dniType={1:"Cédula",3:"Libreta",0:"SI"}
    //const messages={true:"Datos enviados.",false:"Se produjo un error al enviar los datos."}

    

    return (
        
            <Modal
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                show={visible}
                centered
                onHide={onToggle}
            >
            <div className={sitio==="HB"?"hb-style":"pshb-style"}>
            <div style={{height: "50vh"}} className="d-flex align-items-center">
                            <div style={{height: "100%", width: "100%"}} className="d-flex align-items-center">
                                <div style={{ width: "100%", width:"100%"}}>
                                    <Modal.Body style={{paddingLeft: "5vh", paddingRight: "5vh"}}>
                                        <div className='asociar-cuenta d-flex flex-column justify-content-center'>
                                            <p style={{fontSize: "25px", textAlign:"center", width: "100%"}}>
                                                    <ImCancelCircle size='40px' style={{color: "red", textAlign:"center"}}/>
                                            </p>
                                            <p style={{fontSize: "20px", textAlign:"center"}}>
                                                Su tipo de documento <span style={{fontWeight:"bold"}}>{dniType[tipo]}</span> ya no es válido. Por favor, para poder utilizar todas las funcionalidades del sitio, contáctese con nuestro Call Center <a href="tel://08102222748">(0810-222-2748)</a> para actualizarlo. Muchas gracias. 
                                            </p>
                                            
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                        
                                            <input className='button-continuar shadow-blue' type="button"  onClick={() => onToggle()} value="Continuar">
                                                
                                            </input>
                                        
                                        </div>
                                    </Modal.Body>
                                </div>
                            </div>
                        </div>
                </div>

            </Modal>
        
    )
}
