import React,{useContext} from 'react'
import { Button, Modal } from 'react-bootstrap'
import './refundView.scss'
import { UserGlobalContextMemorySpace } from '../../../../contexts/user/userGlobalContext';

export const RefundViewComponent = ({
    visible = false,
    onToggle    
}) => {
           

    const { reintegro} = useContext(UserGlobalContextMemorySpace);

    return (
        <Modal
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            show={visible}
            centered
            onHide={onToggle}        
        >
            <Modal.Header closeButton className='custom-header'>

            <div className='simple-header'>
                <h2 class="section_pshb_title"><b>Reintegro N° {reintegro?.nro_recibo}</b></h2>
                <div class="separador-table-green-result w-100"></div>
            </div>   
                             
            </Modal.Header>

            <Modal.Body className='custom-modal-body'>
            <div className='custom-container'>
            <div className="row">
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>Beneficiario</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.nombre} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>N° reintegro</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.nro_recibo} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
            </div>

            <div className="row">
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>CBU de transferencia</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.CBU} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>Monto presentado</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.presen_total} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>                   
            </div>

            <div className="row">
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>Estado</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.estado} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>Fecha de solicitud</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" readOnly={true} value={reintegro?.orden_fecha.substring(0,10)} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
            </div>

            <div className="row">
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>Monto aprobado</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.monto_aprobado} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
                <div className='section-date col-md-6'>
                    <span className='section_pshb_subtitle'>Fecha estimada de pago</span>                      
                    <div className="input-icons-date-pshb ">                                                    
                        <input type="text" value={reintegro?.fecha_est_pago} readOnly={true} class="input-login shadow-blue w-100"/>
                    </div>                                          
                </div>   
            </div>           

            <div className='row custom-footer'>
                <div className='"col-md-12 text-center'>
                <div class="btn btn-hb-secondary-without-border" onClick={()=>onToggle()}>Cerrar</div>
                </div>
            </div>
            </div>            
                
            <div className='img-footer-modal-pshb'></div>    
            </Modal.Body>            
            
            {/* <Modal.Footer>                            
            </Modal.Footer> */}

        </Modal>
    )
}
