import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaUserCircle } from "react-icons/fa";
import { NavLink, useNavigate, Link } from "react-router-dom";

// import Logo from '../../../assets/img/pshb/HBSideLeft.png';

import Logo from "../../../assets/img/logo-hb-no-padding.png";

import Home from "../../../assets/img/pshb/iconos-drawer/Home.png";
import Gestiones from "../../../assets/img/pshb/iconos-drawer/Gestiones.png";
import Facturacion from "../../../assets/img/pshb/iconos-drawer/Facturacion.png";
import Debito from "../../../assets/img/pshb/iconos-drawer/Debito-automatico.png";
import Contacto from "../../../assets/img/pshb/iconos-drawer/Contacto.png";
import Ayuda from "../../../assets/img/pshb/iconos-drawer/Ayuda.png";
import Perfil from "../../../assets/img/pshb/iconos-drawer/Perfil.png";

import { InformacionComponent as InformacionCredencial } from "../digital-credential/informacion";
import { InformacionComponent as InformacionFactura } from "../invoices/informacion";
import { InformacionComponent as InformacionGrupoFamiliar } from "../family-group/informacion";
import { useToggle } from "../../../hooks/useToggle";
import { myAxios } from "../../../utils/fetch";

const DrawerPSHB = () => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/ayuda", name: "Ayuda" },
      { route: "/cambiar-contrasena", name: "Cambiar contraseña" },
    ],
  });
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const navigate = useNavigate();
  const credencialToggle = useToggle();
  const facturaToggle = useToggle();
  const grupoFamiliarToggle = useToggle();
  const [linkFactura, setLinkFactura] = React.useState(null);

  const titular = user.user.data.Customers.socialSecurityNumber.split(" ")[1];

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  React.useEffect(() => {
    const socialSecurityNumber = user.user.data.Customers.socialSecurityNumber;

    linkPago(user.user.data.Customers.socialSecurityNumber.split(" ")[0])
      .then((result) => {
        //let factura = response.data.data.ds.slice(0, 1);
        // factura[0].link = result.data.data.link;
        if (!result.data.error) setLinkFactura(result.data?.data?.link);
      })
      .catch((err) => {
        // let factura = response.data.data.ds.slice(0, 1);
        // factura[0].link = null;
        // setFacturas(factura);
      });
  }, []);

  const linkPago = async (socialSecurityNumber) => {
    let url = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        `/patient/api/v1/pagos/getlinkpago?credentialNumber=${socialSecurityNumber}&idCustomer=${user.user.data.Customers.idCustomer}`,
      headers: {
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });

    return url;
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "74vw" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="drawer"
    >
      <div style={{ minHeight: "100vh" }}>
        <div
          className="d-flex flex-column"
          style={{ paddingLeft: "12px", paddingRight: "12px" }}
        >
          <div className="d-flex circleBig" style={{ paddingTop: "50px" }}>
            <FaUserCircle
              size="80px"
              style={{ color: "white", marginTop: "-20px" }}
            />
            <h6 className="drawer-name" style={{ color: "white" }}>
              {user.user.data.Customers.firstName +
                " " +
                user.user.data.Customers.lastName}
            </h6>
          </div>
          {/* <div className='d-flex justify-content-center' style={{paddingTop: "40px"}}>
                            
                        </div> */}
          {/* <div className='d-flex justify-content-center'>
                            <h6 style={{color: "white"}}>Edad: 36</h6>                     
                        </div> */}
          {/* <hr style={{border: "3px solid white", backgroundColor: "white", background: "white"}}/> */}
          <div class="separador-principal-pshb"></div>
          <div
            className="d-flex flex-column justify-content-center"
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <div className="d-flex mt-3 align-items-center titulo-enlace-pshb">
              <img src={Home} className="icono-titulo" />
              <span onClick={() => navigate("/")}>Home</span>
            </div>
            <div className="grupo-enlaces-pshb">
              <div className="separador-titulo titulo-enlace-secundario-pshb">
                <img src={Gestiones} className="icono-titulo" />
                <span>GESTIONES</span>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/credencial-digital"}
                  activeclassname="active"
                >
                  Credencial digital
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/autorizaciones"}
                  activeclassname="active"
                >
                  Autorizaciones
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/reintegro-estado"}
                  activeclassname="active"
                >
                  Consultá tus reintegros
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/reintegro-solicitud"}
                  activeclassname="active"
                >
                  Cargá un reintegro
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <a
                  href={
                    process.env.REACT_APP_PLAN_SALUD + "/formularios-utiles"
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  className="navigate-button enlace-drawer-pshb"
                  // onClick={redirectToExternalUrl}
                >
                  Formularios útiles
                </a>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/grupo-familiar"}
                  activeclassname="active"
                >
                  Grupo familiar
                </NavLink>
              </div>
            </div>

            <div className="grupo-enlaces-pshb">
              <div className="separador-titulo titulo-enlace-secundario-pshb">
                <img src={Facturacion} className="icono-titulo" />
                <span>FACTURACIÓN</span>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/facturas"}
                  activeclassname="active"
                >
                  Descargá tu factura
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                {/* <NavLink className="navigate-button enlace-drawer-pshb" to={"/paga-factura"} activeclassname="active">Pagá tu factura</NavLink> */}
                {linkFactura ? (
                  <>
                    {user.user?.data?.Customers?.holder === "T" ? (
                      <Link
                        to="#"
                        onClick={() => openInNewTab(linkFactura)}
                        className="navigate-button enlace-drawer-pshb"
                        activeclassname="active"
                      >
                        Pagá tu factura
                      </Link>
                    ) : (
                      <NavLink
                        className="navigate-button enlace-drawer-pshb"
                        to={"/paga-factura"}
                        activeclassname="active"
                      >
                        Pagá tu factura
                      </NavLink>
                    )}
                  </>
                ) : (
                  <NavLink
                    className="navigate-button enlace-drawer-pshb"
                    to={"/paga-factura"}
                    activeclassname="active"
                  >
                    Pagá tu factura
                  </NavLink>
                )}
              </div>
            </div>

            <div className="grupo-enlaces-pshb">
              <div className="separador-titulo titulo-enlace-secundario-pshb">
                <img src={Debito} className="icono-titulo" />
                <span>DÉBITO AUTOMÁTICO</span>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/debito-cuenta"}
                  activeclassname="active"
                >
                  En cuenta bancaria
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/debito-automatico"}
                  activeclassname="active"
                >
                  En tarjeta de crédito
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/historial-debito"}
                  activeclassname="active"
                >
                  Historial
                </NavLink>
              </div>
            </div>

            <div className="grupo-enlaces-pshb">
              <div className="separador-titulo titulo-enlace-secundario-pshb">
                <img src={Contacto} className="icono-titulo" />
                <span>CONTACTO</span>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/informacion-util"}
                  activeclassname="active"
                >
                  Información útil
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/contacto"}
                  activeclassname="active"
                >
                  Dejanos tu comentario
                </NavLink>
              </div>
            </div>

            <div className="grupo-enlaces-pshb">
              <div className="separador-titulo titulo-enlace-secundario-pshb">
                <img src={Ayuda} className="icono-titulo" />
                <span>AYUDA</span>
              </div>

              <div className="d-flex justify-content-justify">
                <span
                  className="subtitulo-enlace-pshb enlace-drawer-pshb"
                  onClick={() => credencialToggle.onToggle()}
                >
                  Credencial digital
                </span>
              </div>

              <div className="d-flex justify-content-justify">
                <span
                  className="subtitulo-enlace-pshb enlace-drawer-pshb"
                  onClick={() => facturaToggle.onToggle()}
                >
                  Autogestión
                </span>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/ayuda"}
                  activeclassname="active"
                >
                  Soporte web
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <span
                  className="subtitulo-enlace-pshb enlace-drawer-pshb"
                  onClick={() => grupoFamiliarToggle.onToggle()}
                >
                  Grupo familiar
                </span>
              </div>
            </div>

            <div className="grupo-enlaces-pshb">
              <div className="separador-titulo titulo-enlace-secundario-pshb">
                <img src={Perfil} className="icono-titulo" />
                <span>PERFIL</span>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/perfil"}
                  activeclassname="active"
                >
                  Datos personales
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/cambiar-contrasena"}
                  activeclassname="active"
                >
                  Cambiar contraseña
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/cerrar-sesion"}
                  activeclassname="active"
                >
                  Cerrar sesión
                </NavLink>
              </div>
            </div>

            <div className="separador-titulo" />

            <div className="grupo-enlaces-pshb">
              <img
                src={Logo}
                alt="logo"
                className=""
                style={{
                  width: "100px",
                  height: "auto",
                  marginTop: "20px",
                  marginBottom: "10px",
                  marginLeft: "5px",
                }}
              />
              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/turnos"}
                  activeclassname="active"
                >
                  Turnos
                </NavLink>
              </div>

              <div className="d-flex justify-content-justify">
                <NavLink
                  className="navigate-button enlace-drawer-pshb"
                  to={"/resultados"}
                  activeclassname="active"
                >
                  Resultados
                </NavLink>
              </div>
            </div>

            {/* <div class="separador" style={{width:"90%"}}></div> */}

            {/* <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/perfil"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Perfil</NavLink>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/grupo-familiar"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Grupo familiar</NavLink>
                            </div>


                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/credencial-digital"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Credencial digital</NavLink>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/reintegro-estado"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Consultar reintegros</NavLink>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/reintegro-solicitud"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Solicitar reintegro</NavLink>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/facturas"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Facturación</NavLink>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/cambiar-contrasena"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Cambiar contraseña</NavLink>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <NavLink className="navigate-button" to={"/ayuda"} activeclassname="active" style={{width: "80%", textAlign: "center"}}>Ayuda</NavLink>
                            </div> */}
          </div>

          {/* <div className='d-flex flex-column justify-content-center' style={{ paddingTop: "0px", paddingBottom: "0px" }}>                        
                        <div class="separador" style={{ width: "90%" }}></div>
                        <div className='d-flex justify-content-center' style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                            <img src={Logo} alt="logo" className="" height="50" />
                        </div>
                        <div className='d-flex justify-content-center' style={{ paddingTop: "20px" }}>                            
                        </div>
                        <div className='d-flex justify-content-center'>
                            <NavLink className="navigate-button" to={"/turnos"} activeclassname="active" style={{ width: "80%", textAlign: "center" }}>Turnos</NavLink>
                        </div>


                        <div className='d-flex justify-content-center'>
                            <NavLink className="navigate-button" to={"/resultados"} activeclassname="active" style={{ width: "80%", textAlign: "center" }}>Resultados</NavLink>
                        </div>
                    </div> */}

          {/* <div className='d-flex flex-column justify-content-center' style={{ paddingTop: "0px", paddingBottom: "0px" }}>                        
                        <div class="separador" style={{ width: "90%" }}></div>
                        <div className='d-flex justify-content-center'>
                            <NavLink className="navigate-button" to={"/cerrar-sesion"} activeclassname="active" style={{ width: "80%", textAlign: "center" }}>Cerrar sesión</NavLink>
                        </div>
                    </div> */}
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <InformacionCredencial
        key={Math.random()}
        visible={credencialToggle.toggle}
        onToggle={credencialToggle.onToggle}
      />
      <InformacionFactura
        key={Math.random()}
        visible={facturaToggle.toggle}
        onToggle={facturaToggle.onToggle}
      />
      <InformacionGrupoFamiliar
        key={Math.random()}
        visible={grupoFamiliarToggle.toggle}
        onToggle={grupoFamiliarToggle.onToggle}
      />

      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <i
            class="bi bi-list"
            onClick={toggleDrawer(anchor, true)}
            style={{ fontSize: "30px", color: "#0062C1" }}
          ></i>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default DrawerPSHB;
