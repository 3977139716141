import React from "react";
import errorIcon from "../../../assets/img/error-icon.png";
import { useNavigate } from "react-router-dom";
import "./no-autorizado.scss";

const NoAutorizado = (props) => {
  const navigate = useNavigate();

  const volver = () => {
    navigate("/");
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: props.loggedIn ? "50vh" : "50vh" }}
    >
      <div className="">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img className="logoTilde" src={errorIcon}></img>
          <div className="br"></div>
          <div className="br"></div>
          <h3 className="titulo-ayuda-ps">
            <center>
              Estimado socio,<br/> la información solicitada<br/> solo se encuentra disponible para el titular del plan.
            </center>
          </h3>          
          <div className="br"></div>
          <button
            onClick={volver}
            className="boton-confirmar boton-paga"
            type="submit"
            value="Continuar"
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoAutorizado;
