import "./historial-debito.scss";
import HistorialDebitoComponent from "../../../components/pshb/historial-debito";
import UserSidebar from "../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const HistorialDebito = (props) => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/debito-automatico", name: "En tarjeta de crédito" },
      { route: "/debito-cuenta", name: "En cuenta bancaria" },
      { route: "/historial-debito", name: "Historial" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const location = useLocation();

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
    window.scrollTo({top:0, left:0, beahivor: 'smooth'})
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <div className="bodypages"></div>
      <HistorialDebitoComponent
        visible={location.state?.visible ? location.state?.visible : false}
        ref={ref}
      />
    </div>
  );
};

export default HistorialDebito;