import { Col, Row } from "react-bootstrap";
import "./automatic-debit.scss";
import ResultsComponent from "../../../components/hb/results";
import AutomaticDebitComponent from "../../../components/pshb/automatic-debit";
import UserSidebar from "../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const AutomaticDebit = (props) => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/debito-automatico", name: "En tarjeta de crédito" },
      { route: "/debito-cuenta", name: "En cuenta bancaria" },
      { route: "/historial-debito", name: "Historial" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const location = useLocation();

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <div className="bodypages"></div>
      <AutomaticDebitComponent
        visible={location.state?.visible ? location.state?.visible : false}
        ref={ref}
      />
    </div>
  );
};

export default AutomaticDebit;
