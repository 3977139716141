import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import AyudaPSComponent from "../../../components/pshb/ayuda";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import "./ayuda-ps.scss";

const AyudaPS = () => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/ayuda", name: "Ayuda" },
      // { route: "/grupo-familiar", name: "Grupo familiar" },
      // { route: "/credencial-digital", name: "Credencial digital" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <AyudaPSComponent ref={ref}></AyudaPSComponent>
    </div>
  );
};

export default AyudaPS;
