import "./index.scss";
import {
  faPaperclip,
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

const FileInput = ({ onChange, error = false, clearFile = false }) => {
  const fileTypes = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];
  const [fileSelected, setFile] = useState({ label: "", error: false });
  const inputRef = useRef(null);

  useEffect(() => {
    setFile({ label: "", error: false });
    onChange(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [clearFile]);

  const seleccionarArchivo = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setFile({
        label: "",
        error: true,
        errorMessage: "No se ha seleccionado ningún archivo.",
      });
      onChange(null);
      return;
    }

    if (fileTypes.indexOf(file.type) === -1) {
      setFile({
        label: "Archivo inválido",
        error: true,
        errorMessage: "Solo se permiten imágenes JPG, JPEG, PNG y PDF",
      });
      onChange(null);
      return;
    }

    if (file.size > 5048576) {
      setFile({
        label: "Archivo pesado",
        error: true,
        errorMessage: "Por favor verifica que tu archivo pese menos de 5 MB",
      });
      onChange(null);
      return;
    }

    setFile({ label: file.name, error: false, file: file });
    onChange(file);
  };

  return (
    <div>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        accept=".jpg,.jpeg,.png,.pdf"
        onChange={handleFileChange}
      />
      <div
        className="custom-load-icon"
        style={{ height: "30px", minHeight: "30px" }}
        onClick={seleccionarArchivo}
      >
        <FontAwesomeIcon icon={faPaperclip} />
        <span>Adjuntar</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            className={
              (fileSelected.error ? "error-file " : "") +
              "round-label w-100 shadow-blue"
            }
            style={{
              maxWidth: "300px",
              display: "flex",
              alignItems: "center",
              minHeight: "30px",
              height: "30px",
            }}
          >
            {fileSelected.label}
            {error ? "Archivo requerido" : ""}
          </span>
          {fileSelected.label ? (
            fileSelected.error ? (
              <FontAwesomeIcon
                style={{ marginLeft: "3px", height: "30px", color: "red" }}
                icon={faCircleXmark}
              />
            ) : (
              <FontAwesomeIcon
                style={{ marginLeft: "3px", height: "30px", color: "green" }}
                icon={faCheckCircle}
              />
            )
          ) : (
            <></>
          )}
        </div>
        {fileSelected.error ? (
          <span
            className={fileSelected.error ? "error-file" : ""}
            style={{ marginLeft: "12px", fontSize: "10px" }}
          >
            {fileSelected.errorMessage}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FileInput;
