import React, {useContext} from 'react';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import LoginHB from '../../../pages/hb/login';
import RegisterHB from '../../../pages/hb/register';
import LoginPSHB from '../../../pages/pshb/login';
import RegisterPSHB from '../../../pages/pshb/register';

const CommonRegister = (props) => {

    const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser} = useContext(UserGlobalContextMemorySpace);

    const handleLoginPSHB = () =>{
        setUserPSHB(true);
        setUser({user: "PSHB User"})
    }
    const handleLoginHB = () =>{
        setUserPSHB(false);
        setUser({user: "HB User"})
    }

    const changeStack = () => {
        setUserPSHB(!userPSHB)
    }

  return (
    <>
        <RegisterPSHB></RegisterPSHB>
    </>
  );
}

export default CommonRegister;
