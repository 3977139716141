import * as Yup from "yup";

export const validateModelPS = Yup.object().shape({
  cbu: Yup.string()
    .matches(/^\d{22}$/, {
      message: "22 dígitos, sin espacios ni guiones",
      excludeEmptyString: true,
    })
    .required("Campo obligatorio"),
  picked: Yup.string().required("Seleccione un campo"),
  recaptcha: Yup.string().required("Por favor, completa el ReCAPTCHA."),
});
