import { useCallback, useState } from 'react';


export const useToggle = (initialState = false) => {

    const [toggle, setToggle] = useState(initialState);

    const onToggle = useCallback((valor) => { setToggle(valor ? valor : !toggle) }, [toggle]);

    return { toggle, onToggle }
}