import { Col, Row } from 'react-bootstrap'
import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import UserSidebar from '../../../components/common/user-sidebar';
import './perfilps.scss'
import { DatosCoberturaComponent } from '../../../components/common/perfil/dato-cobertura'
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
//import {Outlet} from "react-router-dom";
//import { DatosCoberturaComponent } from '../../../components/common/perfil/dato-cobertura';

const PerfilPS = () => {
  const [routes, setRoutes] = useState({routes: [{route: "/perfil", name:"Datos personales"}, ,{route: "/grupo-familiar", name:"Grupo familiar"},{route: "/credencial-digital", name:"Credencial digital"}]})
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const {userPSHB} = useContext(UserGlobalContextMemorySpace);

  useLayoutEffect(() => {
      setHeight(ref.current.clientHeight);
    }, []);

  
  return (
    <div className='d-flex help-page' ref={ref}>
       <UserSidebar routes={routes} height={height} ></UserSidebar>
        <div className='d-flex flex-column w-100 justify-content-between'>
          <div style={{width:"100%",paddingLeft:"6.31%",paddingRight:"6.31%"}}>
            <DatosCoberturaComponent> </DatosCoberturaComponent>
          </div>
          <div className={userPSHB? 'footer-pshb-large' : 'footer-hb'}></div>
        </div>
    </div>      
      
    
     
  )
}

export default PerfilPS