import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import classNames from "classnames";

export const TarjetaHistorialComponent = ({
  titulo,
  activo = false,
  children,
  data,
}) => {
  const { userPSHB } = useContext(UserGlobalContextMemorySpace);

  return (
    <div
      className={classNames("tarjeta", {
        ["tarjeta--activa"]: activo,
        ["tarjeta--pshb"]: userPSHB,
      })}
    >
      <div
        className="d-flex justify-content-start align-items-center"
        style={{ marginLeft: "10px" }}
      >
        <h6 style={{ color: "rgb(3, 50, 97)" }}>
          N° ID trámite: {data.nroComprobante}
        </h6>
        <h6
          style={{ fontFamily: "Objectivity-Regular" }}
          className={activo ? "tituloActivo" : "tituloInactivo"}
        >
          {titulo}
        </h6>
      </div>
      {children}
    </div>
  );
};
