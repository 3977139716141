import React, { useState, useContext, useEffect } from "react";
import EditarIcon from "../../../assets/img/editar.png";
import EliminarIcon from "../../../assets/img/eliminar.png";
import "./family-group.scss";
import { useToggle } from "../../../hooks/useToggle";
import { InformacionComponent } from "./informacion";
import InformationIcon from "../../../assets/img/information.png";
import { Button, Col, Row } from "react-bootstrap";
import { CUSTOM_SELECT_COLOR_WHITE } from "../../common/customSelect/constants";
import CustomSelect from "../../common/customSelect";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditarFamiliarComponent } from "./editar-familiar";
import { EliminarFamiliarComponent } from "./eliminar-familiar";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import axios from "axios";
import FamilyGroupPSHB from "../../../containers/familiar-group-pshb";
import { MdError } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { BsFillPencilFill } from "react-icons/bs";
import CommonLoader from "../../common/loader";
import { SwalMessage } from "../../../utils/sweet-alert/sweet-alert";
import { myAxios } from "../../../utils/fetch";
import {
  nameLastnameValidator,
  nameLastnameValidatorSpace,
  passportValidation,
} from "../../../utils/hooks/regex";
import { useLocation, useSearchParams } from "react-router-dom";
import { TarjetaGrupoFamiliarComponent } from "../../common/familiar-group/tarjeta-grupo-familiar";
import { IntegranteGrupoComponent } from "../../common/familiar-group/Integrante-grupo-component";
import ReactGA from "react-ga4";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("El nombre es requerido"),
  apellido: Yup.string().required("El apellido es requerido"),
});

const FamilyGroupComponent = (props) => {
  const infoToggle = useToggle();
  const editarFamiliarToggle = useToggle();
  const eliminarFamiliarToggle = useToggle();

  const {
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    setUser,
    familiarGroupComponent,
    setFamiliarGroupComponent,
    familiarGroup,
    setFamiliarGroup,
  } = useContext(UserGlobalContextMemorySpace);
  const location = useLocation();

  const [isAddFamily, setAddFamily] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(true);
  const [familiarGroupError, setFamiliarGroupError] = useState(false);
  const [archivoDU, setArchivoDU] = React.useState();
  const [archivoPartida, setArchivoPartida] = React.useState();
  const [uploadFile, setUploadFile] = React.useState();
  const [memberSelected, setMemberSelected] = useState("");

  const [searchParams, setSearchParams] = useSearchParams({});
  const openDialogParams = searchParams.get("open");

  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState({
    data: "",
    index: "",
  });

  /* create familiar Group*/
  const date = new Date();
  // const [maxDate, setMaxDate] = useState(`${date.getFullYear()}-${((date.getMonth()+1).toString() == 1 ? date.getMonth()+1 : `0${date.getMonth()+1}`)}-${date.getUTCDate()}`);
  const [maxDate, setMaxDate] = useState(
    `${date.getFullYear()}-${
      (date.getMonth() + 1).toString().length == 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }-${
      date.getUTCDate().toString().length == 1
        ? `0${date.getUTCDate()}`
        : `${date.getMonth()}`
    }`
  );

  const [minDate, setMinDate] = useState(
    `${date.getFullYear() - 18}-${
      (date.getMonth() + 1).toString().length == 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }-${
      date.getUTCDate().toString().length == 1
        ? `0${date.getUTCDate()}`
        : `${date.getMonth()}`
    }`
  );

  const [fechaNac, setFechaNac] = useState(memberSelected.birthday);
  const [dateType, setDateType] = useState("date");
  const [fechaNacPlaceholder, setFechaNacPlaceholder] = useState("");
  const [nombre, setNombre] = useState(memberSelected.firstName);
  const [apellido, setApellido] = useState(memberSelected.lastName);
  const [parentesco, setParentesco] = useState(memberSelected.relationshipid);
  const [genero, setGenero] = useState(
    memberSelected.genderCode === "H" ? 2 : 1
  );
  const [docType, setDocType] = useState(memberSelected.dniType);
  const [documento, setDocumento] = useState(memberSelected.dni);
  const [credencial, setCredencial] = useState(memberSelected.credential);
  const [errorMsg, setErrorMsg] = useState("");
  const [dateErrorMsg, setDateErrorMsg] = useState("");
  const [documentoErrorMsg, setDocumentoErrorMsg] = useState("");
  const [isSend, setIsSend] = useState(false);

  const fileTypes = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];
  const [fileSelectedPartida, setFilePartida] = React.useState({
    label: "",
    error: false,
  });
  const [fileSelected, setFile] = React.useState({ label: "", error: false });
  const [integranteVisible, setIntegranteVisible] = useState("");

  const cleanCreateFamilyGroupForm = () => {
    setNombre("");
    setApellido("");
    setGenero("");
    setParentesco("");
    setFechaNac("");
    setCredencial("");
    setArchivoDU("");
    setArchivoPartida("");
    setDocType("");
    setDocumento("");
    setFilePartida("");
    setFile("");
  };

  const llamar = (index) => {
    setIntegranteVisible((previus) => (previus === index ? "" : index));
  };

  const editarIntegrante = (data) => {
    editarFamiliarToggle.onToggle();
    if (data.statusid != "2") {
      setMemberSelected(data);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const seleccionarArchivo = (fileType) => {
    setErrorMsg("");
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.jpeg,.png,.pdf";
    input.onchange = async (e) => {
      var file = e.target.files[0];

      if (fileTypes.indexOf(file.type) === -1) {
        fileType == "partida"
          ? setFilePartida({
              label: "",
              error: true,
              errorMessage: "Solo se permiten imagenes JPG,JPEG,PNG y PDF",
            })
          : setFile({
              label: "",
              error: true,
              errorMessage: "Solo se permiten imagenes JPG,JPEG,PNG y PDF",
            });
        setErrorMsg("Solo se permiten imagenes JPG,JPEG,PNG y PDF.");
        return;
      }

      if (file.size > 3000000) {
        fileType == "partida"
          ? setFilePartida({
              label: "",
              error: true,
              errorMessage:
                "Por favor verifica que tu archivo pese menos de 3 MB",
            })
          : setFile({
              label: "",
              error: true,
              errorMessage:
                "Por favor verifica que tu archivo pese menos de 3 MB",
            });
        setErrorMsg("Por favor verifica que tu archivo pese menos de 3 MB");
        return;
      }

      // console.log("FILE",file);

      try {
        const result = await toBase64(file);
        let aux = [];
        aux = result.split(",");
        fileType == "partida"
          ? setFilePartida({
              label: file.name,
              error: false,
              file: file,
              base64: aux[1],
            })
          : setFile({
              label: file.name,
              error: false,
              file: file,
              base64: aux[1],
            });
        fileType == "partida"
          ? setArchivoPartida(aux[1])
          : setArchivoDU(aux[1]);
      } catch (error) {
        fileType == "partida"
          ? setFilePartida({
              label: "Error",
              error: true,
              errorMessage:
                "Detectamos un error, por favor intenta nuevamente en unos minutos.",
            })
          : setFile({
              label: "Error",
              error: true,
              errorMessage:
                "Detectamos un error, por favor intenta nuevamente en unos minutos.",
            });
        setErrorMsg(
          "Detectamos un error, por favor intenta nuevamente en unos minutos."
        );
        return;
      }
    };

    input.click();
  };

  var documentList = [{ id: 1, name: "DNI" }];

  var relationshipList = [
    { id: 1, name: "Hijo/a" },
    { id: 1, name: "Papá" },
    { id: 1, name: "Mamá" },
  ];

  const handleCreateGF = async (event) => {
    event.preventDefault();
    setIsSend(true);

    if (
      !nombre ||
      !apellido ||
      !parentesco ||
      !genero ||
      !docType ||
      !documento ||
      !credencial ||
      !fechaNac ||
      !archivoDU ||
      !archivoPartida ||
      dateErrorMsg ||
      documentoErrorMsg
    ) {
      setErrorMsg(
        "Por favor ingresa todos los datos solicitados para avanzar."
      );
    } else {
      setIsSend(false);
      setLoader(true);
      setErrorMsg("");

      // let fakeCredential = new Date().getTime().toString();
      let formatDate = `${fechaNac.slice(-2)}-${fechaNac.slice(
        5,
        7
      )}-${fechaNac.slice(0, 4)}`;

      let res = await myAxios({
        url:
          process.env.REACT_APP_GATEWAY_QA +
          "/customers/api/v1/familiargroup/createfamilymember",
        method: "POST",
        headers: { Authorization: "Bearer " + user.user.data.appToken },
        data: {
          idCustomer: user.user.data.Customers.idCustomer,
          familyMember: {
            id: "0",
            familygroupId: "0",
            relationshipid: parentesco,
            firstName: nombre,
            lastName: apellido,
            birthday: formatDate,
            dni: documento,
            dniType: docType,
            genderid: genero,
            fileDU: archivoDU,
            fileDocLegal: "",
            filePartida: archivoPartida,
            credential: credencial,
            status: "Pendiente de aprobación",
            statusCode: "PDA",
          },
        },
      })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (
            error.response.data.Message.Generic ==
            "El miembro de grupo familiar que intenta crear ya existe."
          ) {
            setErrorMsg(
              "El miembro de grupo familiar que intenta crear ya existe."
            );
          } else {
            setErrorMsg(
              "Ocurrió un error al enviar los datos, por favor intente nuevamente en breves minutos."
            );
          }
          setLoader(false);
        });

      // console.log("GF", res)

      if (res.data.responseCode == 200) {
        setFamiliarGroupComponent(res.data.data.FamilyGroup.familyMembers);
        SwalMessage(
          "success",
          '<h5 style="color: #224d7c"></h5>',
          "Miembro del grupo familiar cargado con éxito.",
          "Continuar"
        );
      } else if (
        res.data.responseCode == 500 &&
        res.data.Message.Generic ==
          "El miembro de grupo familiar que intenta crear ya existe."
      ) {
        setErrorMsg(
          "El miembro de grupo familiar que intenta crear ya existe."
        );
      } else {
        setErrorMsg(
          "El miembro de grupo familiar que intenta crear ya existe."
        );
      }

      setLoader(false);
      setAddFamily(false);
    }
  };

  const handlerDate = (value) => {
    if (value.length <= 10) {
      setFechaNac(value);
    }
  };

  const handlerDateBlur = (value) => {
    const date1 = new Date(value);
    const date2 = new Date(minDate);
    const date3 = new Date(maxDate);

    if (date1.getTime() < date2.getTime()) {
      setDateErrorMsg(
        "Solo puede agregarse como miembros de un grupo familiar a menores de 18 años"
      );
    } else if (date1.getTime() > date3.getTime()) {
      setDateErrorMsg("Fecha inválida");
    } else {
      setDateErrorMsg("");
    }
  };

  const handlerDocumentoBlur = (value) => {
    if (docType == "0") {
      if (documento < 1000000) {
        setDocumentoErrorMsg("Nº de documento inválido");
      } else {
        setDocumentoErrorMsg("");
      }
    }
  };

  const getFamiliarGroup = async () => {
    let body = {
      dnitype: user.user.data.Customers.dniType,
      dni: user.user.data.Customers.dni,
    };

    let res = await axios({
      url:
        process.env.REACT_APP_STRAPI_QA +
        process.env.REACT_APP_STRAPI_QA_ENDPOINT_FAMILIARGROUP,
      method: "POST",
      data: body,
    })
      .then((response) => {
        if (response.data.FamiliarGroup) {
          return response.data.FamiliarGroup;
        }
      })
      .catch((error) => {
        setFamiliarGroupError(true);
        setLoader(false);
      });

    if (Array.isArray(res)) {
      await setFamiliarGroupComponent(res);
      setLoader(false);
    } else {
      setFamiliarGroupError(true);
      setLoader(false);
    }
  };

  const showCreateFG = () => {
    cleanCreateFamilyGroupForm();
    setAddFamily(true);
  };

  const deleteFamilyGroupMember = async (member, index) => {
    // console.log(familiarGroup[index])

    member = data.data;
    // console.log(familiarGroup[index])

    setVisible(false);
    setLoader(true);

    let res = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        process.env.REACT_APP_GATEWAY_ENDPOINT_UNLINKFAMILYMEMBER,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //   "USERTOKEN":user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
        familyMember: member,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        setLoader(false);
        SwalMessage(
          "info",
          '<h5 style="color: #224d7c"></h5>',
          "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos",
          "Continuar"
        );
      });

    if (res.data.responseCode == 200) {
      setFamiliarGroupComponent(res.data.data.FamilyGroup.familyMembers);
    } else {
      SwalMessage(
        "info",
        '<h5 style="color: #224d7c"></h5>',
        "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos",
        "Continuar"
      );
    }

    setLoader(false);
  };

  useEffect(() => {
    //Mount:

    if (!familiarGroupComponent) {
      getFamiliarGroup();
    } else {
      setLoader(false);
    }

    return () => {
      //Unmount
    };
  }, []);

  useEffect(() => {
    if (openDialogParams == "1") {
      infoToggle.onToggle();
    }
  }, []);

  const handleOnChangeDocType = (value) => {
    setDocType(value);
    setDocumento("");
  };

  const informacion = () => {
    infoToggle.onToggle();

    ReactGA.event({
      category: "Acciones-GrupoFamiliar",
      action: "Click",
      label: "information",
    });
  };

  const eliminar = (data, index) => {
    //resetForm();
    setData({ data: data, index: index });
    setVisible(true);
  };

  return (
    <>
      {!isAddFamily ? (
        <section className=" " style={{ width: "100%" }}>
          {user.user.data.Customers.socialSecurity == "40080/000" ? (
            <>
              <FamilyGroupPSHB
                visible={
                  location.state?.visible ? location.state?.visible : false
                }
              ></FamilyGroupPSHB>
            </>
          ) : (
            <>
              <div className="p-4" style={{ minHeight: "70vh" }}>
                {/* TITULO */}
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <h2 className="section_hb_title title_mobile">
                      <b>Mi grupo familiar</b>
                    </h2>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-lg-12 header-grupo-familiar"
                    id="header-invoice"
                  >
                    <span className="section_hb_subtitle title_mobile">
                      Gestioná tu grupo familiar y el estado en que se encuentra
                      cada miembro.
                    </span>

                    <div className="agregar_mobile">
                      <div
                        className="btn btn-hb-primary-without-border shadow-blue agregar-familiar"
                        onClick={showCreateFG}
                      >
                        Agregar familiar
                      </div>
                      <img
                        id="icon-information"
                        onClick={() => informacion()}
                        src={InformationIcon}
                        alt="..."
                        className="information_mobile"
                      ></img>
                      <InformacionComponent
                        visible={
                          openDialogParams == "1" ? true : infoToggle.toggle
                        }
                        onToggle={infoToggle.onToggle}
                      />

                      <EditarFamiliarComponent
                        key={Date()}
                        visible={editarFamiliarToggle.toggle}
                        onToggle={editarFamiliarToggle.onToggle}
                        memberSelected={memberSelected}
                      />

                      <EliminarFamiliarComponent
                        visible={eliminarFamiliarToggle.toggle}
                        onToggle={eliminarFamiliarToggle.onToggle}
                      />
                    </div>
                  </div>
                </div>

                {/* SEPARADOR */}
                <div className="separador-table-red-result separador-table-red-result-mobile"></div>
                {/* TABLA */}
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table
                        className="table table-hb table-pshb-result"
                        id="tableResults"
                      >
                        {loader ? (
                          <>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "295px" }}
                            >
                              <div
                                style={{
                                  width: "1rem",
                                  height: "1rem",
                                  color: "#5d84aa",
                                }}
                                className="spinner-grow text-muted"
                              ></div>
                              <div
                                style={{
                                  marginLeft: "5px",
                                  width: "1rem",
                                  height: "1rem",
                                  color: "#5d84aa",
                                }}
                                className="spinner-grow text-muted"
                              ></div>
                              <div
                                style={{
                                  marginLeft: "5px",
                                  width: "1rem",
                                  height: "1rem",
                                  color: "#5d84aa",
                                }}
                                className="spinner-grow text-muted"
                              ></div>
                            </div>
                          </>
                        ) : (
                          <>
                            {familiarGroupComponent &&
                            familiarGroupComponent.length > 0 ? (
                              <>
                                <thead className="contenedor-grupo-familiar-desktop">
                                  <tr>
                                    <th>Nombre y apellido</th>
                                    <th>Tipo</th>
                                    <th>N°</th>
                                    <th>Fecha de nacimiento</th>
                                    <th>Género</th>
                                    <th>Estado</th>
                                    <th>Observaciones</th>
                                    <th>Acciones</th>
                                  </tr>
                                </thead>
                                <tbody className="contenedor-grupo-familiar-desktop">
                                  {familiarGroupComponent.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{data.fullName}</td>
                                        <td>
                                          {data.dniType == 0
                                            ? "DNI"
                                            : data.dniType == 2
                                            ? "Pasaporte"
                                            : data.dniType}
                                        </td>
                                        <td>{data.dni}</td>
                                        <td>{data.birthday}</td>
                                        <td>{data.gender}</td>
                                        <td>{data.status}</td>
                                        <td>{data.observations}</td>
                                        <td>
                                          {data.statusid == "2" ? (
                                            <button
                                              disabled
                                              className="circle-button circle-button-disabled"
                                              onClick={
                                                editarFamiliarToggle.onToggle
                                              }
                                            >
                                              <BsFillPencilFill
                                                size="15px"
                                                style={{
                                                  color: "white",
                                                  marginBottom: "2px",
                                                }}
                                              />
                                            </button>
                                          ) : (
                                            <button
                                              className="circle-button circle-button-green"
                                              onClick={() => {
                                                setMemberSelected(data);
                                                editarFamiliarToggle.onToggle();
                                              }}
                                            >
                                              <BsFillPencilFill
                                                size="15px"
                                                style={{
                                                  color: "white",
                                                  marginBottom: "2px",
                                                }}
                                              />
                                            </button>
                                          )}
                                          {/* <img className="i-25" src={EditarIcon}
                                                                onClick={editarFamiliarToggle.onToggle} alt="..."></img> */}
                                          {/* <img className="i-25" src={EliminarIcon} alt="Desvincular miembro"
                                                                onClick={()=>eliminar(data)}></img> */}
                                          <button
                                            style={{ marginLeft: "5px" }}
                                            className="circle-button circle-button-red"
                                            onClick={() =>
                                              eliminar(data, index)
                                            }
                                            // onClick={() =>
                                            //   deleteFamilyGroupMember(
                                            //     data,
                                            //     index
                                            //   )
                                            // }
                                          >
                                            <BsTrash
                                              size="15px"
                                              style={{
                                                color: "white",
                                                marginBottom: "2px",
                                              }}
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>

                                {/* CONTENEDOR PARA MOBILE */}
                                <div id="contenedor-grupo-familiar-mobile">
                                  {familiarGroupComponent?.map(
                                    (integrante, index) => {
                                      return (
                                        <TarjetaGrupoFamiliarComponent
                                          key={index}
                                          titulo={integrante.fullName}
                                          activo={false}
                                          abierto={integranteVisible === index}
                                          puedeMinimizar={true}
                                          onVisibleTarjeta={(_) =>
                                            llamar(index)
                                          }
                                        >
                                          <IntegranteGrupoComponent
                                            data={integrante}
                                            activo={integranteVisible === index}
                                            editando={false}
                                            eliminar={() =>
                                              deleteFamilyGroupMember(
                                                integrante,
                                                index
                                              )
                                            }
                                            editarIntegrante={() =>
                                              editarIntegrante(integrante)
                                            }
                                          />
                                        </TarjetaGrupoFamiliarComponent>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <thead>
                                  <tr>
                                    <th>
                                      No se encontraron integrantes agregados al
                                      grupo familiar
                                      {familiarGroupError ? (
                                        <MdError
                                          size="30px"
                                          style={{ color: "#033261" }}
                                        />
                                      ) : null}
                                    </th>
                                  </tr>
                                </thead>
                              </>
                            )}
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                {/* SEPARADOR */}
                <div className="separador-table-red-result"></div>
              </div>
            </>
          )}
          {/* <div className={userPSHB? 'footer-pshb' : 'footer-hb' }></div> */}
        </section>
      ) : (
        <>
          <form
            className="form.gf form-gf-hb"
            onSubmit={handleCreateGF}
            style={{ minHeight: "70vh" }}
          >
            <Row id="">
              <Col xs={12} md={6} style={{ minWidth: "50%" }}>
                <div className="header-add-family">
                  <div id="header-add-family-title">
                    <span className="section_hb_title">Agregar familiar</span>
                    <span className="section_hb_subtitle add-gf">
                      Completar los datos personales del paciente que desea
                      agregar a su grupo familiar.
                    </span>
                  </div>
                  {/* <div id="header-add-family-separator"></div> */}
                </div>
                {loader ? (
                  <></>
                ) : (
                  <>
                    <div className="">
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !nombre ? "hb-label-error" : "hb-label"
                            }`}
                          >
                            Nombre
                          </span>
                        </div>
                        <div className="col-md-8">
                          <input
                            name="nombre"
                            maxLength={50}
                            onChange={(e) =>
                              setNombre(
                                !nombre
                                  ? nameLastnameValidator(e.target.value)
                                  : nameLastnameValidatorSpace(e.target.value)
                              )
                            }
                            value={nombre}
                            type="text"
                            className={`${
                              isSend && !nombre
                                ? "input-family-error"
                                : "input-family"
                            }  w-100 shadow-blue`}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !apellido
                                ? "hb-label-error"
                                : "hb-label"
                            }`}
                          >
                            Apellido
                          </span>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            name="apellido"
                            className={`${
                              isSend && !apellido
                                ? "input-family-error"
                                : "input-family"
                            }  w-100 shadow-blue`}
                            maxLength={50}
                            onChange={(e) =>
                              setApellido(
                                !apellido
                                  ? nameLastnameValidator(e.target.value)
                                  : nameLastnameValidatorSpace(e.target.value)
                              )
                            }
                            value={apellido}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !parentesco
                                ? "hb-label-error"
                                : "hb-label"
                            }`}
                          >
                            Parentesco
                          </span>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={parentesco}
                            className={`${
                              isSend && !parentesco
                                ? "input-family-error"
                                : "input-family"
                            } shadow-blue`}
                            style={{ width: "100%" }}
                            onChange={(e) => setParentesco(e.target.value)}
                          >
                            <option
                              className="option-default-size"
                              value=""
                              hidden
                            >
                              Seleccionar
                            </option>
                            <option value="1">Madre</option>
                            <option value="2">Padre</option>
                            <option value="3">Tutor a cargo</option>
                            <option value="9">Hijo menor permanente</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !genero ? "hb-label-error" : "hb-label"
                            }`}
                          >
                            Género
                          </span>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={genero}
                            className={`${
                              isSend && !genero
                                ? "input-family-error"
                                : "input-family"
                            } shadow-blue`}
                            style={{ width: "100%" }}
                            onChange={(e) => setGenero(e.target.value)}
                          >
                            <option
                              className="option-default-size"
                              value=""
                              hidden
                            >
                              Seleccionar
                            </option>
                            <option value="1">Femenino</option>
                            <option value="2">Masculino</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !fechaNac
                                ? "hb-label-error"
                                : "hb-label"
                            }`}
                          >
                            Fecha de nacimiento
                          </span>
                        </div>
                        <div className="col-md-8">
                          <input
                            type={dateType}
                            onFocus={() => setDateType("date")}
                            value={fechaNac}
                            min={minDate}
                            max={maxDate}
                            className={`${
                              isSend && !fechaNac
                                ? "input-family-error"
                                : "input-family"
                            } ${fechaNacPlaceholder} shadow-blue`}
                            onChange={(e) => handlerDate(e.target.value)}
                            onBlur={(e) => handlerDateBlur(e.target.value)}
                            style={{ width: "100%" }}
                          ></input>
                          <p
                            className="hb-label error-message"
                            style={{
                              color: "red",
                              marginTop: "5px",
                              marginLeft: "17px",
                              fontSize: "14px", // Agrega esta línea
                            }}
                          >
                            {dateErrorMsg}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Col>
              <Col xs={12} md={6} style={{ minWidth: "50%" }}>
                <div className="header-add-family">
                  <div id="header-add-family-title">
                    <span className="section_hb_subtitle mt-3">
                      Los archivos deben pesar menos de 3MB y deben visualizarse
                      correctamente.
                    </span>
                  </div>
                  {/* <div id="header-add-family-separator"></div> */}
                </div>
                {loader ? (
                  <></>
                ) : (
                  <>
                    <div className="">
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !docType ? "hb-label-error" : "hb-label"
                            }`}
                          >
                            Tipo de documento
                          </span>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={docType}
                            className={`${
                              isSend && !docType
                                ? "input-family-error"
                                : "input-family"
                            } shadow-blue`}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleOnChangeDocType(e.target.value)
                            }
                          >
                            <option
                              className="option-default-size"
                              value=""
                              hidden
                            >
                              Seleccionar
                            </option>
                            <option value="0">DNI</option>
                            <option value="2">Pasaporte</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !documento
                                ? "hb-label-error"
                                : "hb-label"
                            }`}
                          >
                            N° Documento
                          </span>
                        </div>
                        <div className="col-md-8">
                          <input
                            maxLength={docType == "2" ? 20 : 8}
                            type="text"
                            className={`${
                              isSend && !documento
                                ? "input-family-error"
                                : "input-family"
                            }  w-100 shadow-blue`}
                            onChange={(e) =>
                              docType == "2"
                                ? setDocumento(
                                    passportValidation(e.target.value)
                                  )
                                : setDocumento(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  )
                            }
                            onBlur={(e) => handlerDocumentoBlur(e.target.value)}
                            value={documento}
                          />
                          {documentoErrorMsg ? (
                            <p
                              className="hb-label error-message"
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginLeft: "17px",
                                fontSize: "14px", // Agrega esta línea
                              }}
                            >
                              {documentoErrorMsg}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && !credencial
                                ? "hb-label-error"
                                : "hb-label"
                            }`}
                          >
                            N° Credencial
                          </span>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            className={`${
                              isSend && !credencial
                                ? "input-family-error"
                                : "input-family"
                            } w-100 shadow-blue`}
                            maxLength={20}
                            onChange={(e) =>
                              setCredencial(
                                // e.target.value.replace(/[^0-9]/g, "")
                                e.target.value
                              )
                            }
                            value={credencial}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend && (!archivoDU || fileSelected.error)
                                ? "hb-label-error"
                                : "hb-label"
                            }
                              ${fileSelected.error && "hb-label-error"}`}
                          >
                            Documento
                          </span>
                        </div>
                        <div className="col-md-8 ">
                          <div>
                            <div
                              className="custom-load-icon"
                              onClick={() => seleccionarArchivo("")}
                            >
                              <FontAwesomeIcon icon={faPaperclip} />
                              <span>Adjuntar</span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "100px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className={
                                    (fileSelected.error ? "error-file " : "") +
                                    "round-label w-100 shadow-blue"
                                  }
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "15px",
                                    paddingLeft: "40px",
                                  }}
                                >
                                  {fileSelected.file && !fileSelected.error
                                    ? fileSelected.label //.slice(0, 5) + "..."
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <span
                            className={`${
                              isSend &&
                              (!archivoPartida || fileSelectedPartida.error)
                                ? "hb-label-error"
                                : "hb-label"
                            }
                              ${fileSelectedPartida.error && "hb-label-error"}`}
                          >
                            Partida de nacimiento
                          </span>
                        </div>
                        <div className="col-md-8">
                          <div>
                            <div
                              className="custom-load-icon"
                              onClick={() => seleccionarArchivo("partida")}
                            >
                              <FontAwesomeIcon icon={faPaperclip} />
                              <span>Adjuntar</span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "100px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className={
                                    (fileSelectedPartida.error
                                      ? "error-file "
                                      : "") + "round-label w-100 shadow-blue"
                                  }
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "15px",
                                    paddingLeft: "40px",
                                  }}
                                >
                                  {fileSelectedPartida.file &&
                                  !fileSelectedPartida.error
                                    ? fileSelectedPartida.label //.slice(0, 5) +"..."
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            {loader ? (
              <>
                <CommonLoader></CommonLoader>
              </>
            ) : (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{
                        color: "red",
                        fontWeight: "500",
                        marginTop: "30px",
                      }}
                    >
                      {errorMsg}
                    </p>
                  </div>
                </Row>
                <Row>
                  <div className="row mt-5">
                    <div className="col-md-12 family-add-buttons">
                      <Button
                        disabled={disabled}
                        className="btn btn-hb-secondary-without-border"
                        onClick={() => setAddFamily(false)}
                      >
                        Cancelar
                      </Button>
                      {/* <Button disabled={disabled} className="btn btn btn-hb-primary-without-border shadow-blue" onClick={handleCreateGF}>Aceptar</Button> */}
                      <input
                        className="btn btn btn-hb-primary-without-border shadow-blue"
                        type="submit"
                        value="Aceptar"
                      />
                    </div>
                  </div>
                </Row>
              </>
            )}
          </form>
          {/* <div className={userPSHB? 'footer-pshb' : 'footer-hb' }></div> */}
        </>
      )}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={visible}
        centered
        // key={data}
      >
        <Modal.Body
          style={{
            paddingBottom: "0",
            marginBottom: "0",
          }}
        >
          <div className="modalBody">
            <div className="row mt-2">
              <div className="col-lg-12 ">
                <h2 className="section_pshb_title">
                  <center>
                    <b>
                      ¿Estás seguro que querés eliminar a esta persona de tu
                      Grupo Familiar?
                    </b>
                  </center>
                </h2>
              </div>
            </div>
            <div className="separador-table-red-result"></div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <div
            style={{
              paddingTop: "0",
            }}
            className="mx-auto"
          >
            <button
              style={{
                marginTop: "0",
              }}
              className="boton-volver"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancelar
            </button>
            <button
              style={{
                marginTop: "0",
              }}
              className="boton-confirmar"
              onClick={async () => await deleteFamilyGroupMember()}
            >
              Aceptar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FamilyGroupComponent;
