import React, { useContext } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import classNames from "classnames";

export const TarjetaGrupoFamiliarComponent = ({
  titulo,
  activo = false,
  puedeMinimizar = false,
  children,
  onVisibleTarjeta,
  abierto = false,
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  function IconoCollapse() {
    if (abierto)
      return (
        <FiChevronUp
          role="button"
          className={`ms-auto  ${activo ? "tituloActivo" : "tituloInactivo"}`}
          size={30}
        />
      );
    else
      return (
        <FiChevronDown
          role="button"
          className={`ms-auto  ${activo ? "tituloActivo" : "tituloInactivo"}`}
          size={30}
        />
      );
  }

  return (
    <div
      className={classNames("tarjeta", {
        ["tarjeta--activa"]: false,
        ["tarjeta--pshb"]: userPSHB,
      })}
    >
      <div
        className="d-flex justify-content-start align-items-center"
        onClick={onVisibleTarjeta}
      >
        <h3 className={activo ? "tituloActivo" : "tituloInactivo"}>{titulo}</h3>
        <IconoCollapse />
      </div>

      {children}
    </div>
  );
};
