import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./terminos-condiciones.scss";
import { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext.jsx";
import DOMPurify from "dompurify";

export const TerminosCondicionesComponent = ({ visible = false, onToggle }) => {
  const { footerConfiguration } =
    useContext(UserGlobalContextMemorySpace);
    const sanitizer = DOMPurify.sanitize;

  const ContenidoTerminos=()=>{
    return ( 
    <>
    <p>
      El acceso y uso de este sitio web supone la aceptación plena y sin
      reservas de la totalidad de las condiciones generales de uso que se
      enumeran a continuación, siendo aplicables, igualmente, a la
      información, aplicaciones y servicios a los que se puede acceder a
      través del mismo. El Hospital Británico de Buenos Aires (en adelante
      “HOSPITAL BRITANICO”) se reserva la facultad de modificar total o
      parcialmente estos términos y condiciones.
      <br />
      <br />
      Te recomendamos leer cuidadosamente los términos y condiciones aquí
      establecidos antes de utilizar ese sitio web. En el supuesto que no
      los aceptes, no deberías utilizarlo.
    </p>
    <h5>Usuarios</h5>
    <p>
      El HOSPITAL BRITÁNICO te otorgará un usuario para el acceso al sitio
      web mediante el cual podrás ver los resultados de tus estudios y
      análisis clínicos, como así también solicitar turnos para las
      distintas especialidades médicas. En caso de tener hijos menores de
      edad podrás cargar sus datos personales y acceder al sitio web a
      través de tu usuario para gestionar los trámites atinentes a ellos
      (en adelante, “Grupo Familiar”).
    </p>
    <h5>Responsabilidad de los usuarios</h5>
    <p>
      Aceptando estos términos y condiciones te comprometes expresamente a
      hacer uso del sitio web en forma correcta, sin contravenir la
      legislación argentina, la buena fe y el orden público, asumiendo
      cualquier responsabilidad en caso de no cumplir con dichas
      estipulaciones. No podés, ni podrás solicitar o permitir a terceros:
      (a) el uso del sitio web para acosar a persona alguna, causar daños
      o lesiones a personas o bienes, publicar material falso,
      difamatorio, acosador u obsceno, violar derechos de privacidad,
      promover la intolerancia, el racismo, el odio o el daño, enviar
      mensajes masivos no solicitados, correo “basura”, correo “no
      deseado” o mensajes encadenados, violar derecho de propiedad, o
      violar de cualquier modo las leyes o resoluciones aplicables, o (b)
      realizar o revelar pruebas de rendimiento o vulnerabilidad del sitio
      web sin la previa aprobación del HOSPITAL BRITÁNICO otorgada por
      escrito, o realizar o revelar pruebas de detección de redes,
      identificación de puertos y servicios, escaneo de vulnerabilidades o
      descifrado de contraseñas.
      <br />
      <br />
      El sitio web puede permitirte en algunos casos establecer un enlace,
      transferir tu información o acceder a otros sitios web, plataformas,
      contenidos, productos, servicios e información de terceros ajenos al
      HOSPITAL BRITANICO (“Servicios de Terceros”). El HOSPITAL BRITÁNICO
      no controla ni es responsable de los Servicios de Terceros, y vos
      serás el único responsable de cumplir con los términos de acceso y
      uso de los Servicios de Terceros. En caso de que vos transfieras o
      hagas transferir información o datos tuyos desde el sitio web hacia
      un Servicio de Terceros, esa transferencia constituye una
      distribución realizada por vos y no por el HOSPITAL BRITANICO.
      <br />
      <br />
      El Usuario defenderá y mantendrá indemne al HOSPITAL BRITANICO,
      contra todo daño y/o perjuicio, cualquiera fuera su naturaleza,
      inclusive los gastos por honorarios de abogados, que pudieran surgir
      con motivo u ocasión de cualquier acción o demanda iniciada por un
      tercero como consecuencia del incumplimiento del Usuario de
      cualquiera de las cláusulas del presente o de la violación del mismo
      a la ley o derecho de un tercero.
      <br />
      <br />
      De la misma manera, el Usuario mantendrá indemne al HOSPITAL
      BRITANICO así como también a sus directivos, empleados,
      representantes, presentes o futuros, de y contra todo daño y/o
      perjuicio, cualquiera fuera su naturaleza, derivado u ocasionado,
      directa o indirectamente, por la utilización de los Servicios
      ofrecidos en el SITIO, cuando se deriven o sean ocasionados, directa
      o indirectamente, del incumplimiento del Usuario de cualquiera de
      las cláusulas de la presente o de la violación por el mismo a la ley
      o derecho de un tercero.
    </p>
    <h5>Propiedad intelectual</h5>
    <p>
      El contenido del sitio web, incluyendo los materiales, documentos,
      bases de datos, diseños, marcas, logotipos, gráficos, etc., son
      propiedad exclusiva del HOSPITAL BRITANICO. Por lo tanto, está
      prohibido que modifiques, copies, distribuyas, transmitas,
      publiques, edites o explotes de cualquier forma el contenido y el
      diseño del sitio web con fines comerciales y/o privados, en todo o
      en parte, y por cualquier forma o medio, sin una autorización
      expresa del HOSPITAL BRITANICO.
    </p>
    <h5>Privacidad de la información personal y/o del grupo familiar</h5>
    <p>
      No difundas por ninguna razón tu nombre de usuario y contraseña. No
      abras la sesión y permitas el ingreso de terceros al sitio fuera de
      tu supervisión. Los datos e información proporcionados en este sitio
      web sólo se podrán divulgar al grupo de personas con usuario de
      acceso al sitio web que necesiten conocer esa información para
      cumplir con sus responsabilidades. Ingrese aquí para conocer la
      Política de Protección de Datos Personales. Para mayor seguridad te
      recomendamos: (a) no difundas tu nombre de usuario y contraseña a
      terceros (vos sos responsable de mantener la confidencialidad de
      estos datos y de todos los accesos que se hagan al sitio web (con su
      correspondiente uso) con su usuario, ya sea autorizados o no por
      vos. En caso de que adviertas cualquier uso no autorizado de usuario
      o contraseña, vos notificarás de inmediato al HOSPITAL BRITANICO;
      (b) elegí una contraseña acorde a los lineamientos de seguridad
      requeridos por el sitio web, que tenga un mínimo de 8 caracteres,
      contener al menos una letra, un número y un carácter especial
      ($@!%*?&.). Te recomendamos que no uses contraseñas que contengan
      referencias a fechas de cumpleaños, edad y nombres propios o de
      familiares o mascotas, etc. Adicionalmente te recomendamos que tu
      cuenta de correo electrónico asociada al sitio también posea una
      contraseña fuerte. (c) cerrá la sesión especialmente cuando utilices
      una computadora que compartas con otras personas y evitá conectarte
      a conexiones de internet de acceso público. (d) también te sugerimos
      que ejecutes periódicamente un antivirus en el equipo que uses para
      acceder al sitio web. y (e) nunca ingreses al sitio web desde un
      enlace en un correo electrónico no confiable.
    </p>
    <h5>Dsponibilidad</h5>
    <p>
      El HOSPITAL BRITANICO realizará todo esfuerzo a su alcance para
      garantizarte la disponibilidad y acceso al sitio web las 24 horas
      del día, durante todo el año. No obstante, aceptarás que, en
      ocasiones, y debido a diversas causas como el suministro de nuevas
      conexiones, cambios de direccionamiento y/o actualizaciones de
      operaciones de mantenimiento que, en general, impliquen la
      suspensión del acceso o utilización del sitio web, podrán producirse
      interrupciones por el tiempo necesario para acometer dichas tareas.
      Asimismo, el HOSPITAL BRITANICO se reserva el derecho de modificar o
      eliminar el contenido, estructura, diseño, servicios y condiciones
      de acceso y/o uso del sitio web, en forma unilateral y sin previo
      aviso.
    </p>
    <h5>General</h5>
    <p>
      El presente acuerdo no podrá ser interpretado como un contrato de
      sociedad, mandato, agencia o que genere algún tipo de relación entre
      el HOSPITAL BRITANICO y el Usuario. Si alguna de las condiciones que
      anteceden fuera declarada nula, la misma no afectará en modo alguna
      la validez o exigibilidad del resto de las condiciones previstas en
      este acuerdo. Este acuerdo no podrá ser enmendado o modificado por
      el Usuario, a excepción de que sea por medio de un documento escrito
      y firmado tanto por el Usuario y el HOSPITAL BRITANICO. Nuestra
      falta de acción con respecto a una violación del convenio por parte
      del Usuario u otros no afecta nuestro derecho a actuar frente a una
      violación posterior o similar. Los acápites del presente son
      referencias y de ninguna manera definen, limitan, delimitan o
      describen el ámbito o la extensión de dicha sección.
    </p>
    <h5>Comunicación</h5>
    <p>
      Toda notificación u otra comunicación que deba efectuarse bajo el
      presente, deberá realizarse por escrito: i)Al Usuario: al domicilio
      informado por el Usuario o a la cuenta de correo electrónica
      declarada; ii) Al HOSPITAL BRITANICO: a la casilla
      atencionalpaciente@hbritanico.com.ar o a la calle Perdriel N° 74 de
      la Ciudad Autónoma de Buenos Aires.
    </p>
    <h5>Jurisdicción compoetente y ley aplicable</h5>
    <p>
      La resolución de cualquier controversia derivada de la
      interpretación y/o cumplimiento de estos términos y condiciones de
      uso está sujeta a las leyes de la República Argentina, con exclusión
      de toda norma que remita a la aplicación de una ley extranjera, y a
      la jurisdicción de los Tribunales Ordinarios de la Capital Federal,
      renunciando expresamente, las partes implicadas, a cualquier otro
      fuero que pudiera corresponderles.
    </p>
    <h5>Modificaciones</h5>
    <p>
      El HOSPITAL BRITANICO se reserva los siguientes derechos: (i) de
      modificar o eliminar en forma unilateral, parcial o tal, su sitio
      web y sus condiciones de uso; (ii) negar e impedir el acceso a este
      sitio web, sus servicios y contenidos, sin aviso previo, a aquellos
      usuarios que no cumplan con sus obligaciones de uso; y (iii) poner
      término, suspender o interrumpir unilateralmente, en cualquier
      momento, el acceso y uso del sitio web.
    </p>
    </>)
  }  
  return (
    <Modal
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="custom-header">
        <Modal.Title
          className={"titulo-proteccion-pshb"}
        >
          {footerConfiguration?.titulo_terminos}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className={
            "politica-proteccion-pshb"
          }
        >
          <div
            className={
              "barra-politica-proteccion-pshb"              
            }
          />
          {/* <h5>Introducción</h5> */}
         <div dangerouslySetInnerHTML={{__html: sanitizer(footerConfiguration?.contenido_terminos)}}></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn2-color3-r"
          variant="primary"
          onClick={() => onToggle()}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
