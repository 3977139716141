import "./styles.scss";
import "react-tooltip/dist/react-tooltip.css";
import axios from "axios";
import React, { useContext, useRef } from "react";
import { useToggle } from "../../../hooks/useToggle";
import CustomSelect from "../../common/customSelect";
import { CUSTOM_SELECT_COLOR_WHITE } from "../../common/customSelect/constants";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { useFormik } from "formik";
import validate from "./validate";
import FileInput from "./../../common/file-input-component";
import { Button, Tooltip } from "react-bootstrap";
import { ModalConfirmChangeComponent } from "./confirm-change";
import { useState } from "react";
import { MessageContext } from "./context";
import { OverlayTrigger } from "react-bootstrap";
import { myAxios } from "../../../utils/fetch";
import { InformacionComponent } from "../refund/informacion";
import { familyGroupFilterPSHB } from "../../../hooks/familyGroupFilter";

const AddMecanismoIntegracion = () => {
  const MessageToggle = useToggle();
  const [responseData, setResponseData] = useState({
    message: "",
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [money, setMoney] = useState(true);

  const informacionToggle = useToggle();

  const ref_periodoPrestacion = useRef();
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [categories, setCategories] = React.useState([]);
  const [familygroup, setFamilyGroup] = React.useState([]);

  // CLOSE
  const [closeCategoria, setCloseCategoria] = React.useState(false);
  const [closePaciente, setClosepaciente] = React.useState(false);
  const [closeTipoDocumento, setCloseTipoDocumento] = React.useState(false);
  const [clearFile, setClearFile] = useState(false);
  // END CLOSE

  //CLICK
  const clickpaciente = () => {
    setClosepaciente(false);
    setCloseCategoria(true);
    setCloseTipoDocumento(true);
  };

  const clickTipoDocumento = () => {
    setClosepaciente(true);
    setCloseCategoria(true);
    setCloseTipoDocumento(false);
  };
  //END CLICK

  const [periodoSelected, setPeriodo] = React.useState();
  const [tipoDocumentoSelected, setTipoDocumento] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  const [categorySelected, setCategory] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  const [pacienteSelected, setPaciente] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  const [discSelected, setDisc] = React.useState({
    id: "",
    name: "Seleccionar",
  });

  var tipoDocumentoList = [
    { id: "factura", name: "Factura" },
    { id: "recibo", name: "Recibo" },
    { id: "factura_de_credito_electronica", name: "Factura de Credito Electrónica" },
    { id: "nota_credito", name: "Nota Crédito" },
    { id: "nota_debito", name: "Nota Debito" },
  ];

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const obtenerGrupoFamiliar = async () => {
    return myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/familiargroup/getfamiliargroupbyid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    });
  };

  const obtenerDatos = async () => {
    try {
      var listaGrupoFamiliar = await obtenerGrupoFamiliar();
      let filterFamilyGroup = familyGroupFilterPSHB(
        listaGrupoFamiliar.data.data.MergedFamilyGroup,
        listaGrupoFamiliar.data.data.PrestaciFamilyGroup
      );
      let familyGroup = [];
      try {
        familyGroup = filterFamilyGroup.map((family) => {
          return {
            id: family.credential.split(" ")[1],
            name: family.fullName,
            socialSecurityNumber: family.credential,
            dni: family.dni
          };
        });
      } catch (error) {}

      var temporalFamilyGroup = [
        {
          id: user.user.data.Customers.socialSecurityNumber.split(" ")[1],
          name:
            user.user.data.Customers.firstName +
            " " +
            user.user.data.Customers.lastName,
          socialSecurityNumber: user.user.data.Customers.socialSecurityNumber,
          dni: user.user.data.Customers.dni
        },
        ...familyGroup,
      ];

      // Filtrar duplicados
      try {
        temporalFamilyGroup = temporalFamilyGroup.reduce((acc, current) => {
          const x = acc.find(
            (item) =>
              item.dni === current.dni
          );
          if (!x) {
            acc.push(current);
          }
          return acc;
        }, []);
      } catch (error) {}

      setFamilyGroup(temporalFamilyGroup);
      setPaciente({
        id: user.user.data.Customers.socialSecurityNumber,
        name:
          user.user.data.Customers.firstName +
          " " +
          user.user.data.Customers.lastName,
        numero: user.user.data.Customers.socialSecurityNumber,
      });
    } catch (exception) {}
  };

  React.useEffect(() => {
    obtenerDatos();
  }, []);

  const limpiarFormulario = () => {
    formik.resetForm();

    setCategories([]);
    setCategory({
      id: "",
      name: "Seleccionar",
    });

    setTipoDocumento({
      id: "",
      name: "Seleccionar",
    });

    ref_periodoPrestacion.current.value = "";
    formik.setFieldValue("periodoPrestacion", "");
    setPeriodo("");
  };

  const seleccionarPeriodo = (e) => {
    setPeriodo(e.target.value);
    formik.setFieldValue("periodoPrestacion", e.target.value);
  };

  const anadirComprobanteNumero = (e) => {
    let { value } = e.target;
  
    if (tipoDocumentoSelected.id === "factura" || tipoDocumentoSelected.id === "recibo") {

      const expresionContinua = /^[a-cA-CmM]{1}\d{0,13}$/;
      if (!expresionContinua.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    } else {

      const expresionContinua = /^[a-cA-C]{1}\d{0,13}$/;
      if (!expresionContinua.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    }
  
    formik.setFieldValue("nroComprobante", value);
  };

  const seleccionarTipoDocumento = (id, name, text) => {
    setTipoDocumento({ id, name: text });
    formik.setFieldValue("tipoComprobante", id);
  };

  const seleccionarpaciente = async (id, name, text) => {
    formik.setFieldValue("paciente", id);
    setDisc({
      id: "",
      name: "Seleccionar",
    });

    setCategories([]);
    setCategory({
      id: "",
      name: "Seleccionar",
    });

    var credential = familygroup.find(
      (family) => family.socialSecurityNumber.split(" ")[1] === id
    )?.socialSecurityNumber;
    setPaciente({ id, name: text, numero: credential });
  };

  const guardarMecanismo = (values) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_GATEWAY_QA}/patient/api/v1/mecanismo/save`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        ...values,
        contrato: pacienteSelected.numero,
        paciente: pacienteSelected.name,
      },
    })
      .then((res) => {
        setResponseData({
          message: "Se ha cargado correctamente.",
          error: false,
        });
        MessageToggle.onToggle();
        limpiarFormulario();
      })
      .catch((e) => {
        setResponseData({
          message: "Error al cargar el mecanismo de integración",
          error: true,
        });
        MessageToggle.onToggle();
      })
      .finally(() => {
        setLoading(false);
        setClearFile((prev) => !prev);
      });
  };

  const formik = useFormik({
    initialValues: {
      valorPresentado: "",
      tipoComprobante: "",
      paciente: user.user.data.Customers.idSap,
      periodoPrestacion: "",
      nroComprobante: "",
      tipoComprobante: "",
      cuit: "",
      facturaBase64File: "",
      plantillaBase64File: "",
      facturaBase64FileExt: "",
      plantillaBase64FileExt: "",
    },
    validate,
    onSubmit: (values) => {
      guardarMecanismo(values);
    },
  });

  const changeFile = async (file, name) => {
    let base64 = await toBase64(file);
    base64 = base64.split("base64,")[1];
    const fileExtension = file.name.split(".").pop();

    formik.setFieldValue(name, base64);
    formik.setFieldValue(`${name}Ext`, fileExtension);
  };

  const cambiarValorPresentado = (e) => {
    let { value } = e.target;
    value = value.replaceAll(",", ".");
    const regexNumber = /^[0-9]$/;

    if (
      !regexNumber.test(value.at(-1)) &&
      e.keyCode !== 45 &&
      value.at(-1) !== "."
    ) {
      value = value.substring(0, value.length - 1);
    }

    let cantidadPuntos = value
      .split("")
      ?.filter((item) => item === ".")?.length;

    if (cantidadPuntos > 1) {
      value = value.substring(0, value.length - 1);
    }

    e.target.value = value;

    if (value.split(".")[0].length <= 7) {
      formik.setFieldValue("valorPresentado", value);
      formik.handleChange(e);
    }
  };

  const tooltipValorPresentado = (props) => (
    <Tooltip {...props}>
      Ingresar el monto por el cual se hace la solicitud, sin signo pesos ($),
      sin separador de miles (,) y para ingresar decimales separar con un
      punto(.)
    </Tooltip>
  );

  const tooltipDocumento = (props) => (
    <Tooltip {...props}>
      {tipoDocumentoSelected.id === "factura" || tipoDocumentoSelected.id === "recibo"
        ? "Incluir al inicio la letra correspondiente (A, B, C o M) seguida de 13 dígitos."
        : "Incluir al inicio la letra correspondiente (A, B o C) seguida de 13 dígitos."}   
    </Tooltip>
  );

  const tooltipFecha = (props) => (
    <Tooltip {...props}>Ingrese el periodo del comprobante cargado.</Tooltip>
  );

  const tooltipCuit = (props) => (
    <Tooltip {...props}>
      Ingresar el CUIT del profesional o institución que emitió el
      comprobante(Solo se admiten valores numéricos)
    </Tooltip>
  );

  const cambiarCuit = (e) => {
    let { value } = e.target;

    // Remover cualquier carácter que no sea un número o guión
    value = value.replace(/[^0-9\-]/g, "");

    // Agregar guiones automáticamente en las posiciones correctas
    if (value.length === 2 || value.length === 11) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        // Evitar retroceso y suprimir
        value = value + "-";
      }
    }

    e.target.value = value;
    formik.handleChange(e);
  };

  return (
    <MessageContext.Provider value={responseData}>
      <section className=" ">
        <ModalConfirmChangeComponent
          visible={MessageToggle.toggle}
          onToggle={MessageToggle.onToggle}
        />

        <InformacionComponent
          visible={informacionToggle.toggle}
          onToggle={informacionToggle.onToggle}
        />

        {/* INICIO FORMULARIO */}
        <div className="container p-4">
          <form onSubmit={formik.handleSubmit}>
            {/* TITULO */}
            <div className="row mt-5">
              <div
                className="col-lg-12"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 className="section_pshb_title">
                  <b>Mecanismo Integración</b>
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12" id="header-invoice">
                <span className="section_pshb_subtitle">
                  En el presente apartado cargue la documentación
                  correspondiente al <strong>Mecanismo de integración</strong> y
                  complete los siguientes campos
                </span>
              </div>
            </div>

            <div className="separador-table-green-result"></div>

            <div className="card card-refund b-radius-20 shadow-external">
              <div className="g-0">
                <div className="row">
                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Paciente <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund h-30 ">
                      <div style={{ width: "100%" }}>
                        <div style={{ position: "relative" }}>
                          <CustomSelect
                            defaultText={pacienteSelected.name}
                            optionsList={familygroup}
                            type={CUSTOM_SELECT_COLOR_WHITE}
                            width="100%"
                            fontFamily="Objectivity-Medium"
                            fontSize="14px"
                            onChange={seleccionarpaciente}
                            name="paciente"
                            close={closePaciente}
                            onClick={clickpaciente}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Periodo Prestación <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund relative">
                      <OverlayTrigger placement="top" overlay={tooltipFecha}>
                        <div className="input-icons-date-pshb-refund w-100">
                          <input
                            value={periodoSelected}
                            type="month"
                            ref={ref_periodoPrestacion}
                            name="periodoPrestacion"
                            className={
                              (formik.errors.periodoPrestacion
                                ? "error-data "
                                : "") +
                              "input-round shadow-blue w-100 input-date-ios"
                            }
                            onChange={seleccionarPeriodo}
                            placeholder="Elegir fecha"
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      CUIT prestador <span className="obligatorio">*</span>
                    </span>

                    <div className="input-icons-date-pshb-refund d-flex flex-column">
                      <OverlayTrigger placement="top" overlay={tooltipCuit}>
                        <input
                          type="text"
                          onPaste={(e) => e.preventDefault()}
                          data-tip="Solo se permiten valores númericos"
                          placeholder="00-00000000-0"
                          onChange={cambiarCuit}
                          onBlur={cambiarCuit}
                          value={formik.values.cuit}
                          name="cuit"
                          maxLength={13}
                          className={
                            (formik.errors.cuit && formik.touched.cuit
                              ? "error-data "
                              : "") +
                            "input-round input-text-pshb shadow-blue w-100"
                          }
                        />
                      </OverlayTrigger>
                      {formik.touched.cuit ? (
                        <span className="error-label-pshb">
                          {formik.errors.cuit}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Tipo de comprobante <span className="obligatorio">*</span>
                    </span>

                    <div className="input-icons-date-pshb-refund relative h-30">
                      <CustomSelect
                        defaultText={tipoDocumentoSelected.name}
                        optionsList={tipoDocumentoList}
                        type={CUSTOM_SELECT_COLOR_WHITE}
                        width="100%"
                        fontFamily="Objectivity-Medium"
                        fontSize="14px"
                        onChange={seleccionarTipoDocumento}
                        name="tipoComprobante"
                        error={
                          formik.errors.tipoComprobante &&
                          formik.touched.tipoComprobante
                        }
                        close={closeTipoDocumento}
                        onClick={clickTipoDocumento}
                      />
                    </div>
                    {formik.touched.tipoComprobante ? (
                      <span className="error-label-pshb">
                        {formik.errors.tipoComprobante}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Nro comprobante <span className="obligatorio">*</span>
                    </span>
                    <OverlayTrigger placement="top" overlay={tooltipDocumento}>
                      <div className="input-icons-date-pshb-refund d-flex flex-column">
                        <input
                          type="text"
                          onPaste={(e) => e.preventDefault()}
                          placeholder="A000000000000"
                          maxLength={14}
                          onChange={anadirComprobanteNumero}
                          onBlur={formik.handleBlur}
                          value={formik.values.nroComprobante}
                          name="nroComprobante"
                          className={
                            (formik.errors.nroComprobante &&
                            formik.touched.nroComprobante
                              ? "error-data "
                              : "") +
                            "input-round input-text-pshb shadow-blue w-100"
                          }
                        />
                        {formik.touched.nroComprobante ? (
                          <span className="error-label-pshb">
                            {formik.errors.nroComprobante}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>

                  <div className="section-date col-md-6 col-xl-4">
                    <span className="label-pshb">
                      Valor presentado <span className="obligatorio">*</span>
                    </span>
                    <div className="input-icons-date-pshb-refund ">
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipValorPresentado}
                      >
                        <div
                          className="d-flex flex-column w-100"
                          onMouseLeave={() => setMoney(false)}
                          onMouseEnter={() => setMoney(true)}
                        >
                          {money ? (
                            <input
                              onPaste={(e) => e.preventDefault()}
                              type="text"
                              data-for="valorPresentado"
                              onChange={cambiarValorPresentado}
                              onBlur={cambiarValorPresentado}
                              value={formik.values.valorPresentado}
                              name="valorPresentado"
                              className={
                                (formik.errors.valorPresentado &&
                                formik.touched.valorPresentado
                                  ? "error-data "
                                  : "") +
                                "input-round input-text-pshb shadow-blue w-100"
                              }
                            />
                          ) : (
                            <span
                              style={{ lineHeight: "25px" }}
                              className="input-round input-text-pshb shadow-blue w-100"
                            >
                              {"$" +
                                parseFloat(formik.values.valorPresentado)
                                  .toLocaleString("es-AR")
                                  .replaceAll("NaN", "")}
                            </span>
                          )}
                          {formik.touched.valorPresentado ? (
                            <span className="error-label-pshb">
                              {formik.errors.valorPresentado}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 justify-content-center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "30px 0px",
                    }}
                  >
                    <span id="aclaracion-archivos">
                      Los archivos a subir deberían ser de tipo{" "}
                      <span className="enfasis">PDF</span>,{" "}
                      <span className="enfasis">PNG</span>,{" "}
                      <span className="enfasis">JPG</span> o{" "}
                      <span className="enfasis">JPEG</span>. Con un peso máximo
                      de 5Mb
                    </span>
                  </div>

                  {/* ARCHIVOS */}
                  <div className="col-md-12 col-lg-5 col-sm-12 meca-factura">
                    <span className="label-file">
                      Factura, Recibo o Ticker Fiscal
                    </span>
                    <span style={{ color: "red", fontSize: "20px" }}> *</span>
                    <FileInput
                      clearFile={clearFile}
                      onChange={(file) => changeFile(file, "facturaBase64File")}
                    />
                    {formik.errors.facturaBase64File &&
                      formik.touched.facturaBase64File && (
                        <div
                          style={{
                            color: "red",
                            fontFamily: "Objectivity-Regular",
                            fontSize: "12px",
                            marginTop: "10px",
                            textAlign: "center",
                            width: "90%",
                          }}
                        >
                          Intente con otro archivo, de ser posible en formato
                          PDF.
                        </div>
                      )}
                  </div>

                  <div className="col-md-12 col-lg-5 col-sm-12">
                    <span className="label-file">Planilla de asistencia</span>
                    <FileInput
                      clearFile={clearFile}
                      onChange={(file) =>
                        changeFile(file, "plantillaBase64File")
                      }
                    />
                    {formik.errors.plantillaBase64File &&
                      formik.touched.plantillaBase64File && (
                        <div
                          style={{
                            color: "red",
                            fontFamily: "Objectivity-Regular",
                            fontSize: "12px",
                            marginTop: "10px",
                            textAlign: "center",
                            width: "90%",
                          }}
                        >
                          Intente con otro archivo, de ser posible en formato
                          PDF.
                        </div>
                      )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 text-center mt-5">
                    <Button
                      type="submit"
                      disabled={loading}
                      className="btn btn-hb-primary-without-border shadow-blue"
                    >
                      {!loading ? (
                        "Confirmar"
                      ) : (
                        <>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* FIN FORMULARIO */}
        <div className="footer-pshb"></div>
      </section>
    </MessageContext.Provider>
  );
};

export default AddMecanismoIntegracion;
