import React, { useContext } from "react";
import style from "./style.scss";
import { Link } from "react-router-dom";
// import IconFb from '../../../assets/img/icon-fb.png'
// import IconInsta from '../../../assets/img/icon-insta.png'
// import IconLinkedin from '../../../assets/img/icon-linkedin.png'
// import IconTw from '../../../assets/img/icon-tw.png'

import IconFb from "../../../assets/img/redes/Facebook.png";
import IconInsta from "../../../assets/img/redes/Instagram.png";
import IconLinkedin from "../../../assets/img/redes/LinkedIn.png";
import IconTw from "../../../assets/img/redes/Xfooter.svg";

import FooterLocationPin from "../../../assets/img/footer-location-pin.png";
import FooterPhone from "../../../assets/img/footer-phone.png";
import FooterBook from "../../../assets/img/footer-book.png";
import { useToggle } from "../../../utils/hooks/useToggle";
import { PoliticaProteccionComponent } from "./politica-proteccion";
import { TerminosCondicionesComponent } from "./terminos-condiciones";
import LogoFooter from "../../../assets/img/logo-hb-blak.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faBook,
  faCircleXmark,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const CommonFooter = (props) => {
  const politicaToggle = useToggle();
  const terminosToggle = useToggle();

  const { user,footerConfiguration } =
    useContext(UserGlobalContextMemorySpace);

  function ScrollTop() {
    window.scrollTo({ top: 0, left: 0, beahivor: "smooth" });
  }

  return (
    <section className="section-footer" style={{ minHeight: "200px" }}>
      <div className="container">
        <div className="row pt-3 pb-2">
          
            <div className="col-lg-6">
              <p className="d-flex justify-content-start align-items-center">
                <img
                  src={LogoFooter}
                  alt="logo footer"
                  style={{ paddingRight: "5px", height: "16px" }}
                />
                <span className="section-footer__titulo">
                  {" "}
                  Hospital Británico | © 2022
                </span>
              </p>

              <p className="section-footer__texto-PSHB">
                Sr. consumidor o usuario: ante cualquier duda o reclamo diríjase
                al área de defensa y protección al consumidor de cualquier
                Centro de Gestión y Participación Comunal (CGPC) del Gobierno de
                la Ciudad Autónoma de Bs. As. y/o al teléfono gratuito 147 del
                Gobierno de la Ciudad Autónoma de Buenos Aires. <br />
                <br /> R.N.E.M.P Nº 1255 -Superintendencia de Servicios de Salud
                -0800-222-SALUD (72583){" "}
                <a href="https://www.argentina.gob.ar/sssalud" target="_blank">
                  {" "}
                  www.sssalud.gob.ar{" "}
                </a>{" "}
              </p>
              <p className="section-footer__texto">
                Defensa de las y los consumidores. Para reclamos ingrese{" "}
                <a
                  href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario"
                  target="_blank"
                >
                  aquí.
                </a>
              </p>
            </div>
          

          <div className="col-lg-6">
            <div className="d-flex contact-social-networks">
              <div className="icons d-flex align-items-center">
                <a
                  id="socialIcon"
                  href={footerConfiguration?.facebook}
                  target="_blank"
                >
                  <img
                    src={IconFb}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a
                  id="socialIcon"
                  href={footerConfiguration?.twitter}
                  target="_blank"
                >
                  <img
                    src={IconTw}
                    className="img-fluid  px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a
                  id="socialIcon"
                  href={footerConfiguration?.linkedin}
                  target="_blank"
                >
                  <img
                    src={IconLinkedin}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a
                  id="socialIcon"
                  href={footerConfiguration?.instagram}
                  target="_blank"
                >
                  <img
                    src={IconInsta}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
              </div>
              <div className="section-footer__privacidad">
                <span className="section-footer__texto">
                  <span>
                    <button className="btn" onClick={politicaToggle.onToggle}>
                      POLÍTICA DE PRIVACIDAD
                    </button>
                  </span>

                  <span>
                    {" "}
                    <button className="btn" onClick={terminosToggle.onToggle}>
                      {" "}
                      | TERMINOS Y CONDICIONES
                    </button>
                  </span>

                  <span>
                    {" "}
                    <button className="btn btnPointer">
                      {" "}
                      |{" "}
                      <a
                        target={"_blank"}
                        className="link-default"
                        rel={"noreferrer"}
                        href={footerConfiguration?.intranet}
                      >
                        INTRANET
                      </a>
                    </button>
                  </span>
                </span>
                <PoliticaProteccionComponent
                  visible={politicaToggle.toggle}
                  onToggle={politicaToggle.onToggle}
                />
                <TerminosCondicionesComponent
                  visible={terminosToggle.toggle}
                  onToggle={terminosToggle.onToggle}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex flex-column mx-2 mt-3 contact-information">
                  <div className="icon-location">
                    <img
                      src={FooterLocationPin}
                      className="img-fluid"
                      alt="icon-fb"
                      style={{}}
                    />
                    <a
                      href={footerConfiguration?.ubicacion}
                      target="_blank"
                    >
                      <span className="ms-2 section-footer__texto">
                        {footerConfiguration?.direccion_domicilio}
                      </span>
                    </a>
                  </div>
                  <div className="icon-phone" style={{ marginTop: "5px" }}>
                    <img
                      src={FooterPhone}
                      className="img-fluid"
                      alt="icon-fb"
                      style={{}}
                    />
                    <a href={footerConfiguration?.telefono_domicilio}>
                      <span className="ms-2 section-footer__texto">
                        {footerConfiguration?.telefono_domicilio}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex flex-column mx-2 mt-3 contact-information">
                  <div className="icon-book">
                    <FontAwesomeIcon icon={faBook} color="#0f86c3" />                    
                      <span
                        className="ms-2 section-footer__texto"
                        onClick={() => {
                          ScrollTop();
                        }}
                      >
                        <Link to="/sugerencias">Libro de sugerencias</Link>
                      </span>
                    
                  </div>
                  
                    <>
                      <div className="icon-book">
                        <FontAwesomeIcon icon={faDownload} color="#0f86c3" />
                        <span
                          onClick={() => {
                            ScrollTop();
                          }}
                          className="ms-2 section-footer__texto"
                        >
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={
                              process.env.REACT_APP_PLAN_SALUD +
                              "/pages/baja-plan-salud"
                            }
                          >
                            Solicitud de Baja de Plan de Salud
                          </a>
                        </span>
                      </div>
                      <div className="icon-book">
                        <FontAwesomeIcon icon={faCircleXmark} color="#0f86c3" />
                        <span
                          onClick={() => {
                            ScrollTop();
                          }}
                          className="ms-2 section-footer__texto"
                        >
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={
                              process.env.REACT_APP_PLAN_SALUD +
                              "/pages/tramite-arrepentimiento"
                            }
                          >
                            Trámite de arrepentimiento
                          </a>
                        </span>
                      </div>
                      <div className="icon-book">
                        <FontAwesomeIcon icon={faFile} color="#0f86c3" />
                        <span className="ms-2 section-footer__texto">                          
                          <a href={footerConfiguration?.contrato?.url} target="_blank" rel="noopener noreferrer" download="nombre_pretendido_del archivo.pdf">
                            Contrato de Adhesión
                          </a>
                         
                        </span>
                      </div>
                    </>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonFooter;
