import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import { ModalBody, ModalHeader, Modal } from 'react-bootstrap';
import UserSidebar from '../../../components/common/user-sidebar';
import AyudaComponent from '../../../components/hb/ayuda';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import style from './autorizaciones.scss'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {Check} from '../../../assets/img/check-green.png'
import axios from 'axios';
import { SwalMessage } from '../../../utils/sweet-alert/sweet-alert';
import { myAxios } from '../../../utils/fetch';


const AutorizacionesPSHB = () => {

    const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser} = useContext(UserGlobalContextMemorySpace);
    const [routes, setRoutes] = useState({routes: [{route: "/autorizaciones", name:"Autorizaciones"}]})
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
    const [text, setText] = useState();
    const [loader, setLoader] = useState(false);

    const [errorMsg, setErrorMsg] = useState();
    const fileTypes=["application/pdf","image/png","image/jpg","image/jpeg"];
    const [fileSelected,setFile]=React.useState({label:"",error:false});
    const [archivoDU,setArchivoDU]=React.useState(null);
    const [error, setError] = useState("");

    const handleAutorizaciones = async () => {
        setError("");
        // console.log(archivoDU)
        // console.log(fileSelected)
        if(!text || !archivoDU){
            setError("* Por favor adjunta el documento respaldatorio y los comentarios.");
        }else{
            setLoader(true);
            let userToken = await user.user.data.Token;
            let appToken = await user.user.data.appToken;
            let idCustomer = await user.user.data.Customers.idCustomer;
            let credential = await user.user.data.Customers.socialSecurityNumber

            let res = await myAxios({
                url: process.env.REACT_APP_GATEWAY_QA+"/customers/api/v1/authorization",
                method: 'POST',      
                headers:{
                    'Content-Type': 'application/json',
                    'USERTOKEN': user.user.data.Token,
                    'Authorization' : `Bearer ${user.user.data.appToken}`
                },
                data:{
                    "file": archivoDU,
                    "fileName": fileSelected.label,
                    "credential": credential,
                    "idCustomer": idCustomer,
                    "observations": text
                }
            }).then((response)=>{
                return response
            }).catch((error)=>{
                setLoader(false)
                SwalMessage('info', '<h5 style="color: #224d7c"></h5>', "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos", 'Continuar')
            })

            if(res.data.result.responseCode == 200){
                setText("");
                setArchivoDU("");
                setFile({label:"",error:false});
                SwalMessage('success', '<h5 style="color: #224d7c"></h5>', res.data.result.message, 'Continuar')
            }else{
                SwalMessage('info', '<h5 style="color: #224d7c"></h5>', "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos", 'Continuar')
            }

            setLoader(false);

        }

    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const seleccionarArchivo=()=>{
        setErrorMsg("")
        var input = document.createElement('input');
        input.type = 'file';
        input.accept=".jpg,.jpeg,.png,.pdf"        
        input.onchange = async (e) => { 
            var file = e.target.files[0]; 
                        
            if(fileTypes.indexOf(file.type)===-1)
            {
                setFile({label:"",error:true,errorMessage:"Solo se permiten imagenes JPG,JPEG,PNG y PDF"})
                setErrorMsg("Solo se permiten imagenes JPG,JPEG,PNG y PDF.")
                setArchivoDU(null)
                return;
            }

            if(file.size>3000000)
            {
                setFile({label:"",error:true,errorMessage:"Por favor verifica que tu archivo pese menos de 3 MB"});
                setErrorMsg("Por favor verifica que tu archivo pese menos de 3 MB")
                setArchivoDU(null)
                return;
            }

            // console.log("FILE",file);

            try {
                const result = await toBase64(file);
                let aux = [];
                aux = result.split(",");
                setFile({label:file.name,error:false,file:file, base64: aux[1]})
                setArchivoDU(aux[1]);
            } catch (error) {
                setFile({label:"Error",error:true,errorMessage:"Detectamos un error, por favor intenta nuevamente en unos minutos."});
                setErrorMsg("Detectamos un error, por favor intenta nuevamente en unos minutos.")
                setArchivoDU(null)
                return;
            }
            
        }
         
        input.click();
    }

    useLayoutEffect(() => {
        setHeight(ref.current.clientHeight);
      }, []);

      

    return (

        //     <Modal
        //     size={"small"}
        //     aria-labelledby="contained-modal-title-vcenter"
        //     //show={visible}
        //     centered
        //     //onHide={onToggle}        
        // >

        //     <ModalHeader><img src=""></img></ModalHeader>
        //     <ModalBody>¡Su Solicitud se cargó con exitó!</ModalBody>
        //     </Modal>


            <div className='d-flex help-page' ref={ref} >
             <UserSidebar routes={routes} height={height} ></UserSidebar>

             <section className=''>
                <div className='container p-4' >
                    <div className='row' id="container-autorizaciones-pshb" style={{marginTop:"-30px"}}>
                        <div className={'recupero-contrasena-card '+(userPSHB?"recupero-contrasena-card-PSHB":"recupero-contrasena-card-HB")} style={userPSHB ? {borderLeft: "20px solid #9fcc4b"}: {borderLeft: "20px solid red"}}>			  
                            <div className='d-flex flex-column recupero-contrasena-cardbox '> 
                                
                                

                                <div>
                                            <div className='d-flex justify-content-center'>
                                                <h2 className='font-color-PSHB titulo-autorizaciones'>Inicio proceso de autorización</h2>
                                            </div>
                                            <hr style={userPSHB ? {border: "3px solid #9FCC4B", width: "100%"}: {border: "3px solid red", width: "100%"}}/>
                                        </div>
                                        <div style={{ marginTop: "0px"}} className='d-flex flex-column justify-content-center  align-items-center'>
                                            <div className=''>
                                                <h5 className='font-color-autorizaciones'style={{marginTop:"10px"}}>Cargue el documento respaldatorio y haga click en <strong>Aceptar</strong> para iniciar la autorización.</h5>
                                            </div>
                                            <div className='' style={{marginTop:"10px",marginBottom:"20px"}}>
                                                <h5 className='font-color-autorizaciones'>Formatos posibles: <strong>DOC, DOCX, PDF, PNG</strong>. Tamaño máximo: <strong>3MB</strong>.</h5>
                                            </div>
                                            {/* <div className='cargar-archivos' style={{ width: "40vw",}} >
                                                <div className="input-file" style={{marginTop:"1rem"}}>
                                                    <i className="bi bi-cloud-upload" style={{color: "white", fontSize:"23px",}}></i>
                                                    <button disabled>
                                                        Cargar archivos
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className='col-md-8 cargador-autorizaciones'>
                                                <div>
                                                    <div className="custom-load-icon" onClick={()=>seleccionarArchivo()}>
                                                        <FontAwesomeIcon icon={faPaperclip}/>
                                                        <span>Adjuntar</span>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column",marginLeft: "100px"}}>
                                                        
                                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                            <span className={(fileSelected.error?"error-file ":"")+"round-label w-100 shadow-blue"} style={{width: "100%",display:"flex",alignItems:"center", fontSize: "14px", paddingLeft: "28px",height:"29px"}}>{fileSelected.file && !fileSelected.error ? fileSelected.label:""}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {fileSelected.error ?
                                                <span className={fileSelected.error?"error-file":""} style={{marginLeft:"",fontSize:"10px"}}>{fileSelected.errorMessage}</span>
                                                :
                                                <></>
                                            }

                                        </div>
                                        <div  className='d-flex flex-column justify-content-center' style={{ paddingBottom: "2rem",marginTop:"10px"}}>
                                            <span className='titulo-comentarios-autorizaciones'>Comentarios</span>
                                            <div className='d-flex justify-content-center'>                            
                                                <textarea onChange={(e)=> setText(e.target.value)} value={text} placeholder='Ingrese comentarios' className='text-area-autorizaciones' id="w3review" name="w3review" rows="6" cols="50"></textarea>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                {error ?
                                                    <span className="error-file" style={{marginLeft:"",fontSize:"10px"}}>{error}</span>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            <div className='d-flex justify-content-center' style={{marginTop: "10px"}}>
                                            {loader ?
                                                <button disabled={true} type='submit' className="button-loader shadow-blue">
                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                </button>
                                                :
                                                <input onClick={handleAutorizaciones} className='button-continuar button-continuar-autorizaciones shadow-blue' type="submit" value="Aceptar"/>
                                            }
                                            </div>
                                        </div>
                                        
                                        
                            
                            </div>
                        </div>              
                    </div>              
                </div>
                <div className='footer-pshb-large'></div>
            </section>
                </div>
    )
  }
  
  export default AutorizacionesPSHB;