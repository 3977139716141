import { Col, Row } from 'react-bootstrap'
import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import UserSidebar from '../../../components/common/user-sidebar';
import { DatosCoberturaComponent } from '../../../components/common/perfil/dato-cobertura'
import DigitalCredentialComponentPSHB from '../../../components/pshb/digital-credential';

const CredencialDigitalPSHB = () => {
  const [routes, setRoutes] = useState({routes: [{route: "/perfil", name:"Datos personales"}, ,{route: "/grupo-familiar", name:"Grupo familiar"},{route: "/credencial-digital", name:"Credencial digital"}]})
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
      setHeight(ref.current.clientHeight);
    }, []);

  
  return (

    
    <div className='d-flex help-page' ref={ref}>
      <UserSidebar routes={routes} height={height} ></UserSidebar>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
        <DigitalCredentialComponentPSHB> </DigitalCredentialComponentPSHB>        
        <div className='footer-pshb-large'></div>
      </div>
    </div>      
      
    
     
  )
}

export default CredencialDigitalPSHB;