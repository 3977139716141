import React, {useContext, useState, useEffect} from 'react';
import logo from '../../../assets/img/hb/Isologotipo.png'
import logoPSHB from '../../../assets/img/pshb/logo.jpg'
import bgImageLogin from './../../../assets/img/hb/image-login.jpg'
import bgImageLoginPSHB from './../../../assets/img/hb/test2.jpg'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Overlay from '../../../components/common/overlay';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { SwalMessage } from '../../../utils/sweet-alert/sweet-alert';
import { myAxios } from '../../../utils/fetch';
import Swal from 'sweetalert2';
import useProgressiveImage from "../../../hooks/useProgressiveImage";


const SocialMediaLink = (props) => {

    const loadedBgImage = useProgressiveImage(bgImageLoginPSHB)

    const [loader, setLoader] = useState(false);
    
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams({});
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const [render, setRender] = useState(true)

    const linkSocialMediaEmail = async () => {

        setLoader(true);
        let body = {
            token: token,
            email: email,
            pshb: true
        }
        
        const response = await axios({            
            url: process.env.REACT_APP_STRAPI_QA+"/external-user/linksocialmediaemailupdate",
            method: 'POST',      
            data: body
            }).then((res)=>{
                if(res.data.type == "socialMediaEmailLinked"){
                    setLoader(false)
                    SwalMessage('success', `Vinculamos tu cuenta de ${res.data.socialMedia ? res.data.socialMedia : "Google"} a tu usuario`, `Podrás iniciar sesión con mail y contraseña, o a través de ${res.data.socialMedia ? res.data.socialMedia : "Google"}`, "Iniciar sesión")
                    navigate("/")
                }else if(res.data.type == "invalidToken"){
                    setLoader(false)
                    SwalMessage('warning', '', 'Enlace vencido o inválido. Por favor solicita un nuevo enlace de vinculación.', "Continuar")
                    navigate("/")
                }else{
                    SwalMessage('warning', '', 'Detectamos un problema al vincular las cuentas. Por favor intenta nuevamente en unos minutos.', "Continuar")
                    setLoader(false)
                }
            }).catch(err=>{
                SwalMessage('warning', '', 'Detectamos un problema al vincular las cuentas. Por favor intenta nuevamente en unos minutos.', "Continuar")
                setLoader(false)
            })
        
    }



    useEffect(()=>{
        //Mount:

        if(!token || !email){
            
            navigate("/")
            
        }
        
    }, [])

    return (
        <>
            {loader ? <Overlay/> : null}
            <section>
                <div className="d-flex align-items-center" style={{height: "100vh"}}>
                    <div className="div-login">
                        <div className='d-flex flex-column align-items-center'>
                            <img className='img-logo' src={logoPSHB}></img>
                            <div className='br'></div>
                            <h5 className='default-fontColor'>Vinculación de cuenta</h5>
                            <div className='br'></div>
                            <p style={{paddingLeft: "20px", paddingRight: "20px", textAlign:"center"}} className='default-fontColor'>Vincularemos la casilla {email} a tu casilla de redes sociales.</p>
                            <div className='br'></div>
                            {loader ?
                                <button disabled={true} type='submit' className="button-loader shadow-blue">
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                        <span className="spinner-grow spinner-grow-sm"></span>
                                </button>
                                :
                                <button onClick={linkSocialMediaEmail} className='button-continuar shadow-blue'>Continuar</button>
                            }
                            <div className='br'></div>
                            <Link to={'/'} className='link-noStyle'>Iniciar sesión</Link>
                        </div>
                    </div>
                    <div className={"bg-image-pshb"} style={{ backgroundImage: `url(${loadedBgImage || ''})` }}>
                        <div className="bg-image-text" >
                            <div className="bg-image-text">
                                <div className="transbox">
                                    <h1>Bienvenido a mi HB Online</h1>
                                    <h5>Solicitá un turno, accedé a tus resultados, gestioná tu grupo familiar y tu perfil</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SocialMediaLink;
