import { Fab, ThemeProvider, createTheme } from "@mui/material";
import { useContext } from "react";
import ReactGA from "react-ga4";
import "./style.scss";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const WhatsappComponent = () => {
  const { userPSHB } = useContext(UserGlobalContextMemorySpace);

  const theme = createTheme({
    palette: {
      whatsapp: {
        main: "#00BB2D",
        contrastText: "#fff",
      },
    },
  });

  const accionWhatsapp = () => {
    ReactGA.event({
      category: `Acciones-Home-${userPSHB ? "PSHB" : "HB"}`,
      action: "click",
      label: "whatsapp",
    });
  };

  const boxSX = {
    "&:hover": {
      backgroundColor: "#00BB2D",
    },
  };

  return (
    <div style={{ position: "fixed", bottom: 10, right: 15, zIndex: 2 }}>
      <div id="contenedor-whatsapp-desktop">
        <a
          rel="noreferrer"
          target="_blank"
          onClick={() => accionWhatsapp()}
          href="https://wa.me/541130159749"
        >
          <ThemeProvider theme={theme}>
            <Fab
              color="whatsapp"
              sx={boxSX}
              variant="contained"
              aria-label="add"
            >
              <i
                class="bi bi-whatsapp"
                style={{ fontSize: 30, paddingTop: 1, paddingLeft: 1 }}
              ></i>
            </Fab>
          </ThemeProvider>
        </a>
      </div>
      <div id="contenedor-whatsapp-mobile">
        <a
          rel="noreferrer"
          target="_blank"
          onClick={() => accionWhatsapp()}
          href="https://wa.me/541130159749"
        >
          <ThemeProvider theme={theme}>
            <Fab
              color="whatsapp"
              sx={boxSX}
              variant="contained"
              aria-label="add"
            >
              <i
                class="bi bi-whatsapp"
                style={{ fontSize: 30, paddingTop: 1, paddingLeft: 1 }}
              ></i>
            </Fab>
          </ThemeProvider>
        </a>
      </div>
    </div>
  );
};

export default WhatsappComponent;
