import * as Yup from "yup";

const phoneRegex = /^([0-9]{0,4})[-. ]?([0-9]{0,8})$/;

//Schema Libro --
export const validateModel = Yup.object().shape({
  nombre: Yup.string().required("Campo obligatorio"),
  apellido: Yup.string().required("Campo obligatorio"),
  email: Yup.string().email("Email inválido").required("Campo obligatorio"),
  celular: Yup.string().required("Campo obligatorio"),
  motivo: Yup.string().required("Campo obligatorio"),
  mensaje: Yup.string().required("Campo obligatorio"),
  aceptoPrivacidad: Yup.bool().required("Campo obligatorio"),
  validateAceptoPrivacidad: Yup.string().when("aceptoPrivacidad", {
    is: false,
    then: Yup.string().required("Campo obligatorio"),
  }),
});

//Schema de contacto -- Puede que pidan campos extras, asi que separe del HB
export const validateModelPS = Yup.object().shape({
  nombre: Yup.string().required("Campo obligatorio"),
  apellido: Yup.string().required("Campo obligatorio"),
  email: Yup.string().email("Email inválido").required("Campo obligatorio"),

  //  celular: Yup.string().required('Campo obligatorio'),
  celular: Yup.string()
    .matches(phoneRegex, "Ingrese un numero de telefono")
    .required("Campo obligatorio"),

  motivo: Yup.string().required("Campo obligatorio"),
  mensaje: Yup.string().required("Campo obligatorio"),
  aceptoPrivacidad: Yup.bool().required("Campo obligatorio"),
  validateAceptoPrivacidad: Yup.string().when("aceptoPrivacidad", {
    is: false,
    then: Yup.string().required("Campo obligatorio"),
  }),
});

//Schema testing
export const validateModelTest = Yup.object().shape({
  nombre: Yup.string().required("Campo obligatorio"),
});
