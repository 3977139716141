import React,{useContext} from 'react';
import Logo1 from '../../../assets/img/logo1.jpg'
import Logo2 from '../../../assets/img/logo2.jpg'
import Logo3 from '../../../assets/img/logo3.png'
import Logo4 from '../../../assets/img/logo4.jpg'
import Logo5 from '../../../assets/img/logo5.jpg'
import Logo6 from '../../../assets/img/logo6.png'
import './style.scss'
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';

const LogosComponent = () => {
  const {footerConfiguration} = useContext(UserGlobalContextMemorySpace);
return(  
  <>
    <section style={{backgroundColor:"#ededed",height:"3vh"}}></section>
    <section className="section-logos bg-white">
    <div className="container">
      <div className="row pt-3 pb-3 pr-3 d-flex justify-content-center align-items-center">
        <div className="col-4 col-md text-center">
          <a href={footerConfiguration?.data_fiscal} target="_blank"><img src={Logo1} alt className="logosFooter" /></a>        
        </div>
        <div className="col-4 col-md text-center">
        <a href={footerConfiguration?.registro_nacional} target="_blank"><img src={Logo2} alt className="logosFooter" /></a>
        </div>
        <div className="col-4 col-md text-center">
        <a href={footerConfiguration?.itaes} target="_blank"><img id="itaes" src={Logo3} alt className="logosFooter" /></a>
        </div>
        <div className="col-4 col-md text-center">
        <a href={footerConfiguration?.red_global} target="_blank"><img src={Logo4} alt className="logosFooter" /></a>
        </div>
        <div className="col-4 col-md text-center">
        <a href={footerConfiguration?.consejo_argentino} target="_blank">
          <img src={Logo5} alt className="logosFooter" />
        </a>
        </div>
        <div className="col-4 col-md text-center">          
        <a href={footerConfiguration?.safe_travels}><img src={Logo6} alt className="logosFooter" id="safe-travel" /></a>
        </div>
      </div>
    </div>
  </section>
  </>
);
}
export default LogosComponent;