export const familyGroupFilterPSHB = (MergedFamilyGroup, PrestaciFamilyGroup) => {
    let pFamilyGroup = PrestaciFamilyGroup;
    try {

        if(pFamilyGroup.length > 0 && MergedFamilyGroup.length > 0){
            for(let i=0; i<pFamilyGroup.length; i++){
                let object = MergedFamilyGroup.filter((item)=>{return item.dni==pFamilyGroup[i].dni}) 
                if(object.length > 0){ 
                    pFamilyGroup[i] = object[0]
                }
            }
            return pFamilyGroup;
        }

        return [];
        
    } catch (err) {

        return [];
    }
};