import React from 'react'
import './grupo-menu.scss'
import Turnos from '../../../../assets/img/hb/turnos.svg'
import Resultados from '../../../../assets/img/hb/resultados.svg'
import Profesionales from '../../../../assets/img/hb/profesionales.svg'
import AtencionPaciente from '../../../../assets/img/hb/atencion-paciente.svg'
import { Link } from 'react-router-dom'

import Autorizaciones from '../../../../assets/img/pshb/autorizaciones.svg'
import Reintegros from '../../../../assets/img/pshb/reintegros.svg'
import DescargaFactura from '../../../../assets/img/pshb/descargar-facturas.svg'

const grupos = [
  { icono: Turnos, texto: 'Turnos' },
  { icono: Resultados, texto: 'Resultados' },
  { icono: Profesionales, texto: 'Profesionales y especialidades' },
  { icono: AtencionPaciente, texto: 'Atención al paciente' },
  
]

export const GrupoMenuComponent = () => {
  return (
    <div className='row g-0'>
      <div className=''>
        <div className='row g-0' id="contenedor-botones-pshb">
          {
            // grupos.map((item: { icono: any, texto: string }) =>
            //   //   <div className='col-6 col-lg-3 col-xl-3'>
            //   //   <div className='grupoMenuItem'>
            //   //     <img src={item.icono} className='grupoMenuIcono img-responsive' alt={item.texto} />
            //   //     <span className='grupoMenuTexto'>{item.texto}</span>
            //   //   </div>
            //   // </div>
            //   <div className='col-6 col-lg-3 col-xl-3'>
            //     <div className='grupoMenuItem'>
            //       <img src={item.icono} className='' alt={item.texto} />
            //       <span className=''>{item.texto}</span>
            //     </div>
            //   </div>
            // )                       
            
          }
           <div className='itemDown-pshb'>
           <Link to="/autorizaciones">
              <div className='grupoMenuItemPSHB'>
                <img src={Autorizaciones} className='grupoMenuIcono' style={{height:"45px"}} alt={"Autorizaciones"} />                
                <span className='grupoMenuTexto'>Autorizaciones</span>
              </div>
            </Link>

            </div>
            <div className='itemDown-pshb'>
              <Link to="/reintegro-estado">
              <div className='grupoMenuItemPSHB'>
                <img src={Reintegros} className='grupoMenuIcono' alt={"Reintegros"} />
                <span className='grupoMenuTexto'>Reintegros</span>
              </div>
              </Link>
            </div>            
            <div className='itemDown-pshb'>
            <Link to="/facturas">
              <div className='grupoMenuItemPSHB'>
                <img src={DescargaFactura} className='grupoMenuIcono' style={{height:"45px"}} alt={"Descarga-tu-Factura"} />
                <span className='grupoMenuTexto'>Descargá <br/> tu factura</span>
              </div>
            </Link>
            </div>            

        </div>
        {/* MOVIL */}
        {/* <div className="col">
        <div className="row d-block d-lg-none">
        <div className='col-12 col-sm-12 col-md-12'>
           <Link to="/autorizaciones">
              <div className='grupoMenuItemPSHB'>
                <img src={Autorizaciones} className='grupoMenuIcono' alt={"Autorizaciones"} />                
                <span className='grupoMenuTexto'>Autorizaciones</span>
              </div>
            </Link>

            </div>
            <div className='col-12 col-sm-12 col-md-12'>
              <Link to="/reintegro-estado">
              <div className='grupoMenuItemPSHB'>
                <img src={Reintegros} className='grupoMenuIcono' alt={"Reintegros"} />
                <span className='grupoMenuTexto'>Reintegros</span>
              </div>
              </Link>
            </div>            
            <div className='col-12 col-sm-12 col-md-12'>
            <Link to="/facturas">
              <div className='grupoMenuItemPSHB'>
                <img src={DescargaFactura} className='grupoMenuIcono' alt={"Descarga-tu-Factura"} />
                <span className='grupoMenuTexto'>Descargá <br/> tu factura</span>
              </div>
            </Link>
            </div>            
        </div>
        </div> */}
        {/* FIN MOVIL */}

      </div>
      {/* <div className="offset-xl-1"></div> */}
    </div>
  )
}

