import React, { useContext } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import classNames from "classnames";

export const TarjetaDatoCoberturaComponent = ({
  titulo,
  activo = false,
  puedeMinimizar = false,
  children,
  onVisibleTarjeta = () => null,
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  function IconoCollapse() {
    if (puedeMinimizar) {
      if (activo)
        return (
          <FiChevronUp
            role="button"
            className="ms-auto  tituloActivo"
            onClick={onVisibleTarjeta}
            size={30}
          />
        );
      else
        return (
          <FiChevronDown
            role="button"
            className="ms-auto  tituloInactivo"
            onClick={onVisibleTarjeta}
            size={30}
          />
        );
    } else return null;
  }

  return (
    <div
      className={classNames("tarjeta", {
        ["tarjeta--activa"]: activo,
        ["tarjeta--pshb"]: userPSHB,
      })}
    >
      <div
        className="d-flex justify-content-start align-items-center"
        onClick={onVisibleTarjeta}
        style={{ cursor: "pointer" }}
      >
        <h3 className={activo ? "tituloActivo" : "tituloInactivo"}>{titulo}</h3>
        <IconoCollapse />
      </div>

      {children}
    </div>
  );
};
