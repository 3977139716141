import React from "react";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import "./menu-secundario.scss";
import LogoPlanSalud from "../../../../assets/img/hb/logo-plan-salud-blanco.png";
import Logo from "../../../../assets/img/hb/logoHBInvertido.png";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export const MenuSecundario = ({ visible = false, onHide = () => null }) => {
  return visible ? (
    <div className="contenedorMenuSecundario">
      <div className="logo">
        <img src={Logo} />
      </div>
      <Navbar bg="" expand="lg" className="menu">
        <Container>
          <Navbar.Brand href="#home"></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/turnos" className="itemMenu">
                Turnos
              </Nav.Link>
              <Nav.Link as={Link} to="/resultados" className="itemMenu">
                Resultados
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/certificado-asistencia"
                className="itemMenu"
              >
                Certificado de asistencia
              </Nav.Link>
              <AiFillCloseCircle
                className="itemMenuC"
                onClick={onHide}
                style={{ marginTop: "15px" }}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  ) : null;
};
