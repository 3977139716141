import { initializeApp } from "firebase/app";
import { getAuth, signOut, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithCredential } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDlLfNDHLe-SW0u1nLkOmxE5CHIL8Ri-5c",
  authDomain: "hb-portaldepacientes.firebaseapp.com",
  projectId: "hb-portaldepacientes",
  storageBucket: "hb-portaldepacientes.appspot.com",
  messagingSenderId: "831355447813",
  appId: "1:831355447813:web:a99be915727eb9e68aca6c",
  measurementId: "G-25WWZMDGBM"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const providerGoogle = new GoogleAuthProvider();
export const providerFacebook = new FacebookAuthProvider();

export const logOut = () => {
  signOut(auth).then((data) => {
    console.log(data)
    alert("goodbye")
  }).catch((error) => {
    console.log(error)
    alert("error")
  });
}


export const signInWithGoogle = async () => {
  signInWithPopup(auth, providerGoogle)
    .then((result) => {
      const idToken = result._tokenResponse.idToken;

      // res = {data: {
      //   idToken: result._tokenResponse.idToken,
      //   email: result._tokenResponse.email
      // }}

    })
    .catch((error) => {
      // res = {error: error}
    });

    // return res;
};

export const signInWithFacebook = async () => {
  let res = await signInWithPopup(auth, providerFacebook)
    .then((result) => {

      const idToken = result._tokenResponse.idToken;

      res =  {data: {
        idToken: result._tokenResponse.idToken,
        email: result._tokenResponse.email
      }}

    })
    .catch((error) => {
      res = {error: error}
    });
  
  return res;
};