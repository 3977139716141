import Swal from 'sweetalert2';


export const SwalMessage = (icon, title, text, confirmButtonText) => {
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        color: '#224d7c',
        confirmButtonColor: '#224d7c',
        confirmButtonText: confirmButtonText
    })
}
