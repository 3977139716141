import React, { useContext } from "react";
import "./styleps.scss";
import Lupa from "../../../assets/img/lupa.png";
import Flag from "../../../assets/img/flag.png";
import Logo from "../../../assets/img/pshb/logo.png";
import Home from "../../../assets/img/home.svg";
import LogoPlanSalud from "../../../assets/img/logo-plan-salud-blanco.png";
import { Navbar, Container, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import {
  Link,
  useSearchParams,
  useNavigate,
  Route,
  Navigate,
  Redirect,
} from "react-router-dom";
import IcoWP from "../../../assets/img/Whatsapp.png";
import { LinkContainer } from "react-router-bootstrap";
import DrawerPSHB from "../drawer";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { fetchExternal } from "../../../utils/fetch";
import { linkClasses } from "@mui/material";
import { InformacionComponent as InformacionCredencial } from "../digital-credential/informacion";
import { InformacionComponent as InformacionFactura } from "../invoices/informacion";
import { InformacionComponent as InformacionGrupoFamiliar } from "../family-group/informacion";
import { InformacionComponent as InformacionReintegro } from "../refund/informacion";
import { useToggle } from "../../../hooks/useToggle";
import { myAxios } from "../../../utils/fetch";

const NavBarPSHB = ({ navigation }) => {
  const {
    setOpenHelper,
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    setUser,
  } = useContext(UserGlobalContextMemorySpace);

  const [linkFactura, setLinkFactura] = React.useState(null);
  const titular = user.user.data.Customers.socialSecurityNumber.split(" ")[1];
  // const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const socialSecurityNumber = user.user.data.Customers.socialSecurityNumber;

    linkPago(user.user.data.Customers.socialSecurityNumber.split(" ")[0])
      .then((result) => {
        //let factura = response.data.data.ds.slice(0, 1);
        // factura[0].link = result.data.data.link;
        if (!result.data.error) setLinkFactura(result.data?.data?.link);
      })
      .catch((err) => {
        // let factura = response.data.data.ds.slice(0, 1);
        // factura[0].link = null;
        // setFacturas(factura);
      });
  }, []);

  const linkPago = async (socialSecurityNumber) => {
    let url = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        `/patient/api/v1/pagos/getlinkpago?credentialNumber=${socialSecurityNumber}&idCustomer=${user.user.data.Customers.idCustomer}`,
      headers: {
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });

    return url;
  };

  const [info, setInfo] = React.useState(null);
  const [putSearch, setPutSearch] = React.useState(false);
  const [loader, setLoader] = React.useState(true);

  const credencialToggle = useToggle();
  const facturaToggle = useToggle();
  const grupoFamiliarToggle = useToggle();
  const reintegroToggle = useToggle();

  const ayudaCredencial = () => {
    credencialToggle.onToggle();
    //navigate("/credencial-digital")
  };

  const ayudaFacturacion = () => {
    facturaToggle.onToggle();
    //navigate("/facturas")
  };

  const ayudaReintegro = () => {
    // informacionToggle.onToggle();
    reintegroToggle.onToggle();
  };

  const ayudaGrupoFamiliar = () => {
    grupoFamiliarToggle.onToggle();
    //navigate("/facturas")
  };

  let navigate = useNavigate();

  const handleSesion = () => {
    navigate("/cerrar-sesion");
  };

  React.useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const { data } = await fetchExternal(
        `${process.env.REACT_APP_STRAPI_QA}/pagina-principals?idEmpresa=2`,
        "GET"
      );
      setInfo(data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const redirectToExternalUrl = () => {
    window.open(
      process.env.REACT_APP_PLAN_SALUD + "/formularios-utiles",
      "_blank"
    );
  };
  return (
    <>
      <InformacionCredencial
        key={Math.random()}
        visible={credencialToggle.toggle}
        onToggle={credencialToggle.onToggle}
      />
      <InformacionFactura
        key={Math.random()}
        visible={facturaToggle.toggle}
        onToggle={facturaToggle.onToggle}
      />
      <InformacionGrupoFamiliar
        key={Math.random()}
        visible={grupoFamiliarToggle.toggle}
        onToggle={grupoFamiliarToggle.onToggle}
      />
      <InformacionReintegro
        key={Math.random()}
        visible={reintegroToggle.toggle}
        onToggle={reintegroToggle.onToggle}
      />

      <nav className="web-navbar">
        <div className="d-none d-lg-block">
          <div className="grandientNavbar">
            <div className="grandientNavbar_element1">
              <span className="phoneText">
                Plan de Salud {info?.atencionTelefonica} Urgencias y emergencias{" "}
                {info?.centralTurno}
                <a
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                  target="_blank"
                  href="https://wa.me/541130159749"
                >
                  <img src={IcoWP} className="iconWP" />
                  {info?.whatsapp}
                </a>
              </span>
            </div>
            {/* <div className="grandientNavbar_element">
              {putSearch && (
                <input
                  type="text"
                  className="grandientNavbar_searchInput form-control"
                  placeholder="escribe tu búsqueda..."
                />
              )}
              <img
                src={Lupa}
                onMouseEnter={() => setPutSearch(true)}
                onMouseOut={() => setPutSearch(false)}
              />
              <span className="grandientNavbar_separator"></span>
              <span className="grandientNavbar_idioma">
                Idioma:{" "}
                <img className="grandientNavbar_idioma_icon" src={Flag} />
              </span>
            </div> */}
          </div>
        </div>
        <div className="d-none d-lg-block">
          <Navbar className="customNavbar" expand="lg">
            <Navbar.Brand className="customNavbar_brand">
              <Nav.Link
                as={Link}
                to="/"
                style={{ height: "100%" }}
                className="p-0"
              >
                <img
                  src={Logo}
                  alt="logo"
                  className="customNavbar_brand_logo-ps"
                />
              </Nav.Link>
              <div className="customNavbar_brand_logo_separator"></div>
              <div className="customNavbar_brand_button">
                <Nav.Link as={Link} to="/" style={{ marginBottom: "5px" }}>
                  <img
                    src={Home}
                    alt="home"
                    className="customNavbar_brand_button_home"
                  />
                </Nav.Link>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{ paddingRight: "50px" }}
            >
              <Navbar.Text>
                <div className="text-center saludoUsuarioPS">
                  ¡Hola {user.user.data.Customers.firstName}!
                </div>
              </Navbar.Text>

              <Nav className="ms-auto">
                <NavDropdown
                  title="Gestiones"
                  className="opcionMenu"
                  id="dropdownMenu"
                >
                  <NavDropdown.Item as={Link} to="credencial-digital">
                    <span className="opcionMenu2">Credencial digital</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="autorizaciones">
                    <span className="opcionMenu2">Autorizaciones</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="reintegro-estado">
                    <span className="opcionMenu2">Reintegros</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item onClick={redirectToExternalUrl}>
                    <span className="opcionMenu2">Formularios útiles</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="mecanismo-integracion">
                    <span className="opcionMenu2">Mecanismo Integración</span>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Facturación"
                  className="opcionMenu"
                  id="dropdownMenu"
                >
                  <NavDropdown.Item as={Link} to={"facturas"}>
                    <span className="opcionMenu2">Descargá tu factura</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to={"debito-automatico"}>
                    <span className="opcionMenu2">Débito automático</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />

                  {linkFactura ? (
                    <>
                      {user.user?.data?.Customers?.holder === "T" ? (
                        <NavDropdown.Item href={linkFactura} target="_blank">
                          <span className="opcionMenu2">Pagá tu factura</span>
                        </NavDropdown.Item>
                      ) : (
                        <NavDropdown.Item as={Link} to={"paga-factura"}>
                          <span className="opcionMenu2">Pagá tu factura</span>
                        </NavDropdown.Item>
                      )}
                    </>
                  ) : (
                    <NavDropdown.Item as={Link} to={"paga-factura"}>
                      <span className="opcionMenu2">Pagá tu factura</span>
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                <NavDropdown
                  title="Contacto"
                  className="opcionMenu"
                  id="dropdownMenu"
                >
                  <NavDropdown.Item as={Link} to="informacion-util">
                    <span className="opcionMenu2">Información útil</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="contacto">
                    <span className="opcionMenu2">Dejanos tu consulta</span>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  align="end"
                  title="Ayuda"
                  className="opcionMenu"
                  id="dropdownMenu"
                >
                  <NavDropdown.Item onClick={ayudaCredencial}>
                    {" "}
                    <span className="opcionMenu2">Credencial digital</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item onClick={ayudaFacturacion}>
                    <span className="opcionMenu2">Autogestión</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="/ayuda">
                    <span className="opcionMenu2">Soporte web</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item onClick={ayudaGrupoFamiliar}>
                    <span className="opcionMenu2">Grupo familiar</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item onClick={ayudaReintegro}>
                    <span className="opcionMenu2">Reintegros</span>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  align="end"
                  title="Perfil"
                  className="opcionMenu"
                  id="dropdownMenu"
                  alignLeft
                >
                  <NavDropdown.Item as={Link} to="perfil">
                    <span className="opcionMenu2">Datos personales</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="cambiar-contrasena">
                    <span className="opcionMenu2">Cambiar contraseña</span>
                  </NavDropdown.Item>
                  <Dropdown.Divider className="dropdownDivider" />
                  <NavDropdown.Item as={Link} to="cerrar-sesion">
                    <span className="opcionMenu2">Cerrar sesión</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <div className="d-block d-md-none">
          <Navbar className="" expand="sm">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="w-100">
              <Nav.Link
                as={Link}
                to="/"
                style={{ height: "100%" }}
                className="p-0"
              >
                <img
                  src={Logo}
                  alt="logo"
                  className="customNavbar_brand_logo"
                />
              </Nav.Link>
            </Navbar.Toggle>

            <Navbar.Collapse id="basic-navbar-nav">
              <Navbar.Text>
                <div className="text-center m-3 saludoUsuarioPS">
                  ¡Hola {user.user.data.Customers.firstName}!
                </div>
              </Navbar.Text>

              <Nav className="itemMenuMovil">
                <Nav.Link>
                  <span className="opcionMenu">Grupo familiar</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/perfil">
                  <span className="opcionMenu">Perfil</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/cambiar-contrasena">
                  <span className="opcionMenu">Cambiar Contraseña</span>
                </Nav.Link>
                <Nav.Link>
                  <span className="opcionMenu">Ayuda</span>
                </Nav.Link>
                <Nav.Link>
                  <span className="opcionMenuCerrarSesion">Cerrar sesión</span>
                </Nav.Link>
                <NavDropdown
                  title={
                    <img
                      src={LogoPlanSalud}
                      className="img-fluid"
                      alt="Logo plan salud"
                    />
                  }
                  className="w-100 logoPlanSaludMovil"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Mi plan</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Mi cartilla</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Facturación</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span className="opcionMenu2">Reintegros</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </nav>
      <nav
        className="mobile-navbar"
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
      >
        <div className="d-flex">
          <div
            className="col-4 d-flex align-items-center"
            style={{ paddingLeft: "7vw" }}
          >
            <DrawerPSHB />
          </div>
          <div className="col-4 d-flex justify-content-center">
            <Nav.Link
              as={Link}
              to="/"
              style={{ height: "100%" }}
              className="p-0"
            >
              <img src={Logo} alt="logo" className="customNavbar_brand_logo" />
            </Nav.Link>
          </div>
          <div className="col-4"></div>
        </div>
        {/* <div className='d-flex justify-content-center align-items-center'>
                    <DrawerHB></DrawerHB>
                    <img src={Logo} alt="logo" className="customNavbar_brand_logo" />
                </div> */}
      </nav>
    </>
  );
};

export default NavBarPSHB;
