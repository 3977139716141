import * as Yup from "yup";

export const validateModelFacturacion = Yup.object().shape({
  nombre: Yup.string().required("Ingrese su nombre"),
  apellido: Yup.string().required("Ingrese su apellido"),
  email: Yup.string().email("Email inválido").required("Ingrese su correo"),
  celular: Yup.string()
    .required("Ingrese su número telefónico")
    .min(2, "No puede contener solo 1 caracter"),
  localidad: Yup.string()
    .required("Ingrese su localidad")
    .min(2, "No puede contener solo 1 caracter")
    .max(50, "La localidad debe tener máximo 50 caracteres"),
  opcion: Yup.string().required("Seleccione una opción"),
  comentario: Yup.string().required("Escriba un comentario"),
  aceptoPrivacidad: Yup.bool().required(""),
  validateAceptoPrivacidad: Yup.string().when("aceptoPrivacidad", {
    is: false,
    then: Yup.string().required("Campo obligatorio"),
  }),
});
