import React, {useContext, useState, useEffect, useRef} from 'react';
import { UserGlobalContextMemorySpace } from '../../contexts/user/userGlobalContext';
import Swal from 'sweetalert2';
import axios from 'axios';
import Overlay from '../../components/common/overlay';
import SocialMediaModal from '../../components/common/socialmedia-loginmodal';
import jwtDecode from "jwt-decode";
import styles from './Google.module.scss';
import ReactGA from "react-ga4";


// https://developers.google.com/identity/gsi/web/reference/js-reference

const GoogleAuthButton = (props) => {

    const widthGoogle = useRef(null)

    const [loaderGoogle, setLoaderGoogle] = useState(false);
    const [decodedJWT, setDecodedJWT] = useState("");
    const [appToken, setAppToken] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser, socialMediaModal, setSocialMediaModal, socialMediaData, setSocialMediaData} = useContext(UserGlobalContextMemorySpace);

    const SwalMessage = (allowOutsideClick, icon, title, text) => {
        Swal.fire({
            allowOutsideClick: allowOutsideClick,
            icon: icon,
            title: title,
            text: text,
            color: '#224d7c',
            confirmButtonColor: '#224d7c',
            confirmButtonText: 'Continuar'
        })
    }


    const handleCallbackResponse = async (response) => {             
        setLoaderGoogle(true)        
        
        if(response.credential){
        //   console.log("JWT ID TOKEN: ", response.credential)    
          
            let decoded = await axios.get(`https://oauth2.googleapis.com/tokeninfo?id_token=${response.credential}`)

            let date = new Date().getTime()+60*60000
            localStorage.clear();

            /* VALIDACION EN EL BACK */
            axios({
                url: process.env.REACT_APP_STRAPI_QA+process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN_GOOGLEAUTH,
                method: 'POST',
                data: {idToken: response.credential, pshb: true}
            })
            .then((response)=>{
                setLoaderGoogle(false)
                if(response.data.error){
                    SwalMessage(false, 'warning', '<h5 style="color: #224d7c"></h5>', 'Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña.')
                }else{
                    if(response.data.result.responseCode == 200){
                        if(props.pshb){

                            if(response.data.result.data.Customers.pshb){
                                setUserPSHB(props.pshb);
                                setUser({user: response.data.result})
                                localStorage.setItem("enc", response.data.result.data.enc);
                                localStorage.setItem("enc_session", date)
                            }else{
                                Swal.fire({
                                    allowOutsideClick: false,
                                    icon: "info",
                                    title:
                                      '<h5 style="color: #224d7c">Plan de Salud no encontrado.</h5>',
                                    text: "No encontramos un Plan de Salud del Hospital Britanico asociado a la casilla de mail indicada, comunicate con nuestro equipo de ventas para solicitar un Plan.",
                                    color: "#224d7c",
                                    confirmButtonColor: "#224d7c",
                                    confirmButtonText: "Ventas",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                      window.location =
                                        "https://promociones.hbritanico.com.ar/";
                                    }
                                });
                            }
                        }else{
                            //console.log("response.data.result", response.data.result.data.Customers.pshb)
                            setUserPSHB(props.pshb);
                            setUser({user: response.data.result})
                            localStorage.setItem("enc", response.data.result.data.enc);
                            localStorage.setItem("enc_session", date)
                        }
                    }else if(response.data.result.responseCode == 400 && response.data.result.data.type == "SocialMediaAccountNotLinked"){
                        setSocialMediaData({
                            newEmail: decoded.data.email,
                            appToken: response.data.result.data.appToken,
                            socialMedia: "Google"
                        })
                        setSocialMediaModal(true)
                        // setNewEmail(decoded.data.email)
                        // setAppToken(response.data.result.data.appToken)

                    }
                    else if(response.data.result.responseCode == 400){
                        SwalMessage(false, 'warning', '<h5 style="color: #224d7c"></h5>', response.data.result.message)
                    }else{
                        SwalMessage(false, 'warning', '<h5 style="color: #224d7c"></h5>', 'Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña.')
                    }

                }
            })
            .catch((error)=>{
                setLoaderGoogle(false)
                SwalMessage(false, 'warning', '<h5 style="color: #224d7c"></h5>', 'Detectamos un problema en el inicio de sesión con Google, por favor intenta con tu usuario y contraseña.')
            }) 

        }else{
            setLoaderGoogle(false)

        }
    }
      
    // function onClickHandler(){
    //     console.log("Sign in with Google button clicked...")
    // }

    function eventoGoogle(){
        ReactGA.event({
            category: "Acciones de Sesión con Google",
            action: "Inicio de Sesión con Google",
            label: "Google"                   
          });
    }

    useEffect(()=>{                

        if (!window.google) return
        /* global google */
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCallbackResponse,          
        })
    
        google.accounts.id.renderButton(
          document.getElementById("signInDiv"),
        { theme: "filled_blue", size: "large" , text: "continue_with", shape: "pill",  	
        width: 280, logo_alignment: "left",click_listener:()=>eventoGoogle()}
        //{type: "icon", shape: "pill", click_listener: onClickHandler }

        )

        // function onClickHandler(){
        //     console.log("Sign in with Google button clicked...")
        // }

        // var loginButton = document.getElementById('signInDiv');
        // loginButton.classList.add("tester");
        // loginButton.style.paddingLeft = '30px';
        // loginButton.style.backgroundColor = '#4285F4';
        // loginButton.style.width = '400px';
        // loginButton.style.height = '50px';
        // loginButton.style.borderRadius = '20px';                
        
    }, [widthGoogle])


    return (
        <>
            {loaderGoogle ? <Overlay/> : null}
            {/* <div className="button-div d-flex justify-content-center"> */}
            <SocialMediaModal newEmaiL={newEmail} socialMedia={"google"} appToken={appToken}/>
            {/* <button onClick={()=>console.log(widthGoogle.current.clientWidth)}>test</button> */}
            <div ref={widthGoogle} className={`d-flex justify-content-center align-items-center shadow-blue ${styles.signInDiv} ${styles.inputGoogle}`} style={{}}>
                {loaderGoogle ? 
                    <div style={{marginLeft: "0px", height: "4.5vh"}} className="d-flex align-items-center">
                        <span style={{color: "white"}} className="spinner-grow spinner-grow-sm"></span>
                        <span style={{color: "white"}} className="spinner-grow spinner-grow-sm"></span>
                        <span style={{color: "white"}} className="spinner-grow spinner-grow-sm"></span>
                    </div>
                    :
                    <></>                
                }
                {/* <div className={`${styles.signInDiv} ${styles.inputGoogle} shadow-blue`} id="signInDiv" style={loaderGoogle ? {display:"none"}: {}}></div> */}
                <div id="signInDiv" style={loaderGoogle ? {display:"none"}: {}}></div>

            </div>
        </>
    );
}

export default GoogleAuthButton;
