import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';
import "./calendar.scss";

const CalendarModal = (props) => {

    const date = new Date();
    // const [minYear, setMinYear] = useState(date.setFullYear(date.getFullYear() - 1))
    // console.log(date)

    const [show, setShow] = useState(false);

    const handleClose = () => {
        //props.setShowModal(false);
        setShow(false)
        props.setShowModal(false);
        };
    const handleShow = () => setShow(true);

    const fullDate = (date) =>{
        let day = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
        let month = date.getUTCMonth()+1 < 10 ? `0${date.getUTCMonth()+1}` : date.getUTCMonth()+1;
        let year = date.getFullYear();
        props.setFechaNac(`${day}-${month}-${year}`)
        props.setShowModal(false);

        //handleClose()
    }

    useEffect(()=>{

        setShow(props.showModal)
        
    }, [props.showModal])

    return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose} centered size="sm" backdrop="static">
        <Modal.Header>
          <Modal.Title>Fecha de nacimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Calendar 
                minDate={new Date(`${date.getFullYear()-110}-01-01`)}
                maxDate={date}     
                onClickDay={(value, event) => fullDate(value)}
                locale="es-ES"
            />  
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CalendarModal;
