
import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import '../invoices.scss';
import UserSidebar from '../../../../components/common/user-sidebar';
import AddRefundComponent from '../../../../components/pshb/add-refund';




  const ReintegrosSolicitud = () => {
    const [routes, setRoutes] = useState({routes: [{route: "/reintegro-estado", name:"Estado"},{route: "/reintegro-solicitud", name:"Solicitud"}]})
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
  
    useLayoutEffect(() => {
        setHeight(ref.current.clientHeight);
      }, []);


  return (    
   
      <div className='d-flex help-page' ref={ref}>
         <UserSidebar routes={routes} height={height} ></UserSidebar>
          <AddRefundComponent> </AddRefundComponent>
          </div>      
        
    
  )
}

export default ReintegrosSolicitud;