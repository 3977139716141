import './dato-personal.scss'
import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import React , {useContext} from 'react'
import { UserGlobalContextMemorySpace } from '../../../../contexts/user/userGlobalContext';



export const DatoPersonalComponent = ({
    activo = false,
    editando = false,
    onEditando = () => null
}) => {

     const {sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser} = useContext(UserGlobalContextMemorySpace);

     const [claseCampo, setClaseCampo] = React.useState('')

     const dni_types=["DNI","CI","PA","LC"];

     const gender_types={"M":"MUJER","H":"HOMBRE"};

    React.useEffect(() => {

        // console.log(user);
        if (editando)
            setClaseCampo('campo editandoCampo')
        else
            setClaseCampo('campo')

    }, [editando])

    return (
        <div className={activo ? 'formActivo' : 'formInactivo'}>

            {/* LINEA SUPERIOR */}
            <div className={activo ? 'lineaCabActiva' : userPSHB? 'lineaCabInactivaPSHB' : 'lineaCabInactiva'} />


            {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}
            {/* <div className='botones'>
                {!editando && <button className='botonEditar' onClick={() => activo ? onEditando() : null}><FiEdit2 /> Editar</button>}
                {editando &&
                    <>
                        <button className='botonEditar' onClick={onEditando}><FiCheck /> Confirmar</button>
                        <button className='botonCancelar' onClick={onEditando}><FiX /> Cancelar</button>
                    </>}
            </div> */}

            {/* CAMPOS DE FORMULARIOS */}
            <div className={'d-flex flex-column ' + claseCampo}>
                <div><span className='perfil-etiqueta'>Nombre:</span><span className='perfil-valor'>{user.user.data.Customers.firstName}</span></div>
                <div><span className='perfil-etiqueta'>Apellido:</span><span className='perfil-valor'>{user.user.data.Customers.lastName}</span></div>
            </div>

            <div className={activo ? 'lineaActiva' : 'lineaInactiva'} />
            <div className={'campo-perfil-numero ' + claseCampo}>
                <div><span className='perfil-etiqueta'>Tipo:</span><span className='perfil-valor'>{dni_types[user.user.data.Customers.dniType]}</span></div>
                <div><span className='perfil-etiqueta'>Número:</span><span className='perfil-valor'>{user.user.data.Customers.dni}</span></div>
            </div>

            <div className={activo ? 'lineaActiva' : 'lineaInactiva'} />
            <div className={claseCampo} id="container-date-birth"><span className='perfil-etiqueta'>Fecha de nacimiento:</span><span className='perfil-valor'>{user.user.data.Customers.birthday}</span></div>

            {/* <div className={activo ? 'lineaActiva' : 'lineaInactiva'} />
            <div className={claseCampo}><span>Estado civil:</span>Casado</div> */}
            {!userPSHB?
            <>
                <div className={activo ? 'lineaActiva' : 'lineaInactiva'} />
                <div className={claseCampo}><span className='perfil-etiqueta'>Género:</span><span className='perfil-valor'>{gender_types[user.user.data.Customers.sex]}</span></div>
            </>
            :<></>}
        </div>
    )
}
