import { useState } from "react";

const useIsApple = () => {
  const validIsAppleDevice = () => {
    return !!navigator.platform.match(/iPhone|iPod|iPad/);
  };

  const [ios] = useState(validIsAppleDevice());

  return {
    ios,
  };
};

export default useIsApple;
