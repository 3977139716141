import "./results.scss";
import UserSidebar from "../../../components/common/user-sidebar";
import ResultsComponent from "../../../components/hb/results";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";

const Results = () => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/resultados", name: "Resultados" },
      { route: "/certificado-asistencia", name: "Certificado de asistencia" },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <ResultsComponent> </ResultsComponent>
    </div>
  );
};

export default Results;
