import { Col, Row } from 'react-bootstrap'
import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import UserSidebar from '../../../components/common/user-sidebar';
import FamilyGroupComponent from '../../../components/pshb/family-group';
import style from './style.scss'
import { useLocation } from 'react-router-dom';




const FamilyGroupPSHB = (props) => {
  const [routes, setRoutes] = useState({routes: [{route: "/perfil", name:"Datos personales"}, ,{route: "/grupo-familiar", name:"Grupo familiar"},{route: "/credencial-digital", name:"Credencial digital"}]})
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const location = useLocation();  


  useLayoutEffect(() => {
      setHeight(ref.current.clientHeight);
    }, []);

  
  return (
    <div className='d-flex help-page' ref={ref}>
        <UserSidebar routes={routes} height={height} ></UserSidebar>
        <div className='d-flex flex-column' style={{width: "100%", position: "relative"}}>
          <div className='family-group-pshb'>
            <FamilyGroupComponent visible={location.state?.visible ?  location.state?.visible : false}/>
          </div>
          <div className='footer-pshb-large' style={{position: "absolute", bottom: "0"}}></div>

        </div>
    </div>      

      
    
     
  )
}

export default FamilyGroupPSHB;