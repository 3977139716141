function isValidDateFormat(dateString) {
    var pattern = /^\d{2}-\d{2}-\d{4}$/;
    return pattern.test(dateString);
}

function parseDate(dateString) {
    // Split the input string into day, month, and year parts
    const parts = dateString.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; 
    const year = parseInt(parts[2], 10);
  
    // Create a new Date object using the parsed values
    const dateObject = new Date(year, month, day);
  
    return dateObject;
  }

function isOver13(dateString) {

    let birthday = parseDate(dateString);

    const currentDate = new Date();

    const eighteenYearsAgo = new Date(currentDate.getFullYear() - 13, currentDate.getMonth(), currentDate.getDate());

    return birthday <= eighteenYearsAgo;

  }

  export default isOver13;
