import React, { FC, useContext } from "react";
import DownloadIcon from "../../../assets/img/descargar.png";
import "./style.scss";
import "./index.scss";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import axios from "axios";
import { myAxios, fetchLogs } from "../../../utils/fetch";

import { Tooltip as ReactTooltip } from "react-tooltip";

const CertificadosDeAsistenciaComponentPSHB = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [loader, setLoader] = React.useState(true);
  const [results, setResults] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const controller = new AbortController();

  const siguiente = () => {
    setOffset(offset + limit);
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset < 0) {
      setOffset(0);
    } else {
      setOffset(temporalOffset);
    }
  };

  const descargar = (resultado) => {
    const payLoad = {
      doc: resultado.requestObject,
      idSap: user.user.data.Customers.idSap,
      idCustomer: user.user.data.Customers.idCustomer,
    };

    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/getattendancecertificatefile",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      params: payLoad,
    })
      .then((response) => {
        // fetchLogs({
        //   idLogType: 1,
        //   idType: 24,
        //   description:
        //     "El usuario " +
        //     user.user.data.Customers.lastName +
        //     " " +
        //     user.user.data.Customers.firstName +
        //     " descargó el documento " +
        //     resultado.name +
        //     " de tipo " +
        //     resultado.type,
        // });

        const linkSource = `data:application/pdf;base64,${response.data.data.file}`;
        const downloadLink = document.createElement("a");
        const fileName = resultado.name + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const obtenerResultados = () => {
    setResults([]);
    setLoader(true);
    const payLoad = {
      idSap: user.user.data.Customers.idSap,
      limit: limit,
      offset: offset,
      idCustomer: user.user.data.Customers.idCustomer,
    };

    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/getattendancecertificates",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      params: payLoad,
    })
      .then((response) => {
        // console.log("RESPONSE",response.data.data);
        setResults(response.data.data.certificates);
        setTotal(response.data.data.total);
        setLoader(false);
        // console.log("OFFSET:",offset,"RESULTS:",response.data.data.Results.length,"TOTAL:",response.data.data.total);
      })
      .catch((error) => {
        setResults([]);
        setLoader(false);
      })
      .finally(() => {});
  };

  React.useEffect(() => {
    obtenerResultados();
  }, []);

  React.useEffect(() => {
    obtenerResultados();
  }, [offset]);

  return (
    <div className="family-group-pshb ">
      <section className=" " style={{ height: "80vh" }}>
        <div className="container p-4">
          {/* TITULO */}
          <div className="row mt-5">
            <div className="col-lg-12">
              <h2 className="section_hb_title">
                <b>Mis certificados de Asistencia</b>
              </h2>
              <p className="section_hb_subtitle">
                En esta sección podras descargar el certificado de asistencia.
              </p>
            </div>
          </div>
          {/* SEPARADOR */}
          <div className="separador-table-red-result"></div>
          {/* TABLA */}
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table
                  className="table table-hb table-pshb-result"
                  style={{ color: "#05294F" }}
                >
                  <thead>
                    <tr>
                      <th style={{ fontSize: "20px" }}>Fecha y hora</th>
                      <th style={{ fontSize: "20px" }}>Tipo de Documento</th>
                      <th style={{ fontSize: "20px" }}>Servicio</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loader ? (
                      results.map((result) => {
                        return (
                          <tr key={result.date}>
                            <td>{result.date}</td>
                            <td>{result.type}</td>
                            <td>{result.name}</td>
                            <td>
                              <ReactTooltip id="tooltip-dowload-cert" />
                              <img
                                className="w-25 pointer"
                                onClick={() => descargar(result)}
                                src={DownloadIcon}
                                alt="..."
                                data-tooltip-id="tooltip-dowload-cert"
                                data-tooltip-content="Descargar certificado"
                              ></img>{" "}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="5" style={{ textAlign: "center" }}>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* SEPARADOR */}
          <div className="separador-table-red-result"></div>
          {total > 0 ? (
            <div id="paginate">
              {(offset > 0) & !loader ? (
                <div
                  className="btn btn-hb-primary-without-border shadow-blue"
                  onClick={anterior}
                >
                  Anterior
                </div>
              ) : (
                <></>
              )}
              {!loader ? (
                <span className="number-hb-paginate">
                  <a href="#">{offset / 5 + 1}</a>
                </span>
              ) : (
                <></>
              )}
              {(offset + results.length < total) & !loader ? (
                <div
                  className="btn btn-hb-primary-without-border shadow-blue"
                  onClick={siguiente}
                >
                  Siguiente
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="footer-hb-small"></div>
      </section>
    </div>
  );
};

export default CertificadosDeAsistenciaComponentPSHB;
