import React, { useContext } from 'react';
import ViewActiveIcon from '../../../assets/img/ver-activo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCalendar } from '@fortawesome/free-solid-svg-icons'
import "./historial-debito.scss";
import { useToggle } from "../../../hooks/useToggle";
import InformationIcon from "../../../assets/img/information.png";
import axios from "axios";
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import { myAxios } from '../../../utils/fetch';
import useConstructor from '../../../utils/hooks/useConstructor';
import { Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { TarjetaHistorialComponent } from './tarjeta-historial';
import { HistorialGrupoComponent } from './historial-grupo-component';


const HistorialDebitoComponent = () => {

    const date = new Date();
    const fecha_hasta = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
    date.setMonth(date.getMonth() - 6);
    const fecha_desde = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");


    const refundViewToggle = useToggle();
    const informacionToggle = useToggle();
    const [results, setResults] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [search, setSearch] = React.useState(null);
    const [loader, setLoader] = React.useState(true);
    const [limit, setLimit] = React.useState(5);
    const [offset, setOffset] = React.useState(0);
    const [desde, setDesde] = React.useState(fecha_desde);
    const [hasta, setHasta] = React.useState(fecha_hasta);

    const [searchParams, setSearchParams] = useSearchParams({});
    const openDialogParams = searchParams.get("open");
    const [debitoVisible, setDebitoVisible] = React.useState("");




    //const [reintegro,setReintegro]=React.useState();

    const { user, setReintegro } = useContext(UserGlobalContextMemorySpace);

    const controller = new AbortController();

    const obtenerResultados = (contrarestar) => {

        //setResults([]);        
        setLoader(true);


        myAxios({
            signal: controller.signal,
            url: process.env.REACT_APP_GATEWAY_QA + "/psafiliates/api/v1/consultarlogdebitoporsocio?SocialSecurity=" + user.user.data.Customers.socialSecurityNumber + "&limit=" + limit + "&offset=" + offset,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "USERTOKEN": user.user.data.Token,
                "Authorization": `Bearer ${user.user.data.appToken}`
            },
        }).then((response) => {
            //console.log("RESPONSE",response);            
            setResults(response.data.data.ds);
            setTotal(response.data.data.total);
            setLoader(false);
        }).catch((error) => {
            setResults([]);
            setLoader(false)
        }).finally(() => {

        });


    }

    // React.useEffect(()=>{                        
    //     //obtenerResultados();
    // },[offset]);

    useConstructor(() => {
        obtenerResultados(offset);
    });


    const convertirNumero = (numero) => {

        if (numero == null)
            return "";


        if (!numero.includes("*")) {
            var digitosFinales = numero.toString().slice(-2);
            return `****${digitosFinales}`;
        }

        return numero.toString().slice(-6);
    }

    const siguiente = () => {
        setOffset(offset + limit);
        obtenerResultados(offset + limit);
    }

    const anterior = () => {
        const temporalOffset = offset - limit;
        if (temporalOffset < 0) {
            setOffset(0);
            obtenerResultados(0);
        } else {
            setOffset(temporalOffset);
            obtenerResultados(temporalOffset);
        }
    }

    const seleccionarFecha = (e) => {

        let fechaSplit = e.target.value.split("-");
        let anoSplit = parseInt(fechaSplit[0]);

        if (fechaSplit?.length === 3) {
            if (anoSplit > 2100) {
                e.target.value = "1900-" + fechaSplit[1] + "-" + fechaSplit[2];
            }
        }

        if (e.target.name === "desde") {
            setDesde(e.target.value);
        } else {
            setHasta(e.target.value);
        }
    }

    // React.useEffect(()=>{

    // },[desde,hasta])

    React.useEffect(() => {
        // console.log("INICIANDO");
    }, []);

    React.useEffect(() => {
        if (openDialogParams == '1') {
            informacionToggle.onToggle()
        }
    }, [])




    const llamar = (index) => {
        setDebitoVisible((previus) => (previus === index ? "" : index));
    };





    return (
        <>

            <section className=" ">
                <div className="container p-4 d-none d-lg-block">
                    {/* TITULO */}
                    <div className="row mt-1">
                        <div className="col-lg-12" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h2 className="section_pshb_title">
                                    <b>Historial</b>
                                </h2>

                                <h5 className="section_pshb_subtitle">
                                    Desde esta opción puede observar todas sus solicitudes de débito automático
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="separador-table-green-result"></div>

                    <div>
                        <div className="g-0">

                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <table className="table table-pshb">
                                            <thead>
                                                <tr>
                                                    <th>N° ID de trámite</th>
                                                    <th>Débito automático</th>
                                                    <th>Tipo de tramite</th>
                                                    <th>Tarjeta o CBU</th>
                                                    <th>Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr>
                                            <td>1</td>
                                            <td>No</td>                                        
                                            <td>Aprobado</td>                                        
                                            <td>2022-08-09</td>                                        
                                            <td>
                                                <img className="i-25" src={ViewActiveIcon} onClick={refundViewToggle.onToggle} alt="..."></img>                                                 
                                            </td>
                                        </tr>      */}

                                                {!loader ? results && results.map((result) => {
                                                    return (<tr key={result.rein}>
                                                        <td>{result.guid}</td>
                                                        <td>{result.tipoDebito == "cuenta" ? "En cuenta" : "En tarjeta"}</td>
                                                        <td>{result.tipoOperacion}</td>
                                                        <td>{convertirNumero(result.cbu || result.numeroTarjeta)}</td>
                                                        <td>{result.fecha.split(" ")[0]}</td>
                                                    </tr>)
                                                }) :
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: "center" }}>
                                                            <span className="spinner-grow spinner-grow-sm"></span>
                                                            <span className="spinner-grow spinner-grow-sm"></span>
                                                            <span className="spinner-grow spinner-grow-sm"></span>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>



                            {total > 0 ?
                                <div id="paginate">
                                    {(offset > 0 & !loader) ? <div className="btn btn-pshb-primary shadow-blue" onClick={anterior}>Anterior</div> : <></>}
                                    {!loader ? <span className='number-pshb-paginate'><a href="#">{(offset / 5) + 1} de {Math.ceil(total / limit)}</a></span> : <></>}
                                    {(offset + results?.length < total & !loader) ? <div className="btn btn-pshb-primary shadow-blue" onClick={siguiente}>Siguiente</div> : <></>}
                                </div>
                                : <></>}



                        </div>
                    </div>

                </div>

                <div className="d-md-none d-block d-md-none container p-4">

                <div className="row mt-5">
                        <div className="col-lg-12" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h2 className="section_pshb_title">
                                    <b>Historial</b>
                                </h2>

                                <h5 className="section_pshb_subtitle">
                                    Desde esta opción puede observar todas sus solicitudes de débito automático
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="separador-table-green-result"></div>


                    {results?.map((result, index) => {
                        return (
                            <TarjetaHistorialComponent
                                key={index}
                                titulo={result.guid}
                                activo={false}
                                puedeMinimizar={true}
                                onVisibleTarjeta={(_) => llamar(index)}
                            >
                                <HistorialGrupoComponent
                                    data={result}
                                    activo={true}
                                    editando={false}
                                    eliminar={() => null}
                                    anadir={() => null}
                                />
                            </TarjetaHistorialComponent>
                        );
                    })}

                {total>0?
                <div id="paginate" style={{display:"flex",justifyContent:"center"}}>
                    {(offset>0 & !loader) ? <div className="btn btn-pshb-primary shadow-blue" onClick={anterior}>Anterior</div>:<></>}
                    {!loader ? <span className='number-pshb-paginate'><a href="#">{(offset/5)+1} de {Math.ceil(total/limit)}</a></span>:<></>}
                    {(offset+results?.length<total & !loader)?<div className="btn btn-pshb-primary shadow-blue" onClick={siguiente}>Siguiente</div>:<></>}
                </div>
                :<></>}
                </div>
                <div className='footer-pshb'>

                </div>
            </section>

        </>
    )
}

export default React.memo(HistorialDebitoComponent);   