import React, { useContext, useLayoutEffect } from "react";
import ViewIcon from "../../../assets/img/ver-activo.png";
import ViewIconDisabled from "../../../assets/img/ver-inactivo.png";

import MailIcon from "../../../assets/img/mail-activo.png";
import MailIconDisabled from "../../../assets/img/mail-inactivo.png";
// import ShareIcon from '../../../assets/img/compartir-activo.png'
import DownloadIcon from "../../../assets/img/descargar-activo.png";
import DownloadIconDisabled from "../../../assets/img/descargar-inactivo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./results.scss";
import CustomSelect from "../../common/customSelect";
import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import {
  CUSTOM_SELECT_COLOR_BLUE,
  CUSTOM_SELECT_COLOR_WHITE,
  CUSTOM_SELECT_COLOR_RED,
} from "../../common/customSelect/constants";

import ReactGA from "react-ga4";

import { useToggle } from "../../../hooks/useToggle";
import {
  ModalEmailComponent as EmailComponentSuccess,
  ModalEmailComponent as EmailComponentError,
} from "./email";
import { myAxios, fetchLogs } from "../../../utils/fetch";
import { InputGroup } from "react-bootstrap";

import { Tooltip as ReactTooltip } from "react-tooltip";

const ResultsComponent = () => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [familyGroupList, setFamilyGroupList] = React.useState(null);
  const [year, setYear] = React.useState("Todos");
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [service, setService] = React.useState("all");
  const [idSap, setIdSap] = React.useState(user.user.data.Customers.idSap);
  const [customers, setCustomers] = React.useState([user.user.data.Customers]);
  const [results, setResults] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [familyItem, setFamilyItem] = React.useState(
    user.user.data.Customers.firstName + " " + user.user.data.Customers.lastName
  );
  const [serviceSelected, setServiceSelected] = React.useState(
    "Todos los resultados"
  );
  const [controllerAxios, setControllerAxios] = React.useState();

  const [closeAno, setCloseAno] = React.useState(false);
  const [closeFamily, setCloseFamily] = React.useState(false);
  const [closeService, setCloseService] = React.useState(false);

  const emailToggleSuccess = useToggle();
  const emailToggleError = useToggle();

  // useLayoutEffect(() => {
  //   fetchLogs({
  //     idLogType: 1,
  //     idType: 18,
  //     description:
  //       "El usuario " +
  //       user.user.data.Customers.lastName +
  //       " " +
  //       user.user.data.Customers.firstName +
  //       " accedió al área de laboratorio",
  //   });
  // }, []);

  const obtenerResultados = (
    param_idSap,
    param_limit,
    param_offset,
    param_service,
    param_search,
    param_year
  ) => {
    setResults([]);
    setLoader(true);
    const payLoad = {
      dni: user.user.data.Customers.dni,
      idSap: param_idSap,
      limit: param_limit,
      offset: param_offset,
      service: param_service,
      search: param_search.trim(),
      idCustomer: user.user.data.Customers.idCustomer,
    };

    if (param_year !== "Todos") {
      payLoad.year = param_year;
    }

    let controller = new AbortController();
    setControllerAxios(controller);

    let endpoint =
      payLoad.service == "Otros Informes"
        ? "/patient/api/v1/results/laboratoryresultsother"
        : payLoad.service == "Medicina Nuclear"
        ? "/patient/api/v1/results/laboratoryresultsimg"
        : payLoad.service == "Diagnóstico por Imágenes"
        ? "/patient/api/v1/results/laboratoryresultsimg"
        : payLoad.service == "Laboratorio"
        ? "/patient/api/v1/results/laboratoryresultslab"
        : "/patient/api/v1/results/laboratoryresultsall";

    myAxios({
      signal: controller.signal,
      url: `${process.env.REACT_APP_GATEWAY_QA + endpoint}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: payLoad,
    })
      .then((response) => {
        setTotal(0);
        setLoader(false);
        setResults(response.data.data.Results);
        if (response.data.data.total > 0) {
          setTotal(response.data.data.total);
        }
        // console.log("OFFSET:",offset,"RESULTS:",response.data.data.Results.length,"TOTAL:",response.data.data.total);
      })
      .catch((error) => {
        setResults([]);
        //setLoader(false)
        // console.log("ERRRRR",error);
      })
      .finally(() => {
        //setLoader(false)
      });
  };

  const onChangeYear = (id, name, text) => {
    setYear(id);
    setOffset(0);
    obtenerResultados(idSap, limit, 0, service, search, id);
  };

  const clickYear = () => {
    ReactGA.event({
      category: "Acciones-Resultados",
      action: "click",
      label: "anio-estudios",
    });
    setCloseFamily(true);
    setCloseService(true);
    setCloseAno(false);
  };

  React.useEffect(() => {
    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/familiargroup/getfamiliargroupbyid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    })
      .then((response) => {
        const familyGroup = response.data.data.FamilyGroup.map((family) => {
          return { id: family.idSap ?? family.idTemp, name: family.fullName };
        });
        var temporalFamilyGroup = [
          {
            id: user.user.data.Customers.idSap,
            name:
              user.user.data.Customers.firstName +
              " " +
              user.user.data.Customers.lastName,
          },
          ...familyGroup,
        ];
        // console.log("TEMPORALFAMILYGROUP",temporalFamilyGroup);
        setCustomers([...customers, ...response.data.data.FamilyGroup]);
        setFamilyGroupList(temporalFamilyGroup);
      })
      .catch((error) => {})
      .finally(() => {
        obtenerResultados(idSap, limit, offset, service, search, year);
      });

    //obtenerResultados();
  }, []);

  React.useEffect(() => {
    //console.log("VA A BUSCAR RESULTADOS");
    //obtenerResultados();
  }, [service, year, idSap, search, offset]);

  React.useEffect(() => {
    setOffset(0);
  }, [service, year, idSap, search]);

  //var defaultSelectText="Todos los resultados";
  var estudiosList = [
    { id: "all", name: "Todos los resultados" },
    { id: "Diagnóstico por Imágenes", name: "Diagnóstico por Imágenes" },
    { id: "Laboratorio", name: "Laboratorio" },
    { id: "Medicina Nuclear", name: "Medicina Nuclear" },
    { id: "Otros Informes", name: "Otros Informes" },
  ];

  const yearsList = (() => {
    const now = new Date();
    const year = now.getFullYear();
    const listaAnos = [{ id: "Todos", name: "Todos" }];
    const anos = year - 2017;

    for (var i = 0; i <= anos; i++) {
      listaAnos.push({ id: year - i, name: year - i });
    }

    return listaAnos;

    // return [
    //         {id:"Año",name:"Año"},
    //         {id:year,name:year},
    //         {id:year-1,name:year-1},
    //         {id:year-2,name:year-2},
    //        ];
  })();

  const changeService = (id, name, text) => {
    setService(id);
    setServiceSelected(text);
    setOffset(0);
    obtenerResultados(idSap, limit, 0, id, search, year);
  };

  const clickService = () => {
    ReactGA.event({
      category: "Acciones-Resultados",
      action: "click",
      label: "resultados-all",
    });
    setCloseFamily(true);
    setCloseService(false);
    setCloseAno(true);
  };

  const changeidSap = (id, name, text) => {
    setFamilyItem(text);
    setIdSap(id);
    setOffset(0);
    obtenerResultados(id, limit, 0, service, search, year);
  };

  const clickIdSap = () => {
    setCloseFamily(false);
    setCloseService(true);
    setCloseAno(true);
  };

  const searchData = (event) => {
    ReactGA.event({
      category: "Acciones-Resultados",
      action: "click",
      label: "Buscar",
    });

    //controller.abort();
    // sourceAxios?.cancel("");
    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    // setSourceAxios(source);
    let buscar = event.target.value;

    setSearch(buscar);
    if (buscar.length >= 3) {
      setLoader(true);
      controllerAxios?.abort();
      setOffset(0);
      obtenerResultados(idSap, limit, 0, service, event.target.value, year);
    }
  };

  const siguiente = () => {
    setOffset(offset + limit);
    obtenerResultados(idSap, limit, offset + limit, service, search, year);
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset < 0) {
      setOffset(0);
      obtenerResultados(idSap, limit, 0, service, search, year);
    } else {
      setOffset(temporalOffset);
      obtenerResultados(idSap, limit, temporalOffset, service, search, year);
    }
  };

  const descargar = (resultado) => {
    if (resultado.idReport != null) {
      window.open(resultado.links.urlDownload);
    } else {
      myAxios({
        url: resultado.links.urlDownload,
        headers: {
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      })
        .then((response) => {
          // fetchLogs({
          //   idLogType: 1,
          //   idType: 7,
          //   description:
          //     "El usuario " +
          //     user.user.data.Customers.lastName +
          //     " " +
          //     user.user.data.Customers.firstName +
          //     " bajó el informe resultado_" +
          //     resultado.idLaboratory,
          // });

          const linkSource = `data:application/pdf;base64,${response.data.data.File}`;
          const downloadLink = document.createElement("a");
          const fileName = "report.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((error) => {});
    }
  };

  const verResultado = (resultado) => {
    var win = window.open(resultado.links.urlViewer, "_blank");
    win.focus();
  };

  const enviarEmail = (resultado) => {
    myAxios({
      url: resultado.links.urlSendEmail,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        if (!response.error) {
          emailToggleSuccess.onToggle();
        } else {
          emailToggleError.onToggle();
        }
      })
      .catch((error) => {});
  };

  return (
    <section className=" ">
      <EmailComponentSuccess
        visible={emailToggleSuccess.toggle}
        success={true}
        onToggle={emailToggleSuccess.onToggle}
      />

      <EmailComponentError
        visible={emailToggleError.toggle}
        success={false}
        onToggle={emailToggleError.onToggle}
      />

      <div className="container p-4" id="container-resultados">
        {/* TITULO */}
        <div className="row container-label-results">
          <div className="col-lg-4">
            <h2 className="section_hb_title title-results">
              <b>Resultados de estudios</b>
            </h2>
          </div>

          <div className="col-lg-1"></div>

          <div className="col-12 col-lg-4 col-years-results">
            <div id="title-study-results">
              <span className="año-estudio">Año del estudio</span>
            </div>

            <CustomSelect
              close={closeAno}
              defaultText={year}
              optionsList={yearsList}
              type={CUSTOM_SELECT_COLOR_WHITE}
              onChange={onChangeYear}
              onClick={clickYear}
              width="111px"
              name="year"
              paddingTop="1rem"
              fontFamily="Assistant-Regular"
            />
          </div>

          <div className="col-12 col-lg-3" style={{ marginBottom: "0rem" }}>
            {/* <select  className={`option-register shadow-red`} id="user">                        
                            <option value="1">Mariana Gabriela Sciarreta - Titular</option>                            
                        </select> */}
            <div id="dropdown-name" style={{ position: "relative" }}>
              <CustomSelect
                defaultText={familyItem}
                optionsList={familyGroupList ?? [{ id: "", name: "" }]}
                type={CUSTOM_SELECT_COLOR_BLUE}
                width="100%"
                name="family"
                onChange={changeidSap}
                onClick={clickIdSap}
                fontFamily="Assistant-Regular"
                close={closeFamily}
              />
            </div>
          </div>
        </div>
        {/* SEPARADOR */}
        {/* <div className="separador-result"></div> */}

        <div className="row">
          <div className="col-12 col-md-12" id="findUserName">
            <div className="input-icons me-3">
              <FontAwesomeIcon
                icon={faSearch}
                className="custom-icon-results"
              />
              <input
                className="input-field-results"
                type="text"
                placeholder="Buscar"
                onChange={searchData}
              />
            </div>
            <div>
              <div id="filterServices">
                {/* <select  className={`option-register shadow-blue`} id="estudio">                        
                                  <option value="1">Todos los resultados</option>                            
                              </select> */}

                <CustomSelect
                  width="200px"
                  defaultText={serviceSelected}
                  optionsList={estudiosList}
                  type={CUSTOM_SELECT_COLOR_RED}
                  name="services"
                  onChange={changeService}
                  onClick={clickService}
                  fontFamily="Assistant-SemiBold"
                  close={closeService}
                />
              </div>
            </div>
          </div>
        </div>

        {/* SEPARADOR */}
        <div className="separador-table-red-result"></div>
        {/* TABLA */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table-responsive">
              {!loader ? (
                <table className="table table-hb" id="tableResults">
                  <thead>
                    <tr>
                      {results.length > 0 && (
                        <>
                          <th>Fecha</th>
                          <th>Estudio</th>
                          <th>Servicio</th>
                          <th></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {results.length > 0 ? (
                      results.map((result) => {
                        return (
                          <tr key={result.idLaboratory}>
                            <td>{result.dateDisplay}</td>
                            <td>{result.description}</td>
                            <td>{result.service}</td>
                            <td className="options-results">
                              <ReactTooltip id="tooltip-view-results" />
                              <ReactTooltip id="tooltip-dowload-results" />
                              <ReactTooltip id="tooltip-send-results" />
                              {result.links.urlViewer ? (
                                <>
                                  <img
                                    className="i-25-result pointer"
                                    onClick={() => verResultado(result)}
                                    src={ViewIcon}
                                    alt="..."
                                    data-tooltip-id="tooltip-view-results"
                                    data-tooltip-content="Ver más datos"
                                  ></img>
                                </>
                              ) : (
                                <>
                                  <img
                                    className="i-25-result"
                                    onClick={() => verResultado(result)}
                                    src={ViewIconDisabled}
                                    alt="..."
                                    data-tooltip-id="tooltip-view-results"
                                    data-tooltip-content="Ver más datos"
                                  ></img>
                                </>
                              )}
                              {result.links.urlDownload ? (
                                <>
                                  <img
                                    className="i-25-result pointer"
                                    onClick={() => descargar(result)}
                                    src={DownloadIcon}
                                    alt="..."
                                    data-tooltip-id="tooltip-dowload-results"
                                    data-tooltip-content="Descargar resultado"
                                  ></img>
                                </>
                              ) : (
                                <>
                                  <img
                                    className="i-25-result"
                                    src={DownloadIconDisabled}
                                    alt="..."
                                    data-tooltip-id="tooltip-dowload-results"
                                    data-tooltip-content="Descargar resultado"
                                  ></img>
                                </>
                              )}
                              {result.links.urlSendEmail ? (
                                <>
                                  <img
                                    className="i-25-result pointer"
                                    onClick={() => enviarEmail(result)}
                                    src={MailIcon}
                                    alt="..."
                                    data-tooltip-id="tooltip-send-results"
                                    data-tooltip-content="Enviar resultado"
                                  ></img>
                                </>
                              ) : (
                                <>
                                  <img
                                    className="i-25-result"
                                    src={MailIconDisabled}
                                    alt="..."
                                    data-tooltip-id="tooltip-send-results"
                                    data-tooltip-content="Enviar resultado"
                                  ></img>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <td
                        colSpan="5"
                        style={{
                          textAlign: "center",
                          paddingBottom: "15px",
                          paddingTop: "15px",
                        }}
                      >
                        <p
                          className="section_hb_title"
                          style={{
                            fontSize: "25px",
                          }}
                        >
                          No se encontraron resultados con esas opciones de
                          búsqueda.
                          <br /> Intente con otros parámetros, o contáctese con
                          nuestro equipo de{" "}
                          <a
                            href="mailto:soportewebhb@hbritanico.com.ar"
                            className="section_hb_title"
                            style={{
                              fontSize: "25px",
                              textDecoration: "underline",
                            }}
                          >
                            Soporte
                          </a>
                          .
                        </p>
                      </td>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="text-center" style={{ paddingBottom: "15px" }}>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* SEPARADOR */}
        <div className="separador-table-red-result"></div>
        {total > 0 ? (
          <div id="paginate">
            {(offset > 0) & !loader ? (
              <div
                className="btn btn-hb-primary-without-border shadow-blue"
                onClick={anterior}
              >
                Anterior
              </div>
            ) : (
              <></>
            )}
            {!loader ? (
              <span className="number-hb-paginate">
                <a href="#">{offset / 5 + 1}</a>
              </span>
            ) : (
              <></>
            )}
            {(offset + results.length < total) & !loader ? (
              <div
                className="btn btn-hb-primary-without-border shadow-blue"
                onClick={siguiente}
              >
                Siguiente
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="footer-hb-small"></div>
    </section>
  );
};

export default ResultsComponent;
