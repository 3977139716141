import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import InformacionUtilPage from "../../../components/pshb/informacion-util";

import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import "./info.scss";

const InformacionUtil = () => {
  const [routes, setRoutes] = useState({
    routes: [
      { route: "/informacion-util", name: "Información útil" },
      { route: "/contacto", name: "Contacto" },
      // { route: "/credencial-digital", name: " Credencial digital " },
    ],
  });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <UserSidebar routes={routes} height={height}></UserSidebar>
      <InformacionUtilPage></InformacionUtilPage>
    </div>
  );
};

export default InformacionUtil;
