import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import "./style.scss";

export const HistorialComponent = ({
  activo = false,
  editando = false,
  data,
}) => {
  const { userPSHB } = useContext(UserGlobalContextMemorySpace);
  const [claseCampo, setClaseCampo] = React.useState("");

  React.useEffect(() => {
    if (editando) setClaseCampo("campo editandoCampo");
    else setClaseCampo("campo");
  }, [editando]);

  const formatMoney = (value) => {
    return `$ ${parseFloat(value)
      .toLocaleString("es-AR")
      .replaceAll("NaN", "")}`;
  };

  return (
    <div className={activo ? "" : "d-none"}>
      <div className={activo ? "formActivo" : "formInactivo"}>
        {/* CAMPOS DE FORMULARIOS */}
        <form
          className={
            userPSHB ? "formPSHB integrante-pshb" : "formHB integrante-hb"
          }
        >
          <div className={activo ? "lineaActivaPSHB" : "lineaInactivaPSHB"} />
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Fecha:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Objectivity-Regular" }}
            >
              {data.periodoPrestacion.slice(0, 7)}
            </span>
          </div>
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Paciente:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Objectivity-Regular" }}
            >
              {data.paciente}
            </span>
          </div>
          <div
            className={claseCampo}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span className="perfil-etiqueta">Cuit:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Objectivity-Regular" }}
            >
              {data.cuit}
            </span>
          </div>

          <div
            className={claseCampo}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span className="perfil-etiqueta">Fecha:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Objectivity-Regular" }}
            >
              {formatMoney(data.valorPresentado)}
            </span>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          ></div>
        </form>
      </div>
    </div>
  );
};
